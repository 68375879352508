var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_vm._m(0),_c('div',[_c('p',{staticClass:"text-h5 font-weight-black"},[_vm._v("Top Rated Courses")]),_c('v-row',{staticClass:"mb-0"},_vm._l((_vm.topRatedCourses),function(course){return _c('v-col',{key:course.course_id,attrs:{"xl":"3","lg":"3","md":"3","sm":"6","xs":"6"}},[_c('v-card',{staticClass:"card-outter",attrs:{"hover":"","min-height":"350px","height":"100%"}},[_c('router-link',{staticClass:"centertext no-link",attrs:{"text":"","to":'/app/people/course/' + course.course_id}},[_c('v-img',{attrs:{"height":"175px","src":_vm.VUE_APP_API_ENDPOINT +
                'images/courses/' +
                (course.image || 'default.jpg'),"contain":""}}),_c('v-card-title',[_c('div',{staticClass:"text-subtitle-1 card-title"},[_vm._v(" "+_vm._s(course.title.toLowerCase())+" ")])])],1),_c('v-card-actions',{staticClass:"card-actions"},[_c('div',{staticClass:"ma-2 pa-0 text-body-1"},[_vm._v(" "+_vm._s(course.partner)+" ")])])],1)],1)}),1),_c('v-list',{staticClass:"center-list"},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Explore all the courses")])],1),_c('v-list-item-icon',[_c('router-link',{staticClass:"no-link",attrs:{"to":"/app/people/search-courses"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-arrow-right-circle ")])],1)],1)],1)],1),_c('br'),_c('br'),_c('p',{staticClass:"text-h5 font-weight-black"},[_vm._v("Recommended Courses For You")]),_c('v-row',{staticClass:"mb-0"},_vm._l((_vm.recommendedCourses),function(course){return _c('v-col',{key:course.course_id,attrs:{"xl":"3","lg":"3","md":"3","sm":"6","xs":"6"}},[_c('v-card',{staticClass:"card-outter",attrs:{"hover":"","min-height":"350px","height":"100%"}},[_c('router-link',{staticClass:"centertext no-link",attrs:{"text":"","to":'/app/people/course/' + course.course_id}},[_c('v-img',{attrs:{"height":"175px","src":_vm.VUE_APP_API_ENDPOINT +
                'images/courses/' +
                (course.image || 'default.jpg'),"contain":""}}),_c('v-card-title',[_c('div',{staticClass:"text-subtitle-1 card-title"},[_vm._v(" "+_vm._s(course.title.toLowerCase())+" ")])])],1),_c('v-card-actions',{staticClass:"card-actions"},[_c('div',{staticClass:"ma-2 pa-0 text-body-1"},[_vm._v(" "+_vm._s(course.partner)+" ")])])],1)],1)}),1),_c('v-list',{staticClass:"center-list"},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Explore All The Courses")])],1),_c('v-list-item-icon',[_c('router-link',{staticClass:"no-link",attrs:{"to":"/app/people/search-courses"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-arrow-right-circle ")])],1)],1)],1)],1),_c('br'),_c('br'),_c('p',{staticClass:"text-h5 font-weight-black"},[_vm._v("New Jobs")]),_c('v-row',{staticClass:"mb-0"},_vm._l((_vm.newJobs),function(job){return _c('v-col',{key:job.job_id,attrs:{"xl":"3","lg":"3","md":"3","sm":"6","xs":"6"}},[_c('v-card',{staticClass:"card-outter",attrs:{"hover":"","min-height":"350px","height":"100%"}},[_c('router-link',{staticClass:"centertext no-link",attrs:{"text":"","to":'/app/people/job/' + job.job_id}},[_c('v-img',{attrs:{"height":"175px","src":_vm.VUE_APP_API_ENDPOINT +
                'images/jobs/' +
                (job.image || 'default.jpg'),"contain":""}}),_c('v-card-title',[_c('div',{staticClass:"text-subtitle-1 card-title"},[_vm._v(" "+_vm._s(job.name.toLowerCase())+" ")])])],1),_c('v-card-actions',{staticClass:"card-actions"},[_c('div',{staticClass:"ma-2 pa-0 text-body-1"},[_vm._v(" "+_vm._s(job.full_name)+" ")])])],1)],1)}),1),_c('v-list',{staticClass:"center-list"},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Explore all the jobs")])],1),_c('v-list-item-icon',[_c('router-link',{staticClass:"no-link",attrs:{"to":"/app/people/search-jobs"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-arrow-right-circle ")])],1)],1)],1)],1),_c('br'),_c('br'),_c('p',{staticClass:"text-h5 font-weight-black"},[_vm._v("Recommended Jobs For You")]),_c('v-row',{staticClass:"mb-0"},_vm._l((_vm.recommendedJobs),function(job){return _c('v-col',{key:job.job_id,attrs:{"xl":"3","lg":"3","md":"3","sm":"6","xs":"6"}},[_c('v-card',{staticClass:"card-outter",attrs:{"hover":"","min-height":"350px","height":"100%"}},[_c('router-link',{staticClass:"centertext no-link",attrs:{"text":"","to":'/app/people/job/' + job.job_id}},[_c('v-img',{attrs:{"height":"175px","src":_vm.VUE_APP_API_ENDPOINT +
                'images/jobs/' +
                (job.image || 'default.jpg'),"contain":""}}),_c('v-card-title',[_c('div',{staticClass:"text-subtitle-1 card-title"},[_vm._v(" "+_vm._s(job.name.toLowerCase())+" ")])])],1),_c('v-card-actions',{staticClass:"card-actions"},[_c('div',{staticClass:"ma-2 pa-0 text-body-1"},[_vm._v(" "+_vm._s(job.full_name)+" ")])])],1)],1)}),1),_c('v-list',{staticClass:"center-list"},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Explore all the jobs")])],1),_c('v-list-item-icon',[_c('router-link',{staticClass:"no-link",attrs:{"to":"/app/people/search-jobs"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-arrow-right-circle ")])],1)],1)],1)],1),_c('br'),_c('br'),_c('br'),_c('br')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('p',{staticClass:"text-h4 mt-6 mb-4 primary-color"},[_vm._v(" Search for Courses & Jobs in the ALLVIEW Platform ")])])
}]

export { render, staticRenderFns }