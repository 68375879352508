<template>
  <div>
    <Toolbar />
    <div class="center-in-page">
      <div class="wrapper">
        <v-btn block class="primary" x-large @click="verify()"
          >Click Here To Verify Your Account</v-btn
        >
      </div>
    </div>
    <Footer />

    <v-snackbar v-model="snackbar_show" :color="snackbar_color" top>
      <v-icon>{{ snackbar_icon }}</v-icon>
      &nbsp;&nbsp;
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import Toolbar from "@/components/carbon-ui/toolbar/Toolbar2.vue";
import Footer from "@/components/carbon-ui/footer/Footer4.vue";

export default {
  components: {
    Toolbar,
    Footer,
  },
  data: () => ({
    // Snackbar
    snackbar_show: false,
    snackbar_color: null,
    snackbar_text: null,
    snackbar_icon: null,
  }),
  methods: {
    async verify() {
      let response;
      try {
        // Get the token from the url parameter
        const token = this.$route.query.token;

        // Send the token to the backend to verify the user
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "home/verify-user/" + token
        );
        response = await response.json();
        if (response.success) {
          // Login success message
          this.login_form_show = false;
          this.snackbar_color = "success";
          this.snackbar_text =
            "Success: your account is verified. You can now login.";
          this.snackbar_icon = "mdi-check";
          this.snackbar_show = true;

          // Keep the user waiting to see the success message
          await new Promise((resolve) => setTimeout(resolve, 2000));

          // Redirect the user to the login page
          this.$router.push("/login");
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = error.message;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },
  },
};
</script>

<style scoped>
.center-in-page {
  margin-top: 200px;
  margin-bottom: 400px;
}
.wrapper {
  max-width: 1200px;
  margin: 0 auto;
}
</style>