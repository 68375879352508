<template>
  <v-container class="wrapper">
    <center>
      <div class="text-h4 mb-6 gray">Job Offer Details</div>
    </center>


    <center v-if="!job">
      <h2 class="mx-auto red--text" > JOB {{getJobId()}} NOT FOUND  </h2>
      <h3 v-if="response_error"> {{response_error}}</h3>
      <br><br><br>
    </center>

    <v-card class="mx-auto" v-if="job">
      <v-card-title class="card-title">
        {{
          job.name.toLowerCase().charAt(0).toUpperCase() +
          job.name.toLowerCase().slice(1)
        }}
      </v-card-title>
      <v-card-subtitle>
        <v-row>
          <v-col>

            <v-img
              :src="
                VUE_APP_API_ENDPOINT + 'images/jobs/' + (job.image || 'default.jpg')
              "
              height="200px"
              contain
              class="mt-5 mb-5"
              ></v-img>
          </v-col>
        <v-col>
        <p class="bold">
          {{ job.description }}
        </p>
        </v-col>
        </v-row>
        </v-card-subtitle>

      <br />
      <v-divider></v-divider>
      <br />
      <v-card-text>

        <v-row class="job-row">
          <v-col xs="12" sm="12" md="10" lg="10" xl="10" class="job-col">
            <strong>Company Name: </strong>  &nbsp; {{ job.full_name ? job.full_name :'Not defined'}}
          </v-col>
        </v-row>

        <!-- Professional Sector -->
        <v-row class="job-row">
          <v-col xs="12" sm="12" md="10" lg="10" xl="10" class="job-col">
            <template v-if="job.sector && job.sector.length>0">
              <p class="mt-1 mb-1"><strong>Professional Sector:</strong></p>
              <ul>
                <li v-for="text in job.sector " :key="text.id">
                  {{ text.length > 2 ? text.charAt(0).toUpperCase() + text.slice(1) : text }}
                </li>
              </ul>
            </template>
            <p v-else>
              <strong>Professional Sector:</strong>  &nbsp; Not specified
            </p>
          </v-col>
        </v-row>

        <!--Demanded Skills -->
        <v-row class="job-row">
          <v-col xs="12" sm="12" md="10" lg="10" xl="10"   class="job-col">
            <template v-if="job.essentialSkillsSelected && job.essentialSkillsSelected.length>0">
              <p class="mt-1 mb-1"><strong>Demanded Skills:</strong></p>
              <ul>
                <li v-for="text in job.essentialSkillsSelected " :key="text.id">
                  {{ text }}
                </li>
              </ul>
            </template>
            <p v-else>
              <strong>Demanded Skills:</strong>  &nbsp; Not specified
            </p>
          </v-col>
        </v-row>

        <!--Optional Skills -->
        <v-row class="job-row" v-if="job.optionalSkillsSelected && job.optionalSkillsSelected.length>0">
          <v-col xs="12" sm="12" md="10" lg="10" xl="10" class="job-col">
            <p class="mt-1 mb-1"><strong>Optional Skills:</strong></p>
            <ul>
              <li v-for="text in job.optionalSkillsSelected " :key="text.id">
                {{ text }}
              </li>
            </ul>
          </v-col>
        </v-row>

        <!-- Job Requeriments-->
        <v-row class="job-row">
          <v-col xs="12" sm="12" md="10" lg="10" xl="10" class="job-col">
            <template v-if="job.job_requirements && job.job_requirements.length>0">
              <p class="mt-1 mb-1"><strong>Job Requeriments:</strong></p>
              <ul>
                <li v-for="text in job.job_requirements " :key="text.id">
                  {{ text }}
                </li>
              </ul>
            </template>
            <p v-else class="mt-1 mb-1">
              <strong >Job Requeriments:</strong>  &nbsp;Not specified
            </p>
          </v-col>
        </v-row>

        <!-- Language -->
        <v-row class="job-row">
          <v-col xs="12" sm="12" md="10" lg="10" xl="10" class="job-col">
            <strong>Language: </strong> &nbsp; {{ job.languages && job.languages.length > 0 ? JSON.parse(job.languages).toString().replace(',',', ') : 'Not definded' }}
          </v-col>
        </v-row>

        <!-- Type of contract -->
        <!--
        <v-row class="job-row">
          <v-col xs="12" sm="12" md="10" lg="10" xl="10" class="job-col">
            <strong>Contract Type: </strong> &nbsp; {{ job.contract ? job.contract : 'Not defined'}}
          </v-col>
        </v-row>
        -->
        <!-- Type of contract MULTIPLE -->
        <v-row class="job-row">
          <v-col xs="12" sm="12" md="10" lg="10" xl="10" class="job-col">
            <strong>Contract Type: </strong> &nbsp; {{ job.contract_types && job.contract_types.length > 0 ? JSON.parse(job.contract_types).toString().replace(',',', ') : 'Not definded' }}
          </v-col>
        </v-row>

        <!-- Job place -->
        <v-row class="job-row">
          <v-col xs="12" sm="12" md="10" lg="10" xl="10" class="job-col">
            <strong>Job place: </strong> &nbsp; {{ job.place ? job.place : 'Not defined'}}
          </v-col>
        </v-row>

        <!-- DATES -->
        <v-row class="job-row">
          <v-col xs="12" sm="12" md="10" lg="10" xl="10" class="job-col">
            <strong>Dates: </strong> &nbsp;
            <span v-if="job.init_date && job.end_date">
                  {{ new Date(job.init_date).toLocaleDateString() }} - {{ new Date(job.end_date).toLocaleDateString() }}
                </span>
            <span v-else-if="job.init_date">
               {{ new Date(job.init_date).toLocaleDateString() }} (Start date)
            </span>
            <span v-else>Not specified</span>
          </v-col>
        </v-row>


        <!-- Gross salary -->
        <v-row class="job-row">
          <v-col xs="12" sm="12" md="10" lg="10" xl="10" class="job-col">
            <strong>Gross salary: </strong> &nbsp; {{ job.salary ? job.salary+' €' : 'Not defined'}}
          </v-col>
        </v-row>

        <!-- Contact -->
        <v-row class="job-row">
          <v-col xs="12" sm="12" md="10" lg="10" xl="10" class="job-col">
            <strong>Contact information: </strong> &nbsp; {{ job.contact_details ? job.contact_details : 'Not defined'}}
          </v-col>
        </v-row>

        <!-- Rating -->
        <v-row class="job-row" v-if="job.rating">
          <v-col xs="12" sm="12" md="10" lg="10" xl="10" class="job-col">
            <strong>Rating: </strong>
            <v-rating
              color="primary"
              empty-icon="mdi-star-outline"
              full-icon="mdi-star"
              half-icon="mdi-star-half"
              half-increments
              hover
              length="5"
              readonly
              size="32"
              :value="job.rating"
            ></v-rating>
          </v-col>
        </v-row>

        <!-- Date of creation -->
        <v-row class="job-row">
          <v-col xs="12" sm="12" md="10" lg="10" xl="10" class="job-col">
            <strong>Date of creation: </strong> &nbsp; {{ job.created_at ? new Date(job.created_at).toLocaleDateString() : 'Not defined'}}
          </v-col>
        </v-row>

        <resource-editor :entity-id="job.job_id" entity-table="jobs" ></resource-editor>
        <br />
        <br />
        <br />
        <v-row>
          <v-col>
            <v-btn block class="button-gradient-half" @click="addFav"
              >Add Job To Favorites</v-btn
            >
          </v-col>
          <v-col>
            <v-btn block class="button-gradient-error-half" @click="delFav"
              >Remove From Favorites
            </v-btn>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

    <v-snackbar v-model="snackbar_show" :color="snackbar_color" top>
      <v-icon>{{ snackbar_icon }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>


<script>
import ResourceEditor from "@/pages/app/common/ResourceEditor.vue";

export default {
  name: "job",

  components: {ResourceEditor},
  data: () => ({
    job: null,
    response_error: null,
    // Snackbar
    snackbar_show: false,
    snackbar_color: null,
    snackbar_text: null,
    snackbar_icon: null,
    // env
    VUE_APP_API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,
  }),

  async mounted() {

    if(this.$cookie.get("jwt_token")){
      try {
        await this.getData();
      } catch (error) {
        if (error.message.includes("Authentication failed")) {
          this.$router.push("/auth-error");
        }
      }
    }else{
      console.log("go","/app/visitor/job/"+this.$route.params.pathMatch)
      await this.$router.push("/app/visitor/job/" + this.$route.params.pathMatch);
    }
  },

  methods: {
    async getData() {
      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT +
          "common/jobs/" +
          this.$route.params.pathMatch,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      if (response.success) {
        this.job = response.result;

        if(this.job.job_requirements) {
          console.log("this.job.job_requirements", this.job.job_requirements)
          this.job.job_requirements = JSON.parse(this.job.job_requirements)
        }


      } else {
        this.response_error = response.message;
        throw new Error(response.message);
      }
    },
    getJobId(){
      if(this.$route && this.$route.params && this.$route.params.pathMatch)
        return ""+this.$route.params.pathMatch
      return "NO ID";
    },
    // Insert a job in favourites
    async addFav() {
      let responseInsert;
      try {
        responseInsert = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/add-fav-jobs",
          {
            method: "POST",
            body: JSON.stringify({
              job_id: this.job.job_id,
            }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        responseInsert = await responseInsert.json();
        if (responseInsert.success) {
          this.snackbar_color = "success";
          this.snackbar_text = "Success: job added to favorites";
          this.snackbar_icon = "mdi-success-circle";
          this.snackbar_show = true;
        } else {
          throw new Error(responseInsert.message);
        }
      } catch (error) {
        if (error.message === "alreadySaved") {
          // Warning message
          this.snackbar_color = "orange";
          this.snackbar_text = "The job was already saved as favorite";
          this.snackbar_icon = "mdi-alert-outline";
        } else {
          // Error message
          this.snackbar_color = "error";
          this.snackbar_text = error.message;
          this.snackbar_icon = "mdi-alert-circle";
        }
        this.snackbar_show = true;
      }
    },
    // Delete a job in favourites
    async delFav() {
      let responseDelete;
      try {
        responseDelete = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/delete-fav-jobs",
          {
            method: "POST",
            body: JSON.stringify({
              job_id: this.job.job_id,
            }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        responseDelete = await responseDelete.json();
        if (responseDelete.success) {
          this.snackbar_color = "success";
          this.snackbar_text = "Success: job removed from favorites";
          this.snackbar_icon = "mdi-success-circle";
          this.snackbar_show = true;
        } else {
          throw new Error(responseDelete.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = error.message;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}

.no-link {
  text-decoration: none;
  color: black;
}
.card-title {
  word-break: normal;
  margin-bottom: 20px;
  font-weight: bold;
}
.bold {
  font-weight: bold;
}
.gray {
  color: #283444;
}

.button-gradient-half:hover {
  background-position: right center;
}

.button-gradient-half {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 100%);
}

.button-gradient-error-half:hover {
  background-position: right center;
}
.button-gradient-error-half {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  background-image: linear-gradient(to right, #ec6608 0%, #f2e500 150%);
}

.job-row{
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  margin-left: 25px;
}
.job-col{
  padding: 8px !important;
}
</style>
