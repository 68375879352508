<template>
  <!-- This page is olny used for the Google users since they don't have a register menu. -->
  <div>
    <Toolbar />
    <div class="center-in-page">
      <div class="wrapper">
        <v-card>
          <v-toolbar class="background-gradient"
            >Set your platform user role</v-toolbar
          >
          <v-card-text class="pl-5 pr-5">
            <div class="text-h6 pt-5 pb-5">
              Seting a user role is mandatory to register into the platform.
            </div>
            <v-select
              solo
              flat
              label="Select a role"
              :items="['People', 'Training Provider', 'Company']"
              v-model="role"
            ></v-select>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="setRole"><div class="text-gradient">Set Role</div></v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
    <Footer />

    <v-snackbar v-model="snackbar_show" :color="snackbar_color" top>
      <v-icon>{{ snackbar_icon }}</v-icon>
      &nbsp;&nbsp;
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import Toolbar from "@/components/carbon-ui/toolbar/Toolbar2.vue";
import Footer from "@/components/carbon-ui/footer/Footer4.vue";

export default {
  components: {
    Toolbar,
    Footer,
  },
  data: () => ({
    role: null,
    // Snackbar
    snackbar_show: false,
    snackbar_color: null,
    snackbar_text: null,
    snackbar_icon: null,
  }),
  methods: {
    async setRole() {
      try {
        // Send the token to the backend to verify the user
        let response = await fetch(
          process.env.VUE_APP_API_ENDPOINT +
            "home/set-role/" + this.role, {
            method: "GET",
            headers: {
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();

        if (response.success) {
          // Login success message
          this.login_form_show = false;
          this.snackbar_color = "success";
          this.snackbar_text =
            "Success: role saved successfully.";
          this.snackbar_icon = "mdi-check";
          this.snackbar_show = true;

          // Keep the user waiting to see the success message
          await new Promise((resolve) => setTimeout(resolve, 1250));

          // Redirect the user to its home
          if (response.result.roleId === 1) {
            this.$router.push("/app/people/home");
          } else if (response.result.roleId === 2) {
            this.$router.push("/app/training-provider/home");
          } else if (response.result.roleId === 3) {
            this.$router.push("/app/company/home");
          } else if (response.result.roleId === 7) {
            this.$router.push("/backoffice");
          } else {
            // Role not defined yet
            this.$router.push("/set-role");
          }
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = error.message;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },
  },
};
</script>

<style scoped>
.center-in-page {
  margin-top: 200px;
  margin-bottom: 400px;
}
.wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.background-gradient {
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 100%);
}
.text-gradient {
  background: linear-gradient(to right, #047ec3 0%, #52ae32 150%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>