<template>
  <div class="wrapper">
    <div class="text-center">
      <p class="text-h4 mt-6 mb-4 primary-color">
        Home
      </p>
    </div>
    <div class="row">
    </div>
    <v-col>
      <v-card class="card-form" style="margin-top: 20px">
          <v-card-title
            class="primary white--text headline background-gradient"
          >
            <span class="headline ml-3">Platform Information</span>
          </v-card-title>
          <v-card-text class="pt-6 pl-8">
            <v-row>
              <v-col>
                <div class="text-body-2 card-title no-link">
                  <h3>USERS - {{totalUsers}}</h3>
                  There are <span class="text-subtitle-1">{{totalUsers}}</span> users in AllView.
                  <v-progress-circular indeterminate v-if="usersLoading"></v-progress-circular>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="text-body-2 card-title no-link">

                  <v-progress-circular indeterminate v-if="jobsLoading"></v-progress-circular>
                  <h3>JOBS - {{jobs}}</h3>
                  <ul>
                    <li>Visible jobs: <span class="text-subtitle-1">{{jobs}}</span></li>
                    <li>Deleted jobs: <span class="text-subtitle-1">{{deleted_jobs}}</span></li>
                    <li>Expired jobs: <span class="text-subtitle-1">{{expired_jobs}}</span></li>
                    <li>Jobs waiting for publish start date: <span class="text-subtitle-1">{{waiting_publish_date_jobs}}</span></li>
                  </ul>
                </div>
              </v-col>
              <v-col>
                <div class="text-body-2 card-title no-link">
                  <v-progress-circular indeterminate v-if="coursesLoading"></v-progress-circular>
                  <h3>COURSES - {{courses}}</h3>
                  <ul>
                    <li>Visible courses: <span class="text-subtitle-1 ">{{courses}}</span></li>
                    <li>Deleted courses: <span class="text-subtitle-1">{{deleted_courses}}</span></li>
                    <li>Expired courses: <span class="text-subtitle-1">{{expired_courses}}</span></li>
                    <li>Courses waiting for publish start date: <span class="text-subtitle-1">{{waiting_publish_date_courses}}</span></li>
                  </ul>


                </div>
              </v-col>
            </v-row>

            <v-row>
              <pre class="text--primary no-link text-body-1" v-if="cronRes" style="white-space: pre-wrap;word-wrap: break-word;">
               {{ "\n"+cronRes }}
            </pre>
            </v-row>
          </v-card-text>
          <v-divider class="mt-12"></v-divider>

        </v-card>

    </v-col>
    <!-- <div>
      <p class="text-h5 font-weight-black">Users</p>
    </div>
    <div>
      <p class="text-h5 font-weight-black">Jobs</p>
    </div>
    <div>
      <p class="text-h5 font-weight-black">Users</p>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "Home",

  data: () => ({
    topRatedCourses: [],
    recommendedCourses: [],
    newJobs: [],
    recommendedJobs: [],
    totalUsers: 0,
    totalCourses: 0,
    courses: 0,
    deleted_courses: 0,
    expired_courses: 0,
    waiting_publish_date_courses: 0,
    totalJobs: 0,
    jobs: 0,
    deleted_jobs: 0,
    expired_jobs: 0,
    waiting_publish_date_jobs: 0,
    cronRes:null,
    coursesLoading: false,
    jobsLoading: false,
    usersLoading: false,
    // env
    VUE_APP_API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,
  }),
  async mounted() {
    try {
      await this.getData();
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        this.$router.push("/auth-error");
      }
    }
  },
  methods: {

    async getData() {
      this.usersLoading = true;
      this.jobsLoading = true;
      this.coursesLoading = true;

      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "backoffice/back-home",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      if (response.success) {
        //AQUÍ TENEMOS QUE RELLENAR LA TABLA
        console.log(response)
          this.totalUsers = response.result.users
          this.courses = response.result.courses;
          this.deleted_courses = response.result.deleted_courses;
          this.expired_courses = response.result.expired_courses;
          this.waiting_publish_date_courses = response.result.waiting_publish_date_courses;

          this.jobs = response.result.jobs;
          this.deleted_jobs = response.result.deleted_jobs;
          this.expired_jobs = response.result.expired_jobs;
          this.waiting_publish_date_jobs = response.result.waiting_publish_date_jobs;

        this.cronRes = response.result.cron;
        this.usersLoading = false;
        this.jobsLoading = false;
        this.coursesLoading = false;
      } else {
        this.usersLoading = false;
        this.jobsLoading = false;
        this.coursesLoading = false;
        throw new Error(response.message);
      }
    },

    async getData_old() {
      this.usersLoading = true;
      this.jobsLoading = true;
      this.coursesLoading = true;

      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "backoffice/users",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      if (response.success) {
        //AQUÍ TENEMOS QUE RELLENAR LA TABLA
        console.log(response)
        var arr = response.result
        this.totalUsers = arr.length
        arr.forEach(function(aux){
          aux.country
        })
        // this.topRatedCourses = response.result.topRatedCourses;
        // this.recommendedCourses = response.result.recommendedCourses;
        // this.newJobs = response.result.newJobs;
        // this.recommendedJobs = response.result.recommendedJobs;
        this.$emit('missingProfile', response.result)
        this.usersLoading = false;
      } else {
        this.usersLoading = false;
        throw new Error(response.message);
      }

      this.coursesLoading = true;

      let response_courses = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "backoffice/courses",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response_courses = await response_courses.json();
      if (response_courses.success) {
        //AQUÍ TENEMOS QUE RELLENAR LA TABLA
        console.log(response_courses)
        var arrcourses = response_courses.result.courses
        this.totalCourses = arrcourses.length
        arrcourses.forEach(function(aux){
          aux.country
        })
        this.cronRes = response_courses.result.cron;
        // this.topRatedCourses = response.result.topRatedCourses;
        // this.recommendedCourses = response.result.recommendedCourses;
        // this.newJobs = response.result.newJobs;
        // this.recommendedJobs = response.result.recommendedJobs;
        this.$emit('missingProfile', response_courses.result)
        this.coursesLoading = false;
      } else {
        this.coursesLoading = false;
        throw new Error(response_courses.message);
      }


      let response_jobs = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "backoffice/jobs",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response_jobs = await response_jobs.json();
      if (response_courses.success) {
        //AQUÍ TENEMOS QUE RELLENAR LA TABLA
        console.log(response_jobs)
        var arrjobs = response_jobs.result.jobs
        this.totalJobs = arrjobs.length
        // this.topRatedCourses = response.result.topRatedCourses;
        // this.recommendedCourses = response.result.recommendedCourses;
        // this.newJobs = response.result.newJobs;
        // this.recommendedJobs = response.result.recommendedJobs;
        this.$emit('missingProfile', response_jobs.result)
        this.jobsLoading = false;
      } else {
        this.jobsLoading = false;
        throw new Error(response_jobs.message);
      }


    },

  },
};
</script>

<style scoped>
.centertext {
  margin-left: auto;
  margin-right: auto;
}
.no-link {
  text-decoration: none;
  color: black;
}
.card-title {
  text-transform: capitalize;
  word-break: normal;
}
.primary-color {
  color: #0096c7;
}
.center-list {
  max-width: 240px;
  margin: 0 auto;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}

.background-gradient {
  color: white;
  box-shadow: 0 0 20px #eee;
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 200%);
}
</style>
