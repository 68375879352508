<template>
  <div class="wrapper">
    <div class="text-center">
      <p class="text-h4 mt-6 mb-4 primary-color">
        Users
      </p>
    </div>
    <div>
      <v-row class="mb-0">
        <template>
          <div style="text-align: middle; width: 100%">
            <v-card-title style="margin-bottom: 20px">
              <p class="text-h5 font-weight-black">All Users</p>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details></v-text-field>
            </v-card-title>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="users"
              :options.sync="options"
              :loading="loading"
              :items-per-page="30"
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50,-1]
              }"
              :search="search"
              :single-select="singleSelect"
              item-key="id"
              show-select
              class="elevation-1">
              <template v-slot:top>
                  <v-btn class="button-gradient-error-half"
                  style="margin-top: 8px; margin-left: 8px; margin-bottom: 8px"
                  @click="deleteItem">
                    Remove selected items
                  </v-btn>

                  <v-btn class="button-gradient-half"
                  style="margin-top: 8px; margin-left: 20px; margin-bottom: 8px"
                  @click="downloadCSV">
                    Download CSV
                  </v-btn>

                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemsSelected">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
              </template>
            </v-data-table>
            <v-snackbar
              v-model="snack"
              :timeout="1500"
              :color="snackColor"
            >
              {{ snackText }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  v-bind="attrs"
                  text
                  @click="snack = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
          </div>
        </template>
      </v-row>
      <br /><br />
      <br /><br />
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",

  data: () => ({
    topRatedCourses: [],
    recommendedCourses: [],
    newJobs: [],
    singleSelect: false,
    selected: [],
    recommendedJobs: [],
    totalUsers: 0,
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    users: [],
    loading: true,
    options: {},
    dialogDelete: false,
    snack: false,
    snackColor: '',
    snackText: '',
    arr:[],
    // this.headers = ["id", "Name", "E-Mail", "Phone", "State", "Verified"],
    headers: [
    {text: 'Register date', value: 'created_date'},
    {text: 'Name', value: 'name'},
    {text: 'E-Mail', value: 'email'},
    {text: 'Profile', value: 'profile'},
    {text: 'State', value: 'state'},
    {text: 'Verified', value: 'verified'}],
    search: '',
    // env
    VUE_APP_API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,
  }),
  async mounted() {
    try {
      await this.getData();
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        this.$router.push("/auth-error");
      }
    }
  },
  watch: {
      dialogDelete (val) {
        console.log("EL VALOR ES " + val)
        val || this.closeDelete()
      },
    },
  methods: {
    deleteItem () {
      console.log("CAMBIAMOS EL STATUS DE DIALOGDELETE A TRUE: ")
      this.dialogDelete = true
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async getData() {
      this.loading = true
      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "backoffice/users",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      if (response.success) {
        console.log(response)
        this.arr = response.result
        this.totalUsers = this.arr.length
        var arrUsers = []
        this.arr.forEach(function(val){
          var veriAux = "No"
          if (val.verified == 1){
            veriAux = "Yes"
          }

          var objAux = {
            "id": val.user_id,
            "created_date": val.created_date.substring(0, 10),
            "name": val.full_name,
            "email": val.email,
            "profile": val.role_id,
            "state": val.state,
            "verified": veriAux
          }
          arrUsers.push(objAux)
        })
        console.log(arrUsers.length)
        this.users = arrUsers
        this.$emit('missingProfile', response.result.missingProfile)
        this.loading = false
        this.snack = true
        this.snackColor = 'success'
        this.snackText = 'Data updated'
      } else {
        throw new Error(response.message);
      }
    },

    async deleteItemsSelected(){
      // console.log("ENTRO EN DELETE ITEMS")
      console.log("LOS ELEMENTOS SELECCIONADOS SON: " + JSON.stringify(this.selected))
      this.loading = true
      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "backoffice/delete-user",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
          body: JSON.stringify({
              users_to_delete: this.selected,
              user_id: this.$cookie.get("jwt_token")
          })
        }
      );
      response = await response.json();
      console.log("LA RESPUESTA ES " + JSON.stringify(response))
      // if (response.success) {
      //AQUÍ TENEMOS QUE ACTUALIZAR LA TABLA
      await this.selected.forEach(objToDelete => {
       //console.log("EL OBJETO A ELIMINAR ES: " + JSON.stringify(objToDelete))
        this.editedIndex = this.users.indexOf(objToDelete)
        //console.log("EL OBJETO EN EL INDEX ES: "  + JSON.stringify(this.users[this.editedIndex]))
        this.editedItem = Object.assign({}, this.users[this.editedIndex])
        this.users = this.users.filter(obj => obj.id != this.editedItem.id)
        console.log(JSON.stringify(this.users))
      })
      this.loading = false
      this.closeDelete()
      this.snack = true
      this.snackColor = 'success'
      this.snackText = 'Data updated'
      // } else {
      //   throw new Error(response.message);
      // }
    },

    async downloadCSV(){

      import(/* webpackChunkName: "js2excel" */ 'js2excel').then(({json2excel}) => {
        json2excel({
            data: this.arr,
            name: 'Users',
            formateDate: 'dd/mm/yyyy'
        });
      }).catch((e) => {
          console.log(e.toString())
      });
    },
  },
};
</script>

<style scoped>
.centertext {
  margin-left: auto;
  margin-right: auto;
}
.no-link {
  text-decoration: none;
  color: black;
}
.card-title {
  text-transform: capitalize;
  word-break: normal;
}
.primary-color {
  color: #0096c7;
}
.center-list {
  max-width: 240px;
  margin: 0 auto;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}

.button-gradient-half:hover {
  background-position: right center;
}

.button-gradient-half {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 100%);
}


.button-gradient-error-half:hover {
  background-position: right center;
}
.button-gradient-error-half {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  background-image: linear-gradient(to right, #ec6608 0%, #f2e500 150%);
}
</style>
