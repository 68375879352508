<template>
  <v-container class="wrapper">
    <v-row justify="center">
      <v-col>
        <v-card class="card-form">
          <v-card-title
            class="primary white--text headline background-gradient"
          >
            <v-icon class="white-icon">mdi-lock</v-icon>
            <span class="headline ml-3">Change Password</span>
          </v-card-title>
          <v-card-text class="pt-6 pl-8">
            <v-text-field
              label="User"
              type="email"
              v-model="mail"
            ></v-text-field>
            <v-text-field
              label="New password"
              type="password"
              counter
              v-model="password"
            ></v-text-field>
            <v-text-field
              label="Repeat password"
              type="password"
              counter
              v-model="passwordCheck"
            ></v-text-field>
            <v-divider class="mt-12"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="updatePassword"
                ><div class="text-gradient-half">Update</div>
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar_show" :color="snackbar_color" top>
      <v-icon>{{ snackbar_icon }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>

    <div class="text-center">
      <v-dialog
        v-model="delete_account_click"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="center-btn">
            <v-btn
              v-bind="attrs"
              v-on="on"
              block
              class="button-gradient-error-half"
              v-model="delete_account_click"
              >Delete account</v-btn
            >
          </div>
        </template>
        <v-card>
          <v-toolbar class="background-gradient-error-half"
            >Confirm account deletion</v-toolbar
          >

          <v-card-text class="pt-5">
            Are you sure you want to delete your account permanently?
          </v-card-text>

          <v-card-actions class="justify-end">
            <v-btn text color="light" @click="delete_account_click = false"
              >Cancel</v-btn
            >
            <v-btn text @click="(delete_account_click = false), deleteAccount()"
              ><div class="text-gradient-error-half">Confirm</div>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    // Password
    password: null,
    passwordCheck: null,
    mail: null,
    // Snackbar
    snackbar_show: false,
    snackbar_color: null,
    snackbar_text: null,
    snackbar_icon: null,
    // Delete account
    delete_account_click: false,
  }),
  methods: {
    async updatePassword() {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/update-password",
          {
            method: "PUT",
            body: JSON.stringify({
              newmail: this.mail,
              password: this.password,
              passwordCheck: this.passwordCheck,
            }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
        if (response.success) {
          // Info message
          this.snackbar_color = "success";
          this.snackbar_text = "Password updated";
          this.snackbar_icon = "mdi-check";
          // Display the result as a short message
          this.snackbar_show = true;
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = response.message;
        this.snackbar_icon = "mdi-alert-circle";
      }
      // Display the result as a short message
      this.snackbar_show = true;
    },
    async deleteAccount() {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/delete-account",
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
        if (response.success) {
          // Info message
          this.snackbar_color = "success";
          this.snackbar_text = "Account deleted, all the personal data is cleared";
          this.snackbar_icon = "mdi-check";
          // Display the result as a short message
          this.snackbar_show = true;
          // Keep the user waiting 1.25s to see the message
          await new Promise((resolve) => setTimeout(resolve, 1500));
          // Delete the auth token
          this.$cookie.delete("jwt_token");
          // Go to the landing page
          this.$router.push("/register");
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = response.message;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },
  },
};
</script>

<style scoped>
.card-form {
  margin: 25px;
}

.center-btn {
  margin-top: 10px;
  padding: 22px;
}

.white-icon {
  color: white !important;
}

h3:first-letter {
  text-transform: capitalize;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 0px;
}
.background-gradient {
  color: white;
  box-shadow: 0 0 20px #eee;
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 200%);
}

.text-gradient-half {
  background: linear-gradient(to right, #047ec3 0%, #52ae32 150%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.button-gradient-error-half:hover {
  background-position: right center;
}
.button-gradient-error-half {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  background-image: linear-gradient(to right, #ec6608 0%, #f2e500 100%);
}

.background-gradient-error-half {
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(to right, #ec6608 0%, #f2e500 200%);
}

.text-gradient-error-half {
  background-image: linear-gradient(to right, #ec6608 0%, #f2e500 200%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
