import { render, staticRenderFns } from "./SignUp2.vue?vue&type=template&id=548a6daa&scoped=true&"
import script from "./SignUp2.vue?vue&type=script&lang=js&"
export * from "./SignUp2.vue?vue&type=script&lang=js&"
import style0 from "./SignUp2.vue?vue&type=style&index=0&id=548a6daa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "548a6daa",
  null
  
)

export default component.exports