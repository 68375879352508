<template>
  <div class="wrapper">
    <div class="text-center">
      <p class="text-h4 mt-6 mb-4 primary-color">
        Search for Courses & Jobs in the ALLVIEW Platform
      </p>
    </div>
    <div>
      <p class="text-h5 font-weight-black">Top Rated Courses</p>
      <v-row class="mb-0">
        <v-col
          xl="3"
          lg="3"
          md="3"
          sm="6"
          xs="6"
          v-for="course in topRatedCourses"
          :key="course.course_id"
        >
          <v-card hover min-height="350px" height="100%" class="card-outter">
            <router-link
              text
              :to="'/app/people/course/' + course.course_id"
              class="centertext no-link"
            >
              <v-img
                height="175px"
                :src="
                  VUE_APP_API_ENDPOINT +
                  'images/courses/' +
                  (course.image || 'default.jpg')
                "
                contain
              ></v-img>

              <v-card-title>
                <div class="text-subtitle-1 card-title">
                  {{ course.title.toLowerCase() }}
                </div>
              </v-card-title>
            </router-link>

            <v-card-actions class="card-actions">
              <div class="ma-2 pa-0 text-body-1">
                {{ course.partner }}
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-list class="center-list">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Explore all the courses</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <router-link to="/app/people/search-courses" class="no-link">
              <v-icon color="primary"> mdi-arrow-right-circle </v-icon>
            </router-link>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
      <br /><br />
      <p class="text-h5 font-weight-black">Recommended Courses For You</p>
      <v-row class="mb-0">
        <v-col
          xl="3"
          lg="3"
          md="3"
          sm="6"
          xs="6"
          v-for="course in recommendedCourses"
          :key="course.course_id"
        >
          <v-card hover min-height="350px" height="100%" class="card-outter">
            <router-link
              text
              :to="'/app/people/course/' + course.course_id"
              class="centertext no-link"
            >
              <v-img
                height="175px"
                :src="
                  VUE_APP_API_ENDPOINT +
                  'images/courses/' +
                  (course.image || 'default.jpg')
                "
                contain
              ></v-img>

              <v-card-title>
                <div class="text-subtitle-1 card-title">
                  {{ course.title.toLowerCase() }}
                </div>
              </v-card-title>
            </router-link>

            <v-card-actions class="card-actions">
              <div class="ma-2 pa-0 text-body-1">
                {{ course.partner }}
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-list class="center-list">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Explore All The Courses</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <router-link to="/app/people/search-courses" class="no-link">
              <v-icon color="primary"> mdi-arrow-right-circle </v-icon>
            </router-link>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
      <br /><br />
      <p class="text-h5 font-weight-black">New Jobs</p>
      <v-row class="mb-0">
        <v-col
          xl="3"
          lg="3"
          md="3"
          sm="6"
          xs="6"
          v-for="job in newJobs"
          :key="job.job_id"
        >
          <v-card hover min-height="350px" height="100%" class="card-outter">
            <router-link
              text
              :to="'/app/people/job/' + job.job_id"
              class="centertext no-link"
            >
              <v-img
                height="175px"
                :src="
                  VUE_APP_API_ENDPOINT +
                  'images/jobs/' +
                  (job.image || 'default.jpg')
                "
                contain
              ></v-img>

              <v-card-title>
                <div class="text-subtitle-1 card-title">
                  {{ job.name.toLowerCase() }}
                </div>
              </v-card-title>
            </router-link>

            <v-card-actions class="card-actions">
              <div class="ma-2 pa-0 text-body-1">
                {{ job.full_name }}
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-list class="center-list">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Explore all the jobs</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <router-link to="/app/people/search-jobs" class="no-link">
              <v-icon color="primary"> mdi-arrow-right-circle </v-icon>
            </router-link>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
      <br /><br />
      <p class="text-h5 font-weight-black">Recommended Jobs For You</p>
      <v-row class="mb-0">
        <v-col
          xl="3"
          lg="3"
          md="3"
          sm="6"
          xs="6"
          v-for="job in recommendedJobs"
          :key="job.job_id"
        >
          <v-card hover min-height="350px" height="100%" class="card-outter">
            <router-link
              text
              :to="'/app/people/job/' + job.job_id"
              class="centertext no-link"
            >
              <v-img
                height="175px"
                :src="
                  VUE_APP_API_ENDPOINT +
                  'images/jobs/' +
                  (job.image || 'default.jpg')
                "
                contain
              ></v-img>

              <v-card-title>
                <div class="text-subtitle-1 card-title">
                  {{ job.name.toLowerCase() }}
                </div>
              </v-card-title>
            </router-link>

            <v-card-actions class="card-actions">
              <div class="ma-2 pa-0 text-body-1">
                {{ job.full_name }}
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-list class="center-list">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Explore all the jobs</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <router-link to="/app/people/search-jobs" class="no-link">
              <v-icon color="primary"> mdi-arrow-right-circle </v-icon>
            </router-link>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
      <br /><br />
      <br /><br />
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",

  data: () => ({
    topRatedCourses: [],
    recommendedCourses: [],
    newJobs: [],
    recommendedJobs: [],
    // env
    VUE_APP_API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,
  }),
  async mounted() {
    try {
      await this.getData();
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        this.$router.push("/auth-error");
      }
    }
  },
  methods: {
    async getData() {
      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "people/home",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      if (response.success) {
        this.topRatedCourses = response.result.topRatedCourses;
        this.recommendedCourses = response.result.recommendedCourses;
        this.newJobs = response.result.newJobs;
        this.recommendedJobs = response.result.recommendedJobs;
        this.$emit('missingProfile', response.result.missingProfile)
      } else {
        throw new Error(response.message);
      }
    },
  },
};
</script>

<style scoped>
.centertext {
  margin-left: auto;
  margin-right: auto;
}
.no-link {
  text-decoration: none;
  color: black;
}
.card-title {
  text-transform: capitalize;
  word-break: normal;
}
.primary-color {
  color: #0096c7;
}
.center-list {
  max-width: 240px;
  margin: 0 auto;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}
</style>
