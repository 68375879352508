<template>
  <v-container class="py-6">
    <v-responsive max-width="700" class="mx-auto text-center">
      <h2 class="text-h3 text-lg-h2 mb-4 gray">Sign Up Today</h2>
    </v-responsive>

    <v-card class="pa-2 mx-auto" max-width="600" color="transparent" flat>
      <v-card-text>
        <div class="d-flex flex-column flex-md-row">
          <v-text-field
            label="Name"
            solo
            outlined
            v-model="name"
          ></v-text-field>
        </div>
        <v-text-field
          label="Email"
          solo
          outlined
          v-model="email"
        ></v-text-field>
        <v-text-field
          label="Password"
          solo
          outlined
          type="password"
          v-model="password"
        ></v-text-field>
        <v-text-field
          label="Repeat Password"
          solo
          outlined
          type="password"
          v-model="password_check"
        ></v-text-field>
        <v-select
          solo
          outlined
          label="Role"
          :items="['People', 'Training Provider', 'Company']"
          v-model="role"
        ></v-select>
        <v-btn block class="button-gradient" x-large @click="register()"
          >Create Account</v-btn
        >

        <div class="d-flex my-3">
          <v-divider class="my-1"></v-divider>
          <div class="text-overline mx-1">Or Sign Up With</div>
          <v-divider class="my-1"></v-divider>
        </div>

        <div class="d-flex justify-space-between">
          <GoogleLogin
            :params="googleParams"
            :onSuccess="googleSuccess"
            class="google-button text-gradient"
          >
            <v-btn
              outlined
              large
              class="flex-grow-1"
              color="secondary lighten-2"
            >
              <v-icon>mdi-google</v-icon>oogle
              <br />
            </v-btn>
          </GoogleLogin>
        </div>
        <div class="text-overline text-uppercase mt-3 text-center">
          <v-responsive max-width="280" class="mx-auto">
            By signing up, you agree to the
            <a href="/terms-and-conditions" target="blank_">Terms and Conditions</a>
            & <a href="/privacy-policy" target="blank_">Privacy Policy</a>
          </v-responsive>
        </div>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar_show" :color="snackbar_color" top>
      <v-row class="align-center justify-center">
        <v-col cols="1" >
          <v-icon class="align-center justify-center">{{ snackbar_icon }}</v-icon>
        </v-col>
        <v-col class="align-center justify-center">
          <span class="align-center justify-center">
            {{ snackbar_text + " " +(snackbar_link_text != null ? snackbar_link_text : "" ) }}
          </span>
        </v-col>
      </v-row>

      <v-btn v-if="snackbar_link_action"
             @click="snackbar_action(snackbar_link_action)"
             style="margin-left: 87%; margin-top: 2em; background: white;color: #5b5050;">{{snackbar_link_action}}</v-btn>

    </v-snackbar>
  </v-container>
</template>

<script>
import GoogleLogin from "vue-google-login";

export default {
  components: {
    GoogleLogin,
  },
  data: () => ({
    // Google login https://github.com/rmartide/vue-google-login
    googleParams: {
      client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
    },
    // Form data
    name: null,
    role: null,
    email: null,
    password: null,
    password_check: null,
    // Snackbar
    snackbar_show: false,
    snackbar_color: null,
    snackbar_text: null,
    snackbar_icon: null,
    snackbar_link_text: null,
    snackbar_link_action: null,
  }),
  methods: {
    async register() {
      let response;
      try {
        // Verify that all the fields are present
        if (!this.name) {
          throw new Error("The name is missing");
        } else if (!this.email) {
          throw new Error("The email is missing");
        } else if (!this.password) {
          throw new Error("The password is missing");
        } else if (!this.password_check) {
          throw new Error("The password verification is missing");
        } else if (this.password !== this.password_check) {
          throw new Error("The passwords do not match");
        } else if (!this.role) {
          throw new Error("The role is missing");
        }

        // Send a register request
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "home/register",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name: this.name,
              email: this.email,
              role: this.role,
              password: this.password,
              password_check: this.password_check,
            }),
          }
        )
        response = await response.json();
        if (response.success) {
          // Login success message
          this.login_form_show = false;
          this.snackbar_color = "success";
          this.snackbar_text =
            "Success: registered, please check your email inbox and also your spam inbox to verify your account";
          this.snackbar_icon = "mdi-check";
          this.snackbar_show = true;
          this.snackbar_link_text = null;
          this.snackbar_link_action = null;

          // Keep the user waiting to see the success message
          await new Promise((resolve) => setTimeout(resolve, 5000));

          // Redirect the user to the main page
          this.$router.push("/");
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        // Error message
        if(error.message.indexOf("Account not verified") >= 0){
          // Error message
          this.snackbar_color = "error";
          this.snackbar_text = error.message;
          this.snackbar_icon = "mdi-alert-circle";
          this.snackbar_show = true;
          this.snackbar_link_text = "Do you want to resend the verification mail?";
          this.snackbar_link_action = "Resend";
        }else {
          this.snackbar_color = "error";
          this.snackbar_text = error.message;
          this.snackbar_icon = "mdi-alert-circle";
          this.snackbar_show = true;
          this.snackbar_link_text = null;
          this.snackbar_link_action = null;
        }
      }
    },
    // https://developers.google.com/identity/sign-in/web/backend-auth
    async googleSuccess(googleUser) {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "home/google-login",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              googleToken: googleUser.getAuthResponse().id_token,
            }),
          }
        );
        response = await response.json();
        if (response.success) {
          // Save auth token as a cookie
          this.$cookie.set("jwt_token", response.result.token, 365);

          // Login success message
          this.login_form_show = false;
          this.snackbar_color = "success";
          this.snackbar_text = "Success: you are now logged in.";
          this.snackbar_icon = "mdi-check";
          this.snackbar_show = true;
          this.snackbar_link_text = null;
          this.snackbar_link_action = null;

          // Keep the user waiting 1.25s to see the success message
          await new Promise((resolve) => setTimeout(resolve, 1250));

          // Redirect the user to its home
          if (response.result.roleId === 1) {
            this.$router.push("/app/people/home");
          } else if (response.result.roleId === 2) {
            this.$router.push("/app/training-provider/home");
          } else if (response.result.roleId === 3) {
            this.$router.push("/app/company/home");
          } else if (response.result.roleId === 7) {
            this.$router.push("/backoffice");
          } else {
            // Role not defined yet
            this.$router.push("/set-role");
          }
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = response.message;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
        this.snackbar_link_text = null;
        this.snackbar_link_action = null;
      }
    },
    snackbar_action(action){
      this.snackbar_color = null;
      this.snackbar_text = null;
      this.snackbar_icon = null;
      this.snackbar_show = null;
      this.snackbar_link_text = null;
      this.snackbar_link_action = null;

      if(action === "Resend"){
        this.resendVerificationMail()
      }
    },

    async resendVerificationMail() {
      let response;
      try {
        // Verify that the email and password are present
        if (!this.email) {
          throw new Error("The email is missing");
        }

        // Send the recover password request to the backend
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "home/resend_mail",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: this.email,
            }),
          }
        );
        response = await response.json();
        if (response.success) {
          // Recovery success message
          this.login_form_show = false;
          this.snackbar_color = "success";
          this.snackbar_text =
            "Success: an email has been sent with your new verification link, please check your spam inbox.";
          this.snackbar_icon = "mdi-check";
          this.snackbar_show = true;
          this.snackbar_link_text = null;
          this.snackbar_link_action = null;

          // Keep the user waiting 1.25s to see the success message
          await new Promise((resolve) => setTimeout(resolve, 1250));
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = error.message;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
        this.snackbar_link_text = null;
        this.snackbar_link_action = null;
      }
    },
  },

};
</script>

<style scoped>
.google-button {
  max-width: 1200px;
  margin: 0 auto;
}

.button-gradient:hover {
  background-position: right center;
}

.button-gradient {
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
 /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(to right, #047EC3 0%, #52AE32 51%, #047EC3 100%);
}
.gray {
  color: #222c3a
}
</style>
