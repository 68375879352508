<template>
  <div class="wrapper">
    <div class="text-center">
      <p class="text-h4 title gray">
        Search Training Courses
      </p>
    </div>
    <v-container>
      <v-row>
        <v-col cols="12" sm="12" xs="12" md="12">
          <v-text-field
            v-model="keywords"
            label="Courses Keywords"
            placeholder="Search in job keywords and titles"
            solo
            hide-no-data
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>




      <v-row>
        <v-col cols="6" sm="6" xs="6" md="6">
          <v-autocomplete
            v-model="skillsSelectedAutocomplete"
            :loading="skillsAutocompleteLoading"
            :items="skillsAutocompleteSearch"
            :search-input.sync="skillAutocompleteSearch"
            hide-no-data
            hide-details
            placeholder="Start typing"
            label="Skills"
            chips
            clearable
            deletable-chips
            multiple
            solo
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="6" sm="6" xs="6" md="6">
          <v-autocomplete
            v-model="competencesSelectedAutocomplete"
            :loading="competencesAutocompleteLoading"
            :items="competencesAutocompleteSearch"
            :search-input.sync="competenceAutocompleteSearch"
            hide-no-data
            hide-details
            placeholder="Start typing"
            label="Competences"
            chips
            clearable
            deletable-chips
            multiple
            solo
          >
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" sm="6" md="6">
          <v-autocomplete
            label="Training format"
            v-model="modesSelected"
            :items="modes"
            item-text="type"
            chips
            clearable
            deletable-chips
            multiple
            solo
            hide-no-data
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col cols="6" sm="6" md="6">
          <v-autocomplete
            label="Topics"
            v-model="topicsSelected"
            :items="topics"
            item-text="topic"
            chips
            clearable
            deletable-chips
            multiple
            solo
            hide-no-data
            hide-details
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" sm="6" md="6">
          <v-autocomplete
            label="Languages"
            v-model="languagesSelected"
            :items="languages"
            item-text="language"
            chips
            clearable
            deletable-chips
            multiple
            solo
            hide-no-data
            hide-details
          ></v-autocomplete>
        </v-col>

        <v-col cols="6" sm="6" md="6">
          <v-autocomplete
            label="Training provider"
            :items="entities"
            item-text="partner"
            v-model="entitiesSelected"
            chips
            clearable
            deletable-chips
            multiple
            solo
            hide-no-data
            hide-details
          ></v-autocomplete>
        </v-col>
      </v-row>


      <v-row>
        <v-col cols="12" sm="6" xs="6" md="6">
          <v-autocomplete
            v-model="eqf_levels"
            :items="[{ text: 'Level 1', value: 1 }, { text: 'Level 2', value: 2 }, { text: 'Level 3', value: 3 }, { text: 'Level 4', value: 4 }, { text: 'Level 5', value: 5 }, { text: 'Level 6', value: 6 }, { text: 'Level 7', value: 7 }, { text: 'Level 8', value: 8 }]"
            item-text="text"
            item-value="value"
            placeholder="EQF Level"
            label="EQF Level"
            chips
            clearable
            deletable-chips
            multiple
            solo
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" xs="6" md="6">
          <v-autocomplete
            v-model="free_or_paid"
            :items="['Free', 'Paid']"
            placeholder="Free / Paid"
            label="Free / Paid"
            clearable
            solo
          >
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-btn block class="button-gradient" @click="searchCourses()"
            >Search
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-5">
        <v-col
          xl="3"
          lg="3"
          md="3"
          sm="6"
          xs="6"
          v-for="course in courses"
          :key="course.course_id"
        >
          <v-card hover min-height="350px" height="100%" class="card-outter">
            <!-- <router-link
              text
              :to="'/app/' + userType + '/course/' + course.course_id"
              class="centertext link no-link"
              target="_blank"
            >
              <v-img
                height="175px"
                :src="
                  VUE_APP_API_ENDPOINT +
                  'images/courses/' +
                  (course.image || 'default.jpg')
                "
              ></v-img>

              <v-card-title>
                <div class="text-subtitle-1 card-title">
                  {{ course.title.toLowerCase() }}
                </div>
              </v-card-title>
            </router-link> -->

              <v-img
                height="175px"
                :src="
                  VUE_APP_API_ENDPOINT +
                  'images/courses/' +
                  (course.image || 'default.jpg')
                "
                contain
              ></v-img>

              <v-card-title>
                <div class="text-subtitle-1 card-title">
                  {{ course.title.toLowerCase() }}
                </div>
              </v-card-title>

              <v-card-text>
                <div class="ma-2 pa-0 text-body-1">
                  {{ course.partner }}
                </div>
              </v-card-text>

              <v-card-actions class="card-actions">
                <!-- <v-btn
                  block
                  class="button-gradient-half"
                  @click.stop ="(dialog = true), (selectedCourse = course), (langs = selectedCourse.languages.toString())">
                  Details
                </v-btn> -->
                <v-btn
                  block
                  class="button-gradient-half"
                  @click.stop ="viewSelectedCourse(course)">
                  Details
                </v-btn>
                <!-- <v-btn
                  block
                  class="button-gradient-half"
                  @click="viewSelectedCourse(course)">
                  Details
                </v-btn> -->
              </v-card-actions>
          </v-card>
        </v-col>
      </v-row>


      <v-row v-if="this.last_body && !this.no_more">
        <v-col cols="12" sm="12" md="12">
          <v-btn block class="button-gradient" @click="showMore()"
          >Show more
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-dialog v-model="dialog" max-width="800">

          <course-view :selected-course = "selectedCourse"
                       v-on:close="(dialog = false)"
                       v-on:removeFromList="delFav"
          />
        </v-dialog>
      </v-row>

      <v-snackbar v-model="snackbar_show" :color="snackbar_color" :timeout="timeout" top>
        <v-icon>{{ snackbar_icon }}</v-icon>
        {{ snackbar_text }}
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import CourseView from "@/pages/app/common/CourseView";
export default {
  name: "SearchCourses",
  components: {CourseView},
  data: () => ({
    limit: 20,
    offset:0,
    last_body:null,
    no_more: false,
    order: ["rating"],
    course: "",
    timeout: 1500,
    selectedCourse: "",
    courses: [],
    topics: [],
    entities: [],
    modes: [],
    languages:[],
    langs:[],
    lang: "",
    // Selected data to search
    entitiesSelected: [],
    topicsSelected: [],
    modesSelected: [],
    languagesSelected: [],
    keywords: "",
    free_or_paid: "",
    eqf_level_text:"",
    eqf_levels: [],
    // Skills
    skillsAutocompleteLoading: false, // Loading animation autocomplete
    skillsAutocompleteSearch: [], // Results of the skills search autocomplete
    skillAutocompleteSearch: null, // Autocomplete search field
    skillsSelectedAutocomplete: [], // Autocomplete skills selected
    // Competences
    competencesAutocompleteLoading: false, // Loading animation autocomplete
    competencesAutocompleteSearch: [], // Results of the competences search autocomplete
    competenceAutocompleteSearch: null, // Autocomplete search field
    competencesSelectedAutocomplete: [], // Autocomplete competences selected
    // Snackbar
    snackbar_show: false,
    snackbar_color: null,
    snackbar_text: null,
    snackbar_icon: null,
    // Used to build the courses urls
    userType: null,
    // env
    VUE_APP_API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,
    dialog: false,
  }),
  watch: {
    skillAutocompleteSearch(name) {
      name && name !== this.select && this.searchSkillsByName(name);
    },
    competenceAutocompleteSearch(name) {
      name && name !== this.select && this.searchCompetencesByName(name);
    },
  },

  async mounted() {
    this.orderBy = "rating";
    try {
      await this.getData();
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        this.$router.push("/auth-error");
      }
    }
    this.userType = this.$route.path.match(/\/app\/(.+?)\//)[1];
  },

  methods: {
    async getData() {
      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "common/search-courses",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );

      response = await response.json();
      if (response.success) {
        this.languages = response.result.languages;
        this.modes = response.result.types;
        this.entities = response.result.partners;
        this.topics = response.result.topics;
      } else {
        throw new Error(response.message);
      }
    },
    async viewSelectedCourse(course){
      console.log(course)
      this.selectedCourse = course
      this.langs = this.selectedCourse.languages
      this.langs = this.langs.replace("[", "")
      this.langs = this.langs.replace("]", "")
      this.langs = this.langs.replaceAll("\"", "")
      this.langs = this.langs.split(",")
      this.langs = this.langs.join()
      this.dialog = true

    },
    async openDialogCourse(course_id){
      this.dialog_course = true
      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT +
        "common/courses/" + course_id,
        //this.$route.params.pathMatch,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      if (response.success) {
        this.selectedCourse = response.result;
        this.viewSelectedCourse(this.selectedCourse)
      } else {
        throw new Error(response.message);
      }
    },
    async searchSkillsByName(name) {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/search-skills-by-name",
          {
            method: "POST",
            body: JSON.stringify({ name: name }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
      } catch (error) {
        console.log(error.message);
      }
      this.skillsAutocompleteLoading = false;
      this.skillsAutocompleteSearch = response.result;
    },
    async searchCompetencesByName(name) {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT +
            "common/search-competences-by-name",
          {
            method: "POST",
            body: JSON.stringify({ name: name }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
      } catch (error) {
        console.log(error.message);
      }
      this.competencesAutocompleteLoading = false;
      this.competencesAutocompleteSearch = response.result;
    },

    // Search courses with the filters
    async searchCourses(last_body) {
      try {
        let response;
        if(last_body == null){
          this.no_more = false;
          this.courses = [];
        }

        if(last_body){
          this.last_body = last_body;
        }else{
          this.last_body = {
            keywords: this.keywords,
            free_or_paid: this.free_or_paid,
            skills: this.skillsSelectedAutocomplete,
            eqf_levels: this.eqf_levels,
            competences: this.competencesSelectedAutocomplete,
            types: this.modesSelected,
            topics: this.topicsSelected,
            languages: this.languagesSelected,
            partners: this.entitiesSelected,
            order: this.orderBy,
            limit:this.limit,
            offset: this.offset
            // minduration: this.duration[0],
            // maxduration: this.duration[1],
          };
        }



        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/search-courses",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
            body: JSON.stringify(this.last_body),
          }
        );
        response = await response.json();
        console.log(JSON.stringify(response.result))
        if (response.success) {
          //get the favourtites courses of the user
          this.coursesFavouritesIds = [];

          this.courses.push(...response.result);
          this.no_more = response.result.length < this.limit;


          if (this.courses.length === 0) {
            this.snackbar_text = "No courses found";
            this.snackbar_icon = "mdi-alert-circle";
            this.snackbar_show = true;
          }
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = error.message;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },

    async showMore(){
      if(this.last_body != null){
        this.last_body.offset += this.last_body.limit;

        await this.searchCourses(this.last_body)
      }
    },

    async addFav(course) {
      let responseInsert;
      try {
        responseInsert = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/add-fav-courses",
          {
            method: "POST",
            body: JSON.stringify({
              course_id: course.course_id,
            }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        responseInsert = await responseInsert.json();
        if (responseInsert.success) {
          this.snackbar_color = "success";
          this.snackbar_text = "Success: course added to favorites";
          this.snackbar_icon = "mdi-success-circle";
          this.snackbar_show = true;
        } else {
          throw new Error(responseInsert.message);
        }
      } catch (error) {
        if (error.message === "alreadySaved") {
          // Warning message
          this.snackbar_color = "orange";
          this.snackbar_text = "The course was already saved as favorite";
          this.snackbar_icon = "mdi-alert-outline";
        } else {
          // Error message
          this.snackbar_color = "error";
          this.snackbar_text = error.message;
          this.snackbar_icon = "mdi-alert-circle";
        }
        this.snackbar_show = true;
      }
    },
    // Delete a course in favourites
    async delFav(course) {
      let responseDelete;
      try {
        responseDelete = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/delete-fav-courses",
          {
            method: "POST",
            body: JSON.stringify({
              course_id: course.course_id,
            }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        responseDelete = await responseDelete.json();
        if (responseDelete.success) {
          this.snackbar_color = "success";
          this.snackbar_text = "Success: course removed from favorites";
          this.snackbar_icon = "mdi-success-circle";
          this.snackbar_show = true;
        } else {
          throw new Error(responseDelete.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = error.message;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },
  },
};
</script>

<style scoped>
.card-outter {
  position: relative;
  padding-bottom: 50px;
}
.card-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
  flex: 1;
}
.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 60px;
}
.no-link {
  text-decoration: none;
  color: black;
}
.card-title {
  text-transform: capitalize;
  word-break: normal;
}
.button-gradient:hover {
  background-position: right center;
}

.button-gradient {
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(
    to right,
    #047ec3 0%,
    #52ae32 51%,
    #047ec3 100%
  );
}

.gray {
  color: #222c3a;
}

.button-gradient-half:hover {
  background-position: right center;
}

.button-gradient-half {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 100%);
}

.button-gradient-error-half:hover {
  background-position: right center;
}
.button-gradient-error-half {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  background-image: linear-gradient(to right, #ec6608 0%, #f2e500 150%);
}
</style>
