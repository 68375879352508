<template>
  <div class="wrapper">
    <div class="text-center">
      <p class="text-h4 gray">Search Job Offers</p>
    </div>

    <v-form>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-text-field
              v-model="keywords"
              label="Jobs Keywords"
              placeholder="Search in job keywords and titles"
              solo
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" sm="6" xs="6" md="6">
            <v-autocomplete
              v-model="skillsSelectedAutocomplete"
              :loading="skillsAutocompleteLoading"
              :items="skillsAutocompleteSearch"
              :search-input.sync="skillAutocompleteSearch"
              hide-no-data
              hide-details
              placeholder="Start typing"
              label="Skills"
              chips
              clearable
              deletable-chips
              multiple
              solo
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              v-model="companiesSelected"
              :items="companies"
              item-text="full_name"
              label="Companies"
              chips
              clearable
              deletable-chips
              multiple
              solo
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-autocomplete
              v-model="languagesSelected"
              :items="languages"
              item-text="language"
              label="Languages used in workspace"
              chips
              clearable
              deletable-chips
              multiple
              solo
            ></v-autocomplete>
          </v-col>

          <v-col cols="6">
            <v-autocomplete
              v-model="contractsSelected"
              :items="contracts"
              label="Contract Type"
              item-text="contract"
              chips
              clearable
              deletable-chips
              multiple
              solo
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn block class="button-gradient" @click="getJobs()"
              >Search
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="mt-5">
          <v-col
            xl="3"
            lg="3"
            md="3"
            sm="6"
            xs="6"
            v-for="job in jobs"
            :key="job.job_id"
          >
            <v-card hover min-height="350px" height="100%" class="card-outter">
              <router-link
                text
                :to="'/app/' + userType + '/job/' + job.job_id"
                class="centertext link no-link"
                target="_blank"
              >
                <v-img
                  height="175px"
                  :src="
                    VUE_APP_API_ENDPOINT +
                    'images/jobs/' +
                    (job.image || 'default.jpg')
                  "
                  contain
                ></v-img>

                <v-card-title>
                  <div class="text-subtitle-1 card-title">
                    {{ job.name.toLowerCase() }}
                  </div>
                </v-card-title>
              </router-link>

              <v-card-actions class="card-actions">
                <div class="ma-2 pa-0 text-body-1">
                  {{ job.full_name }}
                </div>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-snackbar v-model="snackbar_show" :color="snackbar_color" top>
      <v-icon>{{ snackbar_icon }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>



<script>
export default {
  name: "SearchJobs",

  data() {
    return {
      jobs: [],
      languages: null,
      contracts: null,
      companies: null,
      // Selected data to search
      contractsSelected: [],
      languagesSelected: [],
      companiesSelected: [],
      keywords: null,
      // Skills
      skillsAutocompleteLoading: false, // Loading animation autocomplete
      skillsAutocompleteSearch: [], // Results of the skills search autocomplete
      skillAutocompleteSearch: null, // Autocomplete search field
      skillsSelectedAutocomplete: [], // Autocomplete skills selected
      // Snackbar
      snackbar_show: false,
      snackbar_color: null,
      snackbar_text: null,
      snackbar_icon: null,
      // Used to build the job urls
      userType: null,
      // env
      VUE_APP_API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,
    };
  },
  watch: {
    skillAutocompleteSearch(name) {
      name && name !== this.select && this.searchSkillsByName(name);
    },
  },
  async mounted() {
    this.order = "rating";
    try {
      await this.getData();
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        this.$router.push("/auth-error");
      }
    }
    this.userType = this.$route.path.match(/\/app\/(.+?)\//)[1];
  },

  methods: {
    async getData() {
      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "common/search-jobs",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      if (response.success) {
        this.languages = response.result.languages;
        this.contracts = response.result.contracts;
        this.companies = response.result.companies;
      } else {
        throw new Error(response.message);
      }
    },
    async getJobs() {
      let jobsFound;
      try {
        jobsFound = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/search-jobs",
          {
            method: "POST",
            headers: {
              "Auth-Token": this.$cookie.get("jwt_token"),
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              keywords: this.keywords,
              languages: this.languagesSelected,
              contracts: this.contractsSelected,
              order: this.order,
              skills: this.skillsSelectedAutocomplete,
              companies: this.companiesSelected,
            }),
          }
        );

        jobsFound = await jobsFound.json();
        this.jobs = jobsFound.result;

        if (this.jobs.length === 0) {
          this.snackbar_text = "No jobs found";
          this.snackbar_icon = "mdi-alert-circle";
          this.snackbar_show = true;
        }
        return this.jobs;
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = error.message;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },

    async searchSkillsByName(name) {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/search-skills-by-name",
          {
            method: "POST",
            body: JSON.stringify({ name: name }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
      } catch (error) {
        console.log(error.message);
      }
      this.skillsAutocompleteLoading = false;
      this.skillsAutocompleteSearch = response.result;
    },
  },
};
</script>

<style scoped>
.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 60px;
}
.no-link {
  text-decoration: none;
  color: black;
}
.card-title {
  text-transform: capitalize;
  word-break: normal;
}
.button-gradient:hover {
  background-position: right center;
}

.button-gradient {
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(
    to right,
    #047ec3 0%,
    #52ae32 51%,
    #047ec3 100%
  );
}

.gray {
  color: #283444;
}
</style>
