<template>
  <v-sheet color="transparent">
    <v-container >
      <div class="d-flex">
        <v-divider class="my-1"></v-divider>
        <div class="mx-3 overline">Stats</div>
        <v-divider class="my-1"></v-divider>
      </div>
      <div class="text-center">
        <v-responsive max-width="1200" class="mx-auto">
          <div class="text-h6 text-lg-h5 mt-4">
            Many users have already tried our platform, what are you waiting for?
          </div>
        </v-responsive>
      </div>
      <v-row class="mt-4">
        <v-col
          v-for="(item, i) in stats"
          :key="i"
          cols="12"
          md="4"
          class="text-center"
        >
          <div class="text-h2 text-lg-h1">{{ item.value }}</div>
          <div class="text-h6 text-lg-h5">{{ item.title }}</div>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      stats: [
        {
          title: "Registered Users",
          value: "",
        },
        {
          title: "Job Offers",
          value: "",
        },
        {
          title: "Training Courses",
          value: "",
        },
      ],
    };
  },
  async mounted() {
    // Get simple platform stats
    let response = await fetch(process.env.VUE_APP_API_ENDPOINT + "home/landing/")
    response = await response.json();
    this.stats[0].value = response.result.users
    this.stats[1].value = response.result.jobs
    this.stats[2].value = response.result.courses
  },
};
</script>
