<template>
  <div>
    <v-card>

      <v-card-title class="card-title" v-if="selectedCourse.title">
        {{
          selectedCourse.title
        }}
      </v-card-title>
      <v-card-subtitle>
        <v-row>
          <v-col>
            <v-img
              :src="VUE_APP_API_ENDPOINT + 'images/courses/' + (selectedCourse.image || 'default.jpg')"
              max-height="250px"
              max-width = "250px"
              contain
              style="margin-left: 10%; margin-top: 15%"
            >
            </v-img>
          </v-col>

          <v-col>
              <div v-html="selectedCourse.description" class="bold" style="margin-top: 15%; margin-right: 10%" v-if="selectedCourse.description"></div>

          </v-col>

        </v-row>

      </v-card-subtitle>

      <br />
      <v-divider></v-divider>
      <br />
      <v-card-text>

        <v-row>
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">

            <course-detail-view :selected-course="selectedCourse"/>

            <br />
            <br />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="addFav(selectedCourse)" v-if="selectedCourse.fav_id == null">
          Add to favorites
        </v-btn>

        <v-btn color="green darken-1" text @click="delFav(selectedCourse)" v-if="selectedCourse.fav_id != null">
          Remove from favorites
        </v-btn>

        <v-btn color="green darken-1" text @click="$emit('close')" >
          Close
        </v-btn>
      </v-card-actions>

    </v-card>

    <v-snackbar v-model="snackbar_show" :color="snackbar_color" top timeout="2000 ">
      <v-icon>{{ snackbar_icon }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </div>

</template>

<script>
import CourseDetailView from "@/pages/app/common/CourseDetailView";
export default {
  name: "CourseView",
  components: {CourseDetailView},
  props:[

    "selectedCourse"
  ],
  data: () => ( {
    courses: null,
    //selectedCourse: "",
    lang: null,
    // Snackbar
    snackbar_show: false,
    snackbar_color: null,
    snackbar_text: null,
    snackbar_icon: null,
    // Used to build the course urls
    userType: null,
    dialog: false,
    // env
    VUE_APP_API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,
  }),

  async mounted() {
    /*
    try {
      await this.getData();
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        this.$router.push("/auth-error");
      }
    }
    */
    this.userType = this.$route.path.match(/\/app\/(.+?)\//)[1];
  },

  methods: {
    async getData() {
      let response = await fetch(
        // We reuse the search-courses endpoint since it returns the fav courses
        process.env.VUE_APP_API_ENDPOINT + "common/search-courses",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      if (response.success) {
        this.courses = response.result.courses;
      } else {
        throw new Error(response.message);
      }
    },
    async getCourse() {
      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT +
        "common/courses/" +  this.selectedCourse.course_id,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      if (response.success) {
        this.course = response.result
        this.lang = this.course.language.JOIN()
      } else {
        throw new Error(response.message);
      }
    },
    // Insert a course in favourites
    async addFav() {
      let responseInsert;
      try {
        responseInsert = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/add-fav-courses",
          {
            method: "POST",
            body: JSON.stringify({
              course_id: this.selectedCourse.course_id,
            }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        responseInsert = await responseInsert.json();
        if (responseInsert.success) {
          this.snackbar_color = "success";
          this.snackbar_text = "Success: course added to favorites";
          this.snackbar_icon = "mdi-success-circle";
          this.snackbar_show = true;

          this.selectedCourse.fav_id = -1;

          this.$emit('addToList',this.selectedCourse);
          setTimeout(()=>{

            this.snackbar_show = false;
            //this.$emit('close');
          },1500);

        } else {
          throw new Error(responseInsert.message);
        }
      } catch (error) {
        if (error.message === "alreadySaved") {
          // Warning message
          this.snackbar_color = "orange";
          this.snackbar_text = "The course was already saved as favorite";
          this.snackbar_icon = "mdi-alert-outline";
        } else {
          // Error message
          this.snackbar_color = "error";
          this.snackbar_text = error.message;
          this.snackbar_icon = "mdi-alert-circle";
        }
        this.snackbar_show = true;
      }
    },
    // Delete a course in favourites
    async delFav() {
      console.log(JSON.stringify(this.selectedCourse))
      let responseDelete;
      try {
        responseDelete = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/delete-fav-courses",
          {
            method: "POST",
            body: JSON.stringify({
              course_id: this.selectedCourse.course_id,
            }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        responseDelete = await responseDelete.json();
        if (responseDelete.success) {
          this.snackbar_color = "success";
          this.snackbar_text = "Success: course removed from favorites";
          this.snackbar_icon = "mdi-success-circle";
          this.snackbar_show = true;

          this.selectedCourse.fav_id = null;
          this.$emit('removeFromList',this.selectedCourse.course_id);

          setTimeout(()=>{
            this.snackbar_show = false;
            //this.$emit('close');
          },1500);
        } else {
          throw new Error(responseDelete.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = error.message;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }


    },
  }
}

</script>

<style scoped>
.card-outter {
  position: relative;
  padding-bottom: 50px;
}
.card-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
  flex: 1;
}

.detailbtn {
  float: right;
}

.cart {
  margin-bottom: 100px;
  padding: 40px;
}

.detacurso {
  margin-bottom: 20px;
}

.titlecourse {
  margin-top: 40px;
  margin-bottom: 20px;
}

.title {
  margin-bottom: 25px;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}

.no-link {
  text-decoration: none;
  color: black;
}
.card-title {
  text-transform: capitalize;
  word-break: normal;
}
.gray {
  color: #283444;
}

.button-gradient:hover {
  background-position: right center;
}

.button-gradient {
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(
    to right,
    #047ec3 0%,
    #52ae32 51%,
    #047ec3 100%
  );
}

.button-gradient-half {
  text-transform: uppercase;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 100%);
}
</style>
