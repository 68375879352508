<template>
  <div>
    <Toolbar/>
    <SignUp/>
  </div>
</template>

<script>
import Toolbar from "@/components/carbon-ui/toolbar/Toolbar2.vue";
import SignUp from "@/components/carbon-ui/signup/SignUp2.vue";

export default {
  components: {
    SignUp,
    Toolbar
  }
}
</script>