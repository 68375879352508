<template>
  <v-container class="wrapper">
    <div class="text-center">
      <div class="text-h4 gray">My Favorite Jobs</div>
      <div v-if="jobs && jobs.length === 0">
        <p class="text-center mt-2">
          You don't have any saved jobs at the moment
        </p>
      </div>
    </div>
    <v-row class="mt-5">
      <v-col
        xl="3"
        lg="3"
        md="3"
        sm="6"
        xs="6"
        v-for="job in jobs"
        :key="job.job_id"
      >
        <v-card hover min-height="350px" height="100%" class="card-outter">
          <router-link
            text
            :to="'/app/' + userType + '/job/' + job.job_id"
            class="centertext link no-link"
          >
            <v-img
              height="175px"
              :src="
                VUE_APP_API_ENDPOINT +
                'images/jobs/' +
                (job.image || 'default.jpg')
              "
              contain
            ></v-img>

            <v-card-title>
              <div class="text-subtitle-1 card-title">
                {{ job.name.toLowerCase() }}
              </div>
            </v-card-title>
          </router-link>

          <v-card-actions class="card-actions">
            <div class="ma-2 pa-0 text-body-1">
              {{ job.full_name }}
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MyJobs",
  data() {
    return {
      jobs: null,
      // Snackbar
      snackbar_show: false,
      snackbar_color: null,
      snackbar_text: null,
      snackbar_icon: null,
      // Used to build the job urls
      userType: null,
      // env
      VUE_APP_API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,
    };
  },

  async mounted() {
    try {
      await this.getData();
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        this.$router.push("/auth-error");
      }
    }
    this.userType = this.$route.path.match(/\/app\/(.+?)\//)[1];
  },

  methods: {
    async getData() {
      let response = await fetch(
        // We reuse the search-courses endpoint since it returns the fav jobs
        process.env.VUE_APP_API_ENDPOINT + "common/search-jobs",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      if (response.success) {
        this.jobs = response.result.jobs;
      } else {
        throw new Error(response.message);
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}

.no-link {
  text-decoration: none;
  color: black;
}
.card-title {
  text-transform: capitalize;
  word-break: normal;
}
.gray {
  color: #283444;
}
</style>
