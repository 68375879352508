<template>
  <!-- change if you cant see the rest of filters-->
  <div style="max-width: 1200px; margin: 0 auto; margin-top: 30px">
    <v-form>
      <v-container>
        <v-row>
          <v-col cols="6" sm="6" xs="6" md="6">
            <v-autocomplete
              v-model="skillSelected"
              :items="skillsName"
              chips
              clearable
              label="Skills"
              multiple
              solo
            >
              <template v-slot:selection="{ item }">
                <v-chip>
                  <strong>{{ item }}</strong
                  >&nbsp;
                  <span>(skill)</span>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="competenceSelected"
              :items="competencesName"
              chips
              clearable
              label="Select competences"
              multiple
              solo
            >
              <template v-slot:selection="{ item }">
                <v-chip>
                  <strong>{{ item }}</strong
                  >&nbsp;
                  <span>(competence)</span>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="experienceSelected"
              :items="experiencesName"
              chips
              clearable
              label="Select experiences"
              multiple
              solo
            >
              <template v-slot:selection="{ item }">
                <v-chip>
                  <strong>{{ item }}</strong
                  >&nbsp;
                  <span>(experience)</span>
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-btn block class="ma-3" color="primary" @click="searchUsers()"
          >Search
        </v-btn>
      </v-col>
    </v-row>

    <div class="ma-5 pa-0" v-for="user in users" :key="user" tile>
      <v-card min-height="10" max-width="830">
        <span  style="display: none">{{ user.user_id }}</span>

        <v-avatar size="100" style="display: inline-block">
          <v-img :src="'http://localhost:8081/images/' + user.image"> </v-img>
        </v-avatar>

        <v-card-title class="ma-2 pa-0">{{ user.full_name }}</v-card-title>
         <v-card-title class="ma-2 pa-0">{{ user.email }}</v-card-title>
      </v-card>
    </div>


    <v-snackbar v-model="snackbar_show" :color="snackbar_color" top>
      <v-icon>{{ snackbar_icon }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "SearchUsers",

  data: () => ({
    users: [],
    skills: [],
    skillsName: [],
    competencesName: [],
    competences: [],
    experiences: [],
    experiencesName: [],
    skillSelected: [],
    experienceSelected: [],
    competenceSelected: [],

    snackbar_show: false,
    snackbar_color: null,
    snackbar_text: null,
    snackbar_icon: null,
  }),
  mounted() {
    this.getCompetences();
    this.getSkills();
    this.getExperiences();
  },

  methods: {
    async getSkills() {
      let skillsResults;
      try {
        skillsResults = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "skills/get-skillsname",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        skillsResults = await skillsResults.json();
        this.skills = skillsResults.result;

        this.skills.forEach((skill) => {
          this.skillsName.push(skill.skill_name);
        });

        return this.skills;
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = "Something unexpected has occurred";
        this.snackbar_show = true;
      }
    },

    async getExperiences() {
      let experiencesFound;

      try {
        experiencesFound = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "getOccupations",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        experiencesFound = await experiencesFound.json();
        var experiences = experiencesFound.result;

        experiences.forEach((experience) => {
          this.experiencesName.push(experience.occupation_name);
        });
        return this.experiencesName;
      } catch (error) {
        console.log(error.message);
      }
    },

    async getCompetences() {
      let competencesResults;
      try {
        competencesResults = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "skills/get-competencesname",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        competencesResults = await competencesResults.json();
        this.competences = competencesResults.result;

        this.competences.forEach((competence) => {
          this.competencesName.push(competence.skill_name);
        });

        return this.competences;
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = "Something unexpected has occurred";
        this.snackbar_show = true;
      }
    },

    async searchUsers() {
      try {
        let response;

        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "users/lookingfor-user",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              competence: this.competenceSelected,
              experience: this.experienceSelected,
              skill: this.skillSelected,
            }),
          }
        );
        response = await response.json();
        if (response.success) {
          this.users = response.result;
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text =
          "Something unexpected has occurred " + error.message;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
        console.log(error.message);
      }
    },
  },
};
</script>