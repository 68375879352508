<template>
  <div class="wrapper">
    <div class="text-center">
      <p class="text-h4 mt-6 mb-4 primary-color">
        Jobs
      </p>
    </div>
    <div>
      <v-row class="mb-0">
        <template>
          <div style="text-align: center; width: 100%">
            <v-card-title>
              <p class="text-h5 font-weight-black">All Jobs</p>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details></v-text-field>
            </v-card-title>

            <v-data-table
              :headers="headers"
              :items="jobs"
              :options.sync="options"
              :loading="loading"
              :items-per-page="5"
              :search="search"
              class="elevation-1"
            >

            <template v-slot:item.actions="{ item }">


              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>

            <template v-slot:top>

              <v-btn class="button-gradient-half"
                  style="margin-top: 8px; margin-left: 20px; margin-bottom: 8px"
                  @click="downloadCSV">
                    Download CSV
                </v-btn>

                    <v-dialog
                      v-model="dialog"
                      max-width="1000px"
                      ref="dialog"
                      id="dialog"
                    >
                      <v-card ref="dialog_vcard" id="dialog_vcard" >
                        <v-card-title>
                          <span class="text-h5">Edit Job</span>
                        </v-card-title>

                        <v-card-text style="overflow-y: auto;" id="card">


                          <v-container id="container">

                            <div style="display: flex; margin-bottom: 18px">
                              <div class="image-container">
                                <v-snackbar v-model="snackbar_show" :color="snackbar_color" top>
                                  <v-icon>{{ snackbar_icon }}</v-icon>
                                  {{ snackbar_text }}
                                </v-snackbar>
                                <v-img
                                  v-model="imagePic"
                                  class="display-inline uploaded-image"
                                  width="400px"
                                  height="300px"
                                  :src="(editedItem.image || VUE_APP_API_ENDPOINT +
                                     'images/jobs/' + 'default.jpg')"
                                  @click="openImageFileDialog(editedIndex)"
                                >
                                </v-img>
                              </div>
                              <div>
                                <a v-if="editedItem.image" style="vertical-align: top; margin-left: 8px !important;"
                                   v-on:click="removeImage(editedItem.job_id,editedIndex)"> X </a>

                                  <v-file-input
                                    ref = "img_ctrl"
                                    :id = "'image-input-'+editedIndex"
                                    :rules="imageRules"
                                    accept="image/png, image/jpeg, image/bmp"
                                    placeholder="Upload job photo (500x300px) **Optional"
                                    v-model="imageData.image"
                                    prepend-icon="mdi-camera"
                                    class="input"
                                    chips
                                    show-size
                                    @change="uploadJobImage(editedItem.job_id,editedIndex,false)"
                                  ></v-file-input>

                                <!--
                                <v-btn
                                  v-if="jobs[index].image"
                                  block
                                  class="button-gradient-half"
                                  @click.stop="removeImage(jobs[index].job_id,index)">
                                  Eliminar imagen
                                </v-btn>
                                -->
                              </div>

                            </div>

                                <v-text-field
                                  placeholder="Job name"
                                  v-model="editedItem.name"
                                  counter
                                  maxlength="60"
                                />

                              <v-text-field
                                placeholder="A brief description of the job"
                                label="Description"
                                v-model="editedItem.description"
                                counter
                                maxlength="120"
                              />

                              <v-combobox
                                v-model="editedItem.sector"
                                :items="sectors"
                                label="Professional Sector (ESCO classification and others included by the user)*"
                                placeholder="Start typing"
                                clearable
                                chips
                                multiple
                                deletable-chips
                                flat
                              >
                              </v-combobox>
                            <v-combobox
                              v-model="editedItem.essentialSkillsSelected"
                              :loading="essentialSkillsAutocompleteLoading"
                              :items="essentialSkillsAutocompleteSearch"
                              :search-input.sync="essentialSkillAutocompleteSearch"
                              label="Demanded Skills (ESCO classification and others included by the user) *"
                              placeholder="Start typing"
                              clearable
                              chips
                              multiple
                              deletable-chips
                              flat
                            >
                            </v-combobox>

                            <br />

                            <v-combobox
                              v-model="editedItem.optionalSkillsSelected"
                              :loading="optionalSkillsAutocompleteLoading"
                              :items="optionalSkillsAutocompleteSearch"
                              :search-input.sync="optionalSkillAutocompleteSearch"
                              label="Optional Skills (ESCO classification and others included by the user)"
                              placeholder="Start typing"
                              clearable
                              chips
                              multiple
                              deletable-chips
                              flat
                            >
                            </v-combobox>

                            <v-combobox
                              v-model="editedItem.experiencesSelected"
                              :loading="experiencesAutocompleteLoading"
                              :items="experiencesAutocompleteSearch"
                              :search-input.sync="experienceAutocompleteSearch"
                              label="Job Requeriments *"
                              placeholder="Start typing"
                              clearable
                              chips
                              multiple
                              deletable-chips
                              flat
                            >
                            </v-combobox>

                            <v-select
                              v-model="editedItem.languages"
                              :items="languages"
                              item-text="language"
                              item-value="id"
                              hide-no-data
                              hide-details
                              label="Languages used in workspace *"
                              placeholder="Select a language"
                              multiple
                              flat
                            />

                            <br/>

                            <v-select
                              v-model="editedItem.contract_types"
                              :items="contracts"
                              hide-no-data
                              hide-details
                              label="Type of contract *"
                              placeholder="Select a language"
                              multiple
                              flat
                            />

                            <v-text-field

                              v-model="editedItem.place"
                              label="Job place"
                              placeholder="Place"
                            />

                            <v-container>
                              <v-row>

                                <v-col cols="12" lg="6">

                                  <v-menu
                                    v-model="editedItem.init_date_modal"
                                    :close-on-content-click="true"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                  >
                                    <template v-slot:activator="{ on, attrs }">

                                      <v-text-field
                                        v-model="editedItem.date_dialog_data_start_formatted"
                                        label="Start date of job position"
                                        hint="DD/MM/YYYY format"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        clearable
                                        @click:clear="editedItem.init_date = null"
                                      ></v-text-field>

                                    </template>
                                    <v-date-picker v-model="editedItem.date_dialog_data_start" no-title @input="editedItem.init_date_modal = false"
                                                   @change="
                              editedItem.date_dialog_data_start_formatted = formatDate(editedItem.date_dialog_data_start);
                              editedItem.init_date = editedItem.date_dialog_data_start;
                            ">

                                    </v-date-picker>
                                  </v-menu>
                                </v-col>
                                <v-col cols="12" lg="6">
                                  <v-menu
                                    v-model="editedItem.end_date_modal"
                                    :close-on-content-click="true"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="editedItem.date_dialog_data_end_formatted"
                                        label="End date of job position"
                                        hint="DD/MM/YYYY format"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        clearable
                                        @click:clear="editedItem.end_date = null"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="editedItem.date_dialog_data_end" no-title @input="editedItem.end_date_modal = false"
                                                   @change="
                                                      editedItem.date_dialog_data_end_formatted = formatDate(editedItem.date_dialog_data_end);
                                                      editedItem.end_date = editedItem.date_dialog_data_end;

                                                    "
                                    ></v-date-picker>
                                  </v-menu>
                                </v-col>
                              </v-row>
                            </v-container>

                            <v-text-field
                              v-model="editedItem.contact_details"
                              label="Contact data *"
                              placeholder="Start typing"
                            />
                            <v-text-field
                              v-model="editedItem.salary"
                              suffix="€"
                              label="Gross salary"
                              placeholder="Start typing"
                              type="number"
                            />
                            <v-checkbox
                              label="Receive recommendations about candidates"
                              v-model="editedItem.receive_candidates_recommendations"
                            ></v-checkbox>

                            <v-card class="mt-3 mb-3" >

                              <v-container>

                                <v-row>
                                  <v-checkbox
                                    class="ms-2"
                                    v-model="editedItem.expired"
                                    label="Expire job "
                                  ></v-checkbox>

                                  <v-checkbox
                                    v-if="!editedItem.expired"
                                    class="ms-2"
                                    v-model="editedItem.manual_publish"
                                    label="Publish offer (disable this option if you want to schedule the job publication)"
                                  ></v-checkbox>

                                </v-row>
                                <!--
                                <v-row v-if="jobs[index].manual_publish">
                                  <p class="caption text-decoration-none secondary--text ms-2" >
                                    * You have to disable this option for been able to schedule the job publication
                                  </p>
                                </v-row>
                                -->
                                <v-row v-if="!editedItem.manual_publish && !editedItem.expired">
                                  <v-col cols="12" lg="6">
                                    <v-menu
                                      v-model="editedItem.publish_init_date_modal"
                                      :close-on-content-click="true"
                                      transition="scale-transition"
                                      offset-y
                                      max-width="290px"
                                      min-width="290px"
                                    >
                                      <template v-slot:activator="{ on, attrs }">

                                        <v-text-field
                                          v-model="editedItem.publish_date_dialog_data_start_formatted"
                                          label="Publish after date"
                                          hint="DD/MM/YYYY format"
                                          persistent-hint
                                          prepend-icon="mdi-calendar"
                                          readonly
                                          v-bind="attrs"
                                          v-on="on"
                                          :disabled="editedItem.manual_publish"
                                          clearable
                                          @click:clear="editedItem.publish_ini = null"
                                        ></v-text-field>

                                      </template>
                                      <v-date-picker v-model="editedItem.publish_date_dialog_data_start" no-title @input="editedItem.publish_init_date_modal = false"
                                                     @change="
                              editedItem.publish_date_dialog_data_start_formatted = formatDate(editedItem.publish_date_dialog_data_start);
                              editedItem.publish_ini = editedItem.publish_date_dialog_data_start;
                            ">

                                      </v-date-picker>
                                    </v-menu>
                                  </v-col>
                                  <v-col cols="12" lg="6">
                                    <v-menu
                                      v-model="editedItem.publish_end_date_modal"
                                      :close-on-content-click="true"
                                      transition="scale-transition"
                                      offset-y
                                      max-width="290px"
                                      min-width="290px"
                                    >
                                      <template v-slot:activator="{ on, attrs }">

                                        <v-text-field
                                          v-model="editedItem.publish_date_dialog_data_end_formatted"
                                          label="Publish end date"
                                          hint="DD/MM/YYYY format"
                                          persistent-hint
                                          prepend-icon="mdi-calendar"
                                          readonly
                                          v-bind="attrs"
                                          v-on="on"
                                          :disabled="editedItem.manual_publish"
                                          clearable
                                          @click:clear="editedItem.publish_end = null"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker v-model="editedItem.publish_date_dialog_data_end" no-title @input="editedItem.publish_end_date_modal = false"
                                                     @change="

                              editedItem.publish_date_dialog_data_end_formatted = formatDate(editedItem.publish_date_dialog_data_end);
                              editedItem.publish_end = editedItem.publish_date_dialog_data_end;

                            "
                                      ></v-date-picker>
                                    </v-menu>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <p class="caption text-decoration-none secondary--text ms-2 me-2" v-if="!editedItem.manual_publish && !editedItem.expired">
                                    * You can schedule the publication of the offer by setting the start and end publication dates. If neither date is set and the "Publish Offer" option is not enabled, the offer will never be published.
                                  </p>
                                </v-row>
                              </v-container>
                            </v-card>

                            <p class="caption text-decoration-none secondary--text">
                              * Required field
                            </p>


                            <resource-editor :entity-id="editedItem.job_id" entity-table="jobs" mode="EDITOR"></resource-editor>

                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="save"
                          >
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="dialogDelete = false">Cancel</v-btn>
                          <v-btn color="blue darken-1" text @click="deleteItemSelected">OK</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
            </template>

            </v-data-table>
            <v-snackbar
              v-model="snack"
              :timeout="1500"
              :color="snackColor"
            >
              {{ snackText }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  v-bind="attrs"
                  text
                  @click="snack = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
          </div>
        </template>

      </v-row>
      <br /><br />
      <br /><br />
    </div>
  </div>
</template>

<script>
import ResourceEditor from "@/pages/app/common/ResourceEditor.vue";

export default {
  name: "Home",
  components: {ResourceEditor},

  data: () => ({
    imageRules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Avatar size should be less than 2 MB!",
    ],
    imageData: {
      image: null,
    },
    imagePic: [],
    ///
    skills: [],
    skillsName: [],
    contracts: [],
    sectors: [],
    experiencesName: [],
    langJobs:[],
    languages: [],
    experiences: [],
    ///
    // Skills
    essentialSkillsSelected: [], // Skills saved by the user
    essentialSkillsAutocompleteLoading: false, // Loading animation autocomplete
    essentialSkillsAutocompleteSearch: [], // Results of the skills search autocomplete
    essentialSkillAutocompleteSearch: null, // Autocomplete search field
    essentialSkillsSelectedAutocomplete: [], // Autocomplete skills selected
    optionalSkillsSelected: [], // Skills saved by the user
    optionalSkillsAutocompleteLoading: false, // Loading animation autocomplete
    optionalSkillsAutocompleteSearch: [], // Results of the skills search autocomplete
    optionalSkillAutocompleteSearch: null, // Autocomplete search field
    optionalSkillsSelectedAutocomplete: [], // Autocomplete skills selected
    // Experiences
    experiencesSelected: [], // Experiences saved by the user
    experiencesAutocompleteLoading: false, // Loading animation autocomplete
    experiencesAutocompleteSearch: [], // Results of the experiences search autocomplete
    experienceAutocompleteSearch: null, // Autocomplete search field
    experiencesSelectedAutocomplete: [], // Autocomplete experiences selected
    ///
    topRatedCourses: [],
    recommendedCourses: [],
    newJobs: [],
    recommendedJobs: [],
    totalJobs: 0,
    jobs: [],
    loading: true,
    options: {},
    dialogDelete: false,
    dialog: false,
    editedItem: {},
    snack: false,
    snackColor: '',
    snackText: '',
    textarea: '',
    arr:[],
    editedIndex: -1,
    defaultItem: {},
    items_language: [],
    items_contract: [],
    // Snackbar
    snackbar_show: false,
    snackbar_color: null,
    snackbar_text: null,
    snackbar_icon: null,
    // this.headers = ["id", "Name", "E-Mail", "Phone", "State", "Verified"],
    headers: [
    {text: 'id', value: 'job_id'},
    {text: 'Name', value: 'name'},
    {text: 'Description', value: 'description'},
    {text: 'Company', value: 'company_name'},
    //{text: 'Contact', value: 'contactperson'},
    {text: 'Status', value: 'status'},
   // {text: 'E-Mail', value: 'email'},
    {text: 'Created', value: 'mi_c_at'},
    {text: 'Actions', value: 'actions'}
    ],
    search: '',
    // env
    VUE_APP_API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,
  }),
  watch: {
    essentialSkillAutocompleteSearch(name) {
      name &&
      name !== this.select &&
      this.searchSkillsByName(name, "essential");
    },
    optionalSkillAutocompleteSearch(name) {
      name && name !== this.select && this.searchSkillsByName(name, "optional");
    },
    experienceAutocompleteSearch(name) {
      name && name !== this.select && this.searchExperiencesByName(name);
    },
  },
  async mounted() {
    try {
      await this.getData();
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        this.$router.push("/auth-error");
      }
    }
  },
  methods: {

    previewImage(event){
      console.log("previewImage",event)
      this.imagePic = event.target.files[0]
    },
    async removeImage(job_id,jobIndex){
      if(!this.imageData){
        this.imageData = {
          image: null,
          name: 'noname'
        };
      }
      this.imageData.image = null
      this.uploadJobImage(job_id,jobIndex,true)
    },
    async uploadJobImage(job_id,jobIndex,delete_image) {
      let response;
      //console.log("EL NOMBRE DE LA FOTO ES: " + this.imageData.image.name)
      try {
        //console.log("LAS IMAGENES SON: " + this.imageData.image.name)
        console.log("uploadJobImage "+job_id,this.imageData)
        if (delete_image || (this.imageData.image.name != null || this.imageData.image.name) ) {
          console.log("Entro aquí",this.imageData)
          let formData = new FormData();
          if(delete_image){
            formData.append("delete_image",true);
          }else {
            formData.append(
              "image",
              this.imageData.image,
              this.imageData.image.name
            );
          }
          formData.append("job_id", job_id);
          // Remove the file from the browser
          this.imageData.image = null;
          response = await fetch(
            process.env.VUE_APP_API_ENDPOINT + "company/upload-job-image",
            {
              method: "POST",
              body: formData,
              // Weirdly, the auth-token is not sent as an auth-token header
              // We need to sent it with the cookies header (credentials: include)
              headers: {
                "Auth-Token": this.$cookie.get("jwt_token"),
              },
            }
          );
          response = await response.json();
          console.log(response)
          if (response.success) {
            this.snackbar_color = "success";
            this.snackbar_text = "Image uploaded succesfully";
            this.snackbar_icon = "mdi-alert-circle";
            this.snackbar_show = true;

            if(response.result){

              if(response.result.image_deleted){
                let job = this.jobs.find(o => o.job_id === job_id)
                job.image = null;
                this.editedItem.image = null;
                this.imagePic =  this.VUE_APP_API_ENDPOINT +'images/jobs/default.jpg'
                console.log("DELETE IMAGE check job index to update image",job,this.editedItem)
                this.imageData.image = null;
                this.imageData.name = null;

              }else if(response.result.filename){
                let job = this.jobs.find(o => o.job_id === job_id)
                job.image = response.result.filename;
                this.editedItem.image = this.VUE_APP_API_ENDPOINT +'images/jobs/'+response.result.filename
                this.imagePic =  this.VUE_APP_API_ENDPOINT +'images/jobs/'+response.result.filename
                this.imageData.image = null;
                this.imageData.name = null;
              }
            }
          }
        }
      } catch (error) {
        // The browser returns a CORS error but the request succeeds
        // This is a quick fix to avoid displaying a error message to
        // the user even if the request is successful
        if (response.success) return;
        this.snackbar_color = "error";
        this.snackbar_text = error;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },
    async getData() {
      this.loading = true;
      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "backoffice/jobs",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      if (response.success) {
        //AQUÍ TENEMOS QUE RELLENAR LA TABLA
        this.languages = response.result.languages;
        this.sectors = response.result.sectors;
        this.contracts = response.result.contracts;
        this.jobs = response.result.jobs;

        this.loading = false;
        console.log(response)

        this.items_language = response.result.languages
        this.items_contract = response.result.contracts
        const now = new Date(); // Fecha y hora actuales

        for (let i = 0; i<this.jobs.length; i++){
          let job = this.jobs[i];
          job.mi_c_at =  new Date(job.created_at).toLocaleDateString()


          job.experiencesSelected = JSON.parse(job.job_requirements)
          this.langJobs.length = 0

          job.contract_types = job.contract_types ? JSON.parse(job.contract_types) : []
          if(job.contract_types.length == 0 && job.contract){
            job.contract_types.push(job.contract)
          }

          job.languages = job.languages ? JSON.parse(job.languages) : []
          job.init_date_modal = false
          job.end_date_modal = false
          job.publis_init_date_modal = false
          job.publis_end_date_modal = false

          if(job.init_date){
            //console.log("test",this.formatDateToYYYYMMDD(new Date(course.init_date)));
            job.init_date = this.formatDateToYYYYMMDD(new Date(job.init_date))
            job.init_date = job.init_date.substring(0,10)
            job.date_dialog_data_start = job.init_date
            job.date_dialog_data_start_formatted = this.formatDate(job.init_date)
          }

          if(job.end_date){
            job.end_date = this.formatDateToYYYYMMDD(new Date(job.end_date))
            job.end_date =  job.end_date.substring(0,10)
            job.date_dialog_data_end = job.end_date
            job.date_dialog_data_end_formatted = this.formatDate(job.end_date)
          }

          job.publis_init_date_modal = false
          job.publis_end_date_modal = false

          if(job.publish_ini){
            //console.log("test",this.formatDateToYYYYMMDD(new Date(course.init_date)));
            job.publish_ini = this.formatDateToYYYYMMDD(new Date(job.publish_ini))
            job.publish_ini = job.publish_ini.substring(0,10)
            job.publish_date_dialog_data_start = job.publish_ini
            job.publish_date_dialog_data_start_formatted = this.formatDate(job.publish_ini)
          }

          if(job.publish_end){
            job.publish_end = this.formatDateToYYYYMMDD(new Date(job.publish_end))
            job.publish_end =  job.publish_end.substring(0,10)
            job.publish_date_dialog_data_end = job.publish_end
            job.publish_date_dialog_data_end_formatted = this.formatDate(job.publish_end)
          }

          if(job.manual_publish == 0){
            job.manual_publish = false
          }else if(job.manual_publish == 1){
            job.manual_publish = true
          }

          if(job.expired == 0){
            job.expired = false
          }else if(job.expired == 1){
            job.expired = true
          }

          this.getUserPic(job)

          job.status = "NO STATUS";
          if(job.expired){
            job.status = "MANUAL EXPIRED";
          }else if(job.manual_publish === 1 || job.manual_publish === true){
            job.status = "MANUAL PUBLISHED";
          }else{
            let publishIniPassed = job.publish_ini === null || now > new Date(job.publish_ini);
            let publishEndNotPassed = job.publish_end === null || now < new Date(job.publish_end);
            if(publishIniPassed && publishEndNotPassed){
              job.status = "PUBLISHED";
            }else if(!publishIniPassed){
              job.status = "NOT JET";
            }else{
              job.status = "EXPIRED";
            }
          }
        }

        this.arr = response.result.jobs
        this.totalJobs = this.arr.length
        var arrJobs = []
        this.arr.forEach(function(val){
          //console.log(val)

          var objAux = {
            "id": val.job_id,
            "name": val.name,
            "email": val.email,
            "phone": val.phone,
            //"status": status,
            "description": val.description,
            "company_name": val.company_name,
            "contactperson": val.contact_details,
            "created_at": new Date(val.created_at).toLocaleDateString(),
            "experiencesSelected" : JSON.parse(val.job_requirements)
          }
          arrJobs.push(objAux)
        })
        console.log(arrJobs.length)

        // this.jobs = arrJobs

        this.$emit('missingProfile', response.result.missingProfile)

      } else {
        this.loading = false
        throw new Error(response.message);
      }
    },

    deleteItem(item){
      console.log(item)
      this.dialogDelete = true
      this.editedIndex = this.jobs.indexOf(item)
      //this.editedItem = Object.assign({}, item)
      this.editedItem = item
    },

    editItem(item){
      console.log(item)
      let itemAux = this.jobs.find(o => o.job_id === item.job_id)
      console.log("EL OBJETO ES: " + JSON.stringify(itemAux))
      this.editedItem.job_id = itemAux.job_id
      this.editedItem.company_id = itemAux.company_id
      this.editedItem.company_name = itemAux.company_name
      this.editedItem.name = itemAux.name
      this.editedItem.salary = itemAux.salary
      this.editedItem.contact_details = itemAux.contact_details
      //this.editedItem.languaje = itemAux[0].languaje_id
      //this.editedItem.contract = itemAux[0].name
      var lang = this.items_language.filter(o=>o.id == itemAux.language_id)
      this.editedItem.language = lang[0]

      var cont = this.items_contract.filter(o=>o.id == itemAux.contract_id)
      this.editedItem.contract = cont[0]

      this.editedItem.sector = itemAux.sector
      this.editedItem.essentialSkillsSelected = itemAux.essentialSkillsSelected
      this.editedItem.optionalSkillsSelected = itemAux.optionalSkillsSelected
      this.editedItem.place = itemAux.place

      this.editedItem.duration = itemAux.duration
      if (itemAux.receive_candidates_recommendations == 1){
        this.editedItem.receive_recommendations = true
      }else{
        this.editedItem.receive_recommendations = false
      }
      this.editedItem.image = itemAux.image
      this.terxtarea = itemAux.description
      this.editedItem.description = itemAux.description
      console.log(itemAux.description)

      this.editedItem.experiencesSelected = itemAux.experiencesSelected;

      this.editedItem.contract_types = itemAux.contract_types;

      this.editedItem.languages = itemAux.languages;
      this.editedItem.init_date_modal = itemAux.init_date_modal;
      this.editedItem.end_date_modal = itemAux.end_date_modal;
      this.editedItem.publis_init_date_modal = itemAux.publis_init_date_modal;
      this.editedItem.publis_end_date_modal = itemAux.publis_end_date_modal;

      this.editedItem.init_date = itemAux.init_date;
      this.editedItem.date_dialog_data_start = itemAux.date_dialog_data_start;
      this.editedItem.date_dialog_data_start_formatted = itemAux.date_dialog_data_start_formatted;

      this.editedItem.end_date = itemAux.end_date;
      this.editedItem.date_dialog_data_end = itemAux.date_dialog_data_end;
      this.editedItem.date_dialog_data_end_formatted = itemAux.date_dialog_data_end_formatted;

      this.editedItem.publis_init_date_modal = itemAux.publis_init_date_modal;
      this.editedItem.publis_end_date_modal = itemAux.publis_end_date_modal;

      this.editedItem.publish_ini = itemAux.publish_ini;
      this.editedItem.publish_date_dialog_data_start = itemAux.publish_date_dialog_data_start;
      this.editedItem.publish_date_dialog_data_start_formatted = itemAux.publish_date_dialog_data_start_formatted;

      this.editedItem.publish_end = itemAux.publish_end;
      this.editedItem.publish_date_dialog_data_end = itemAux.publish_date_dialog_data_end;
      this.editedItem.publish_date_dialog_data_end_formatted = itemAux.publish_date_dialog_data_end_formatted;


      this.$set(this.editedItem, 'manual_publish', itemAux.manual_publish);
      this.$set(this.editedItem, 'expired', itemAux.expired);

      this.dialog = true
      // setTimeout(() => {
      //   this.scrollToTop();
      // }, 300);
    },
    // scrollToTop() {
    //   const dialogContent = this.$refs.dialog_vcard;
    //   if (dialogContent) {
    //     dialogContent.scrollTo({left:0,top:0});
    //   }
    // },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDialog(item) {
      this.dialog = false
      this.editedIndex = this.jobs.indexOf(item)
      //this.editedItem = Object.assign({}, item)
      // this.editedItem = item
    },

    async save(){
      let error = [];
      //
      // if(this.editedItem.name.length === 0){
      //   error.push("Job title is mandatory")
      // }
      // if(this.editedItem.essentialSkillsSelected && this.editedItem.essentialSkillsSelected.length === 0){
      //   error.push("Demanded Skills is mandatory")
      // }
      // if(this.editedItem.experiencesSelected && this.editedItem.experiencesSelected.length === 0){
      //   error.push("Job requirements is mandatory")
      // }
      // if(this.editedItem.contract_types && this.editedItem.contract_types.length === 0){
      //   error.push("Contract type is mandatory")
      // }
      // if(this.editedItem.contact_details.length === 0){
      //   error.push("Contact data is mandatory")
      // }

      if(!this.editedItem.receive_candidates_recommendations){
        this.editedItem.receive_candidates_recommendations = false
      }
      if(!this.editedItem.place){
        this.editedItem.place = false
      }

      if (error.length > 0){
        this.snackbar_color = "error";
        this.snackbar_text = error.join(',');
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }else{
        console.log("EL VALOR DEL TEXTAREA ES: " + this.textarea)
        console.log("EL OBJETO EDITED ITEM ES: " + JSON.stringify(this.editedItem))

        try {
          let response = await fetch(
            //process.env.VUE_APP_API_ENDPOINT + "backoffice/update-job",
            process.env.VUE_APP_API_ENDPOINT + "company/update-job",
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                "Auth-Token": this.$cookie.get("jwt_token"),
              },
              body: JSON.stringify({
                jobs: this.editedItem,
              }),
            }
          );

          response = await response.json();
          if (response.success) {
            this.getData()
            this.dialog = false
            this.snack = true
            this.snackColor = 'success'
            this.snackText = 'Job updated'
          } else {
            throw new Error(response.message);
          }

        }catch (error){
          // Error message
          this.snackbar_color = "error";
          this.snackbar_text = error.message;
          this.snackbar_icon = "mdi-alert-circle";
          this.snackbar_show = true;
        }
      }


    },

    close(){
      this.closeDialog
    },

    async deleteItemSelected(){
      console.log(JSON.stringify(this.editedItem))
      // this.jobs = this.jobs.filter( o => o.id != this.editedItem.id)
      await fetch(
        process.env.VUE_APP_API_ENDPOINT + "backoffice/delete-jobs",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
          body: JSON.stringify({
              job_to_delete: this.editedItem.job_id,
              user_id: this.$cookie.get("jwt_token")
          })
        }
      );


      this.snack = true
      this.snackColor = 'success'
      this.snackText = 'Job deleted'
      console.log("remove item",this.editedItem.job_id)
      console.log("remove item",JSON.stringify(this.jobs))
      let index = this.jobs.findIndex(o => o.job_id === this.editedItem.job_id);
      if(index > -1)
        this.jobs.splice(index, 1);
      // this.jobs.splice(0, this.jobs.length);
      //
      // await this.getData()

      this.closeDelete()

    },

    async downloadCSV(){
      import(/* webpackChunkName: "js2excel" */ 'js2excel').then(({json2excel}) => {
        json2excel({
            data: this.arr,
            name: 'Jobs',
            formateDate: 'dd/mm/yyyy'
        });
      }).catch((e) => {
          console.log(e.toString())
      });
    },
    formatDateToYYYYMMDD(date) {
      const anio = date.getFullYear();
      const mes = String(date.getMonth() + 1).padStart(2, "0");
      const dia = String(date.getDate()).padStart(2, "0");
      return `${anio}-${mes}-${dia}`;
    },
    formatDate(date) {
      console.log("formatDate",date)
      if (!date) return null
      if(date.length > 10){
        const [year, month, day] = date.substring(0,10).split(/[/-]/)

        console.log("formatDate",`${day}/${month}/${year}`)
        return `${day}/${month}/${year}`
      }else{
        const [year, month, day] = date.split(/[/-]/)

        console.log("formatDate",`${day}/${month}/${year}`)
        return `${day}/${month}/${year}`
      }

    },

    async getUserPic(job){
      // let response = await fetch(
      //   process.env.VUE_APP_API_ENDPOINT + "company/user-profile/",
      //   {
      //     headers: {
      //       "Auth-Token": this.$cookie.get("jwt_token"),
      //     },
      //   }
      // );
      // response = await response.json();
      // // console.log(response.result.personalInformation)
      // // console.log(job.image  + "------" + response.result.personalInformation.image )
      // if (response.success){
      //   if (!job.image || job.image == response.result.personalInformation.image ){
      //     if(response.result.personalInformation.image) {
      //       var userPic = process.env.VUE_APP_API_ENDPOINT + "images/profiles/" + response.result.personalInformation.image
      //       job.image = userPic
      //       console.log("LA IMAGEN recibida GETPIC ES: " + job.image)
      //     }
      //   }else{
      //     job.image =  process.env.VUE_APP_API_ENDPOINT + "images/jobs/" + job.image
      //     //this.imagePic.src = process.env.VUE_APP_API_ENDPOINT + "images/jobs/" + job.image
      //   }
      // }
      if(job.image)
        job.image =  process.env.VUE_APP_API_ENDPOINT + "images/jobs/" + job.image
    },

    openImageFileDialog(index){
      if(document.getElementById('image-input-'+index))
        document.getElementById('image-input-'+index).click();
    },


    async searchSkillsByName(name, type) {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/search-skills-by-name",
          {
            method: "POST",
            body: JSON.stringify({ name: name }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
      } catch (error) {
        console.log(error.message);
      }
      if (type === "essential") {
        this.essentialSkillsAutocompleteLoading = false;
        this.essentialSkillsAutocompleteSearch = response.result;
      } else if (type === "optional") {
        this.optionalSkillsAutocompleteLoading = false;
        this.optionalSkillsAutocompleteSearch = response.result;
      }
    },


    async searchExperiencesByName(name) {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT +
          "common/search-experiences-by-name?level=3",
          {
            method: "POST",
            body: JSON.stringify({ name: name }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
      } catch (error) {
        console.log(error.message);
      }
      this.experiencesAutocompleteLoading = false;
      this.experiencesAutocompleteSearch = response.result;
    },
  },


};
</script>

<style scoped>
.centertext {
  margin-left: auto;
  margin-right: auto;
}
.no-link {
  text-decoration: none;
  color: black;
}
.card-title {
  text-transform: capitalize;
  word-break: normal;
}
.primary-color {
  color: #0096c7;
}
.center-list {
  max-width: 240px;
  margin: 0 auto;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}


.button-gradient-half:hover {
  background-position: right center;
}

.button-gradient-half {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 100%);
}


.image-container {
  width: 400px; /* Ancho deseado del contenedor */
  height: 300px; /* Alto deseado del contenedor */

  justify-content: center;
  align-items: center;
}
.uploaded-image{
  object-fit: cover; /* Puedes ajustar esto según tus necesidades */
}
</style>
