var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"wrapper"},[_c('div',{staticClass:"text-center"},[_c('h1',{staticClass:"text-h4 gray mb-4"},[_vm._v("Rate Training Courses")])]),_c('v-autocomplete',{attrs:{"label":"Select a course to rate","items":_vm.courses,"item-text":"title","return-object":""},on:{"change":function($event){return _vm.courseAltered()}},model:{value:(_vm.course),callback:function ($$v) {_vm.course=$$v},expression:"course"}}),(_vm.ratingCourse)?_c('p',{staticClass:"p-0 mt-2"},[_vm._v("What rate do you give it?")]):_vm._e(),(_vm.ratingCourse)?_c('v-rating',{attrs:{"empty-icon":"mdi-star-outline","full-icon":"mdi-star","half-icon":"mdi-star-half-full","color":"yellow","hover":""},model:{value:(_vm.rating),callback:function ($$v) {_vm.rating=$$v},expression:"rating"}}):_vm._e(),(_vm.ratingCourse)?_c('v-textarea',{attrs:{"label":"Any comments about this course? (Optional)","rows":"1","auto-grow":""},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}):_vm._e(),(_vm.ratingCourse)?_c('v-textarea',{attrs:{"label":"Would you mind telling us what made you do this course? (Optional)","rows":"1","auto-grow":""},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}}):_vm._e(),(_vm.ratingCourse)?_c('v-btn',{staticClass:"button-gradient-half",attrs:{"block":""},on:{"click":function($event){return _vm.rateCourse()}}},[_vm._v("Send Rating")]):_vm._e(),_c('v-card',{staticClass:"mt-3"},[_c('v-list',{attrs:{"subheader":""}},[_c('v-subheader',[_c('strong',[_vm._v("List of courses rated: pending of approval comments")])]),_vm._l((_vm.pendingCourses),function(course){return _c('v-list-item',{key:course.course_id},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.VUE_APP_API_ENDPOINT +
              'images/courses/' +
              (course.image || 'default.jpg')}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(course.title)}})],1),_c('v-list-item-icon',[_c('router-link',{staticClass:"no-link",attrs:{"to":'/app/' + _vm.userType + '/course/' + course.course_id}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-arrow-right-circle ")])],1)],1)],1)})],2)],1),_c('v-card',{staticClass:"mt-3"},[_c('v-list',{attrs:{"subheader":""}},[_c('v-subheader',[_c('strong',[_vm._v("List of courses rated: comments accepted")])]),_vm._l((_vm.acceptedCourses),function(course){return _c('v-list-item',{key:course.course_id},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.VUE_APP_API_ENDPOINT +
              'images/courses/' +
              (course.image || 'default.jpg')}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(course.title)}})],1),_c('v-list-item-icon',[_c('router-link',{staticClass:"no-link",attrs:{"to":'/app/' + _vm.userType + '/course/' + course.course_id}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-arrow-right-circle ")])],1)],1)],1)})],2)],1),_c('v-card',{staticClass:"mt-3"},[_c('v-list',{attrs:{"subheader":""}},[_c('v-subheader',[_c('strong',[_vm._v("List of courses rated: comments rejected")])]),_vm._l((_vm.rejectedCourses),function(course){return _c('v-list-item',{key:course.course_id},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.VUE_APP_API_ENDPOINT +
              'images/courses/' +
              (course.image || 'default.jpg')}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(course.title)}})],1),_c('v-list-item-icon',[_c('router-link',{staticClass:"no-link",attrs:{"to":'/app/' + _vm.userType + '/course/' + course.course_id}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-arrow-right-circle ")])],1)],1)],1)})],2)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar_color,"top":""},model:{value:(_vm.snackbar_show),callback:function ($$v) {_vm.snackbar_show=$$v},expression:"snackbar_show"}},[_c('v-icon',[_vm._v(_vm._s(_vm.snackbar_icon))]),_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }