<template>
  <v-sheet>
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col v-for="(item, i) in features" :key="i" cols="12" md="4">
          <v-card outlined height="100%">
            <v-img :src="item.image" height="280"></v-img>
            <v-responsive max-width="360" class="mx-auto text-center pa-4">
              <div class="text-h5 mt-5">{{ item.title }}</div>
              <div class="text-h6 mt-5">{{ item.text }}</div>
              <div class="my-4">
                <router-link v-if="item.link.to" :to="item.link.to" class=" text-gradienttext-decoration-none font-weight-bold text-h6">{{ item.link.title }}</router-link>
                <a
                  v-else
                  class="text-decoration-none font-weight-bold text-h6 text-gradient"
                  :href="item.link.href"
                  :target="item.link.target || 'blank'"
                >{{ item.link.title }}</a>
              </div>
            </v-responsive>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      features: [{
        title: 'ESCO',
        image: '/images/landing-feature-1.jpg',
        text: 'ESCO is the multilingual classification of European Skills, Competences, Qualifications and Occupations. ESCO is part of the Europe 2020 strategy. The ESCO classification identifies and categorises skills, competences, qualifications and occupations relevant for the EU labour market and education and training. It systematically shows the relationships between the different concepts.',
        link: {
          title: 'Learn More',
          href: 'https://ec.europa.eu/esco/portal'
        }
      }, {
        title: 'Recommendations',
        image: '/images/landing-feature-2.jpg',
        text: 'By using machine learning recommender systems, the platform performs recommendations of training courses, job offers, skills, and competences based on similar user profiles. These recomendations allows to improve the connection among people, training providers and companies for the European wood and furniture industry.',
        link: {
          title: 'Learn More',
          href: 'https://en.wikipedia.org/wiki/Recommender_system'
        }
      }, {
        title: 'Centres of vocational excellence',
        image: '/images/landing-feature-3.jpg',
        text: 'CoVEs aim to drive innovation and excellence in vocational education and training, by bringing together a wide range of local partners. VET providers, employers, chambers, research centres, development agencies, employment services, and social partners work together creating “skills ecosystems”, that contribute to innovation, economic and social development at a regional and European level.',
        link: {
          title: 'Learn More',
          href: 'https://ec.europa.eu/social/main.jsp?catId=1501'
        }
      }]
    }
  }
}
</script>

<style scoped>
.text-gradient {
  background: linear-gradient(to right, #047ec3 0%, #52ae32 150%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gray {
  color: #222c3a
}
</style>

