<template>
  <v-card class="mt-3 mb-3" v-if="mode==='EDITOR' || resources.length > 0">
    <v-card-title>
      <span class="text-body-1 font-weight-bold">Related Resources</span>
    </v-card-title>
    <v-container fluid
      class="bg-surface-variant "
    >
      <v-row
        align="start"
        no-gutters
      >
        <v-col
          v-for="res in resources" :key="res.resId"
        >
          <v-card class="ma-1" @click="cardClick(res)" width="280">
            <v-card-title>
              <v-icon  left color="primary" v-if="res.resType === 'File'">  mdi-file-outline </v-icon>
              <v-icon  left color="primary" v-else >  mdi-link-variant  </v-icon>
              <span class="text-body-1 font-weight-bold">{{res.resName}}</span>
            </v-card-title>
            <v-card-subtitle><span class="text-body-2 ml-4 font-weight-bold">{{res.resDesc}}</span></v-card-subtitle>
            <v-card-actions v-if="mode === 'EDITOR'">
              <v-list-item class="grow" >
                <v-row
                  align="center"
                  justify="end"
                >
                  <v-btn  plain  size="small" v-if="res.resType === 'File'" @click.stop="download(res)">Download</v-btn>
                  <v-btn  plain  size="small" v-else @click.stop="openLink(res)">Open Link</v-btn>
                  <v-btn  plain  size="small" @click.stop="remove(res)">Remove</v-btn>
                </v-row>
              </v-list-item>
            </v-card-actions>
          </v-card>

        </v-col>
      </v-row>
    </v-container>

    <v-form v-if="mode === 'EDITOR'">
      <div style="margin-left: 28px;margin-right: 28px" >
        <div class="d-flex resource-row" >
            <v-select
              class="ms-2 me-2"
              label="Select type"
              :items="['Link', 'File']"
              v-model="resType"
            ></v-select>
            <v-text-field
              class="ms-2 me-2"
              v-model="resName"
              label="Resource name"
            ></v-text-field>
            <v-text-field
              class="ms-2 me-2"
              v-model="resDesc"
              label="Resource Desc"
            ></v-text-field>
          </div>
        <div class="d-flex resource-row" v-if="resType == 'Link' || resType == 'Video' ">
            <v-text-field
              class="ms-2 me-2"
              v-model="resUrl"
              label="Resource URL"
            ></v-text-field>
        </div>
        <div class="d-flex resource-row" v-if="resType == 'File'">
          <v-file-input
            :id = "'resource-input-'"
            accept="*/*"
            class="input"
            chips
            show-size
            v-model="resourceFile"
          ></v-file-input>
        </div>
        <v-row justify="end" v-if="entityId">
          <v-col cols="12" sm="6" md="4">
            <v-btn block  size="x-large" variant="flat" @click.stop="clear()">Clear</v-btn>
          </v-col>

          <v-col cols="12" sm="6" md="4" @click.stop="addResource()">
            <v-btn block  size="x-large">Add Resource</v-btn>
          </v-col>

        </v-row>
        <v-row v-else class="ma-2" style="padding-bottom: 30px !important;">
        <span class="text-body-1 font-weight-bold warning pa-2  d-flex align-center" >
           <v-icon  left color="grow" >  mdi-alert-circle </v-icon>
          Resources can only be added to existing entities, please save the entity before add any associated resource.
        </span>
        </v-row>
      </div>

    </v-form>

  </v-card>
</template>

<script>
export default {
  name: "ResourceEditor",
  props: {
    entityTable: {
      type: String,
      required: true,
    },
    entityId: {
      type: Number,
      required: false,
    },
    mode: {
      type: String,
      def: "READ",
    },
  },
  data: () => ({
    resType: "",
    resName: "",
    resDesc: "",
    resUrl:"",
    resources:[],
    resourceFile: undefined
  }),
  async mounted() {
    try {
      await this.getData();
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        this.$router.push("/auth-error");
      }
    }
  },
  methods:{
    clear(){
      this.resType = "";
      this.resName =  "";
      this.resDesc =  "";
      this.resUrl = "";
    },

    addResource(){
      if(this.resName.length === 0) {
        alert("Add a resource name")
      }else if(this.resType.toLowerCase() === "file" && this.resourceFile == null){
        alert("Add a file")
      }else if(this.resType.toLowerCase() === "link" && this.resUrl.length === 0){
        alert("Add a url")
      }else if(this.resType.toLowerCase() === "video" && this.resUrl.length === 0){
        alert("Add a url")
      }else{
        this.uploadResource()
      }
      /*
      if(this.resType == "Link"){
        if(this.resUrl.length == 0) {
          alert("falta resUrl")
        }else{
          this.addResourceData({
            resType:  this.resType,
            resName:  this.resName,
            resDesc:  this.resDesc,
            resUrl: this.resUrl,
          })
        }
      }

       */

    },

    addResourceData(resource){
      this.resources.push({
        resId: -1,
        resType: resource.resType,
        resName: resource.resName,
        resDesc: resource.resDesc,
        resUrl:resource.resUrl,
        resOwner: -1,
      })
    },

    async remove(resource) {
      console.log("remove")
      let response;
      try {
        let formData = new FormData();
        formData.append("resource_id", resource.resId);
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/delete-resource",
          {
            method: "POST",
            body: formData,
            // Weirdly, the auth-token is not sent as an auth-token header
            // We need to sent it with the cookies header (credentials: include)
            headers: {
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
        console.log(response)
        if (response.success || response.ok) {

          const index = this.resources.findIndex(objeto =>
            objeto.resId === resource.resId
          );
          if (index > -1) {
            this.resources.splice(index, 1)
          }
          //this.imagePic = response.result;
        }
        //}
      } catch (error) {
        // The browser returns a CORS error but the request succeeds
        // This is a quick fix to avoid displaying a error message to
        // the user even if the request is successful

        console.log("Error", error)

      }
    },

    async uploadResource() {
      let response;
      try {
        console.log("uploadResource",this.resourceFile)
        let formData = new FormData();
        if (this.resourceFile != null) {
          formData.append(
            "file",
            this.resourceFile,
            this.resourceFile.name
          );
        } else {
          formData.append(
            "url",
            this.resUrl
          );
        }
        formData.append("type", this.resType);
        formData.append("name", this.resName);
        formData.append("desc", this.resDesc);
        formData.append("res_type", this.resType);
        formData.append("entityTable", this.entityTable);
        formData.append("entityId", this.entityId);
        // Remove the file from the browser
        this.resourceFile = null;
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/add-resource",
          {
            method: "POST",
            body: formData,
            // Weirdly, the auth-token is not sent as an auth-token header
            // We need to sent it with the cookies header (credentials: include)
            headers: {
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
        console.log(response)
        if (response.success || response.ok) {
          console.log("add",JSON.stringify({
            resId: response.result.id,
            resType: response.result.type,
            resName: response.result.name,
            resDesc: response.result.desc,
            resUrl:response.result.uri,
            resOwner: response.result.userId,
          }))
          this.resources.push({
            resId: response.result.id,
            resType: response.result.type,
            resName: response.result.name,
            resDesc: response.result.desc,
            resUrl:response.result.uri,
            resOwner: response.result.userId,
          })
          this.clear()
          //this.imagePic = response.result;
        }
        //}
      } catch (error) {
        // The browser returns a CORS error but the request succeeds
        // This is a quick fix to avoid displaying a error message to
        // the user even if the request is successful

        console.log("Error",error)

      }
    },
    async getData() {
      try {
        let formData = new FormData();
        formData.append("entityTable", this.entityTable);
        formData.append("entityId", this.entityId);
        let response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/get-resource",
          {
            method: "POST",
            body: formData,
            headers: {
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
        console.log(response.result)
        if (response.success || response.ok) {
          if(response.result && response.result.length > 0) {
            for (let i = 0; i<response.result.length ; i++) {
              const item = response.result[i]
              this.resources.push({
                resId: item.id,
                resType: item.type,
                resName: item.name,
                resDesc: item.desc,
                resUrl:item.content,
                resOwner: item.userId,
              })
            }
          }

          //this.imagePic = response.result;
        }
      } catch (error) {
        // The browser returns a CORS error but the request succeeds
        // This is a quick fix to avoid displaying a error message to
        // the user even if the request is successful

        console.log("Error",error)

      }
    },
    async download(resource) {
      console.log("download",resource)
      let strWindowFeatures = "location=yes,height=570,width=520,scrollbars=yes,status=yes";
      let URL = process.env.VUE_APP_API_ENDPOINT +"images/resources/"+ resource.resUrl;
      console.log("download",URL)
      window.open(URL, "_blank", strWindowFeatures);
    },
    openLink(resource) {
      console.log("openLink",resource)
      let strWindowFeatures = "location=yes,height=570,width=520,scrollbars=yes,status=yes";
      window.open(resource.resUrl, "_blank", strWindowFeatures);
    },
    cardClick(resource) {
      if(resource.resType === "File"){
        this.download(resource)
      }else{
        this.openLink(resource)
      }
    }
  }
}
</script>

<style scoped>
  .resource-row{
    margin-top: 0px;
  }
</style>
