<template>
  <v-container class="wrapper">
    <v-row justify="center">
      <v-col>
        <v-card class="card-form">
          <v-card-title
            class="background-gradient primary white--text headline"
          >
            <v-icon class="white-icon">mdi-account-edit</v-icon>
            <span class="headline ml-3">Publish Training Courses</span>
            <v-spacer></v-spacer>
            <v-btn
              small
              text
              elevation="0"
              @click="
                courses.push({
                  title: 'New Course',
                })
              "
            >
              <v-icon class="white-icon"> mdi-plus </v-icon>
            </v-btn>
          </v-card-title>

          <v-expansion-panels>
            <v-expansion-panel v-for="(course, index) in courses" :key="index">
              <v-expansion-panel-header class="font-weight-medium">
                {{ course.title }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text class="pt-4 pl-8">

                  <v-img
                    ref = "courseImage"
                    class="display-inline"
                    width="200px"
                    :src=" imageData.imageURL ? imageData.imageURL : ( VUE_APP_API_ENDPOINT +'images/courses/' + (course.image || 'default.jpg')) "
                  >
                  </v-img>

                  <a v-if="course.image" style="vertical-align: top"
                     v-on:click="courses[index].image = null; course.image = null;"> X </a>

                  <v-file-input
                    :rules="imageRules"
                    accept="image/png, image/jpeg, image/bmp"
                    placeholder="Upload photo (500x300px) *Optional"
                    v-model="imageData.image"
                    prepend-icon="mdi-camera"
                    class="input"
                    chips
                    show-size
                    @change="handleImageChange"
                  ></v-file-input>

                  <v-text-field
                    v-model="courses[index].title"
                    label="Title *"
                    :rules="formRules"
                  ></v-text-field>

                  <v-text-field
                    v-model="courses[index].description"
                    label="Description"
                  ></v-text-field>
                  <!--
                  <v-text-field
                    v-model="courses[index].duration"
                    label="Duration (Deprecated)"
                    placeholder="Type a duration like: One month or two weeks"
                  ></v-text-field>
                  -->
                  <v-container>
                    <v-row>

                      <v-col cols="12" lg="6"  md="6">

                        <v-menu
                          v-model="courses[index].init_date_modal"
                          :close-on-content-click="true"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">

                            <v-text-field
                              v-model="courses[index].date_dialog_data_start_formatted"
                              label="Start date"
                              hint="DD/MM/YYYY format"
                              persistent-hint
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              clearable
                              @click:clear="courses[index].init_date = null"
                            ></v-text-field>

                          </template>
                          <v-date-picker v-model="courses[index].date_dialog_data_start" no-title @input="courses[index].init_date_modal = false"
                                         @change="
                              courses[index].date_dialog_data_start_formatted = formatDate(courses[index].date_dialog_data_start);
                              courses[index].init_date = courses[index].date_dialog_data_start;

                            ">

                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" lg="6"  md="6">

                        <v-menu
                          v-model="courses[index].end_date_modal"
                          :close-on-content-click="true"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="courses[index].date_dialog_data_end_formatted"
                              label="End date"
                              hint="DD/MM/YYYY format"
                              persistent-hint
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              clearable
                              @click:clear="courses[index].end_date = null"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="courses[index].date_dialog_data_end" no-title @input="courses[index].end_date_modal = false"
                                         @change="

                              courses[index].date_dialog_data_end_formatted = formatDate(courses[index].date_dialog_data_end);
                              courses[index].end_date = courses[index].date_dialog_data_end;

                            "
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-container>

                  <v-combobox
                    v-model="courses[index].partner"
                    :items="partners"
                    placeholder="Select a training provider or type a new one"
                    label="Training Provider Name *"
                    :rules="formRules"
                  ></v-combobox>

                  <!-- <v-combobox
                    v-model="courses[index].category"
                    :items="categories"
                    placeholder="Select a category or type a new one"
                    label="Category *"
                  ></v-combobox> -->

                  <v-select
                    v-model="courses[index].topicsSelected"
                    :items="topics"
                    hide-no-data
                    hide-details
                    label="Topics"
                    placeholder="Select one or more topics"
                    multiple
                    flat
                  >
                  </v-select>
                  <br>

                  <!-- <v-text-field
                    v-model="courses[index].learning_outcomes"
                    label="Learning outcomes"
                  ></v-text-field> -->

                  <v-text-field
                    v-model="courses[index].keywords"
                    label="Comma-Separated Keywords *"
                  ></v-text-field>

                  <v-combobox
                    v-model="courses[index].skills"
                    :items="skills"
                    placeholder="Start typing"
                    label="Skills"

                    multiple
                    hide-no-data
                    hide-details
                    clearable
                    chips
                    deletable-chips
                  >
                  </v-combobox>
                  <v-banner>
                    <p class="caption text-decoration-none secondary--text" >
                      * This service uses the ESCO classification of the European Commission.
                    </p>
                  </v-banner>

                  <v-combobox
                    v-model="courses[index].competences"
                    :items="competences"
                    placeholder="Start typing"
                    label="Competences"

                    multiple
                    hide-no-data
                    hide-details
                    clearable
                    chips
                    deletable-chips
                  >
                  </v-combobox>
                 <v-banner>
                   <p class="caption text-decoration-none secondary--text" >
                     * This service uses the ESCO classification of the European Commission.
                   </p>
                 </v-banner>

                  <v-combobox
                    v-model="courses[index].type"
                    :items="types"
                    placeholder="Select a course type or create a new one"
                    label="Course Type"
                  ></v-combobox>

                  <v-select
                    v-model="courses[index].access"
                    :items="accesses"
                    placeholder="Select an access type"
                    label="Access Type"
                  ></v-select>

                  <v-select
                    v-model="courses[index].license"
                    :items="licenses"
                    hide-no-data
                    hide-details
                    label="License *"
                    placeholder="Select a license"
                    flat
                  />

                  <!-- <v-text-field
                    v-model="courses[index].institution"
                    label="Name of the institution"
                  ></v-text-field> -->

                  <v-text-field
                    v-model="courses[index].contact_mail"
                    label="Contact E-Mail"
                  ></v-text-field>

                  <v-text-field
                    v-model="courses[index].weblink"
                    label="Weblink"
                  ></v-text-field>

                  <v-row>
                    <v-col cols="12" lg="6" md="6" >
                      <v-select
                        v-model="langCourses[index]"
                        :items="languages"
                        hide-no-data
                        hide-details
                        label="Language *"
                        placeholder="Select a language"
                        multiple
                        flat
                      />
                    </v-col>

<!--                  <v-select-->
<!--                    v-model="courses[index].created_at"-->
<!--                    :items="dateOfCreation"-->
<!--                    label="Date of creation"-->
<!--                  ></v-select>-->

                    <v-col cols="6" lg="6"  md="6">

                      <v-menu
                        v-model="courses[index].created_at_modal"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">

                          <v-text-field
                            v-model="courses[index].date_dialog_created_at_formatted"
                            label="Date of creation"
                            hint="DD/MM/YYYY format"
                            persistent-hint
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            @click:clear="courses[index].created_at = null"
                          ></v-text-field>

                        </template>
                        <v-date-picker v-model="courses[index].date_dialog_created_at" no-title @input="courses[index].created_at_modal = false"
                                       @change="
                                courses[index].date_dialog_created_at_formatted = formatDate(courses[index].date_dialog_created_at);
                                courses[index].created_at = courses[index].date_dialog_created_at;
                              ">

                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <!-- <v-combobox
                    v-model="courses[index].relevance"
                    :items="relevances"
                    placeholder="Select a course relevance or type a new one"
                    label="Course Relevance"
                  ></v-combobox> -->

                  <v-text-field
                    v-model="courses[index].eqf_nqf_level"
                    label="EQF NQF level"
                  ></v-text-field>

                  <v-text-field
                    v-model="courses[index].ecvet_points"
                    label="ECVET/ECTS points"
                  ></v-text-field>

                  <v-text-field
                    v-model="courses[index].archive_name"
                    label="Link to online training platform (if applicable)"
                  ></v-text-field>

                  <v-text-field
                    v-model="courses[index].media"
                    label="Type of training material (e.g. pdf, videos, lab work, etc.)"
                  ></v-text-field>

                  <v-checkbox
                    v-model="courses[index].receive_candidates_recommendations"
                    label="Receive recommendations about candidates"
                  ></v-checkbox>

                  <p class="caption text-decoration-none secondary--text">
                    * Required field
                  </p>
                </v-card-text>

                <v-card class="mt-3 mb-3" >

                  <v-container>
                    <v-row>
                      <v-checkbox
                        class="ms-2"
                        v-model="courses[index].expired"
                        label="Expire course "
                      ></v-checkbox>
                      <v-checkbox
                        v-if="!courses[index].expired"
                        class="ms-2"
                        v-model="courses[index].manual_publish"
                        label="Publish offer (disable this option if you want to schedule the course publication)"
                      ></v-checkbox>

                    </v-row>
                    <!--
                    <v-row v-if="courses[index].manual_publish">
                      <p class="caption text-decoration-none secondary--text ms-2" >
                        * You have to disable this option for been able to schedule the job publication
                      </p>
                    </v-row>
                    -->
                    <v-row v-if="!courses[index].manual_publish && !courses[index].expired">
                      <v-col cols="12" lg="6"  md="6">
                        <v-menu
                          v-model="courses[index].publish_init_date_modal"
                          :close-on-content-click="true"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">

                            <v-text-field
                              v-model="courses[index].publish_date_dialog_data_start_formatted"
                              label="Publish after date"
                              hint="DD/MM/YYYY format"
                              persistent-hint
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :disabled="courses[index].manual_publish"
                              clearable
                              @click:clear="courses[index].publish_ini = null"
                            ></v-text-field>

                          </template>
                          <v-date-picker v-model="courses[index].publish_date_dialog_data_start" no-title @input="courses[index].publish_init_date_modal = false"
                                         @change="
                              courses[index].publish_date_dialog_data_start_formatted = formatDate(courses[index].publish_date_dialog_data_start);
                              courses[index].publish_ini = courses[index].publish_date_dialog_data_start;
                            ">

                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" lg="6"  md="6">
                        <v-menu
                          v-model="courses[index].publish_end_date_modal"
                          :close-on-content-click="true"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">

                            <v-text-field
                              v-model="courses[index].publish_date_dialog_data_end_formatted"
                              label="Publish end date"
                              hint="DD/MM/YYYY format"
                              persistent-hint
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :disabled="courses[index].manual_publish"
                              clearable
                              @click:clear="courses[index].publish_end = null"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="courses[index].publish_date_dialog_data_end" no-title @input="courses[index].publish_end_date_modal = false"
                                         @change="

                              courses[index].publish_date_dialog_data_end_formatted = formatDate(courses[index].publish_date_dialog_data_end);
                              courses[index].publish_end = courses[index].publish_date_dialog_data_end;

                            "
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <p class="caption text-decoration-none secondary--text ms-2 me-2" v-if="!courses[index].manual_publish && !courses[index].expired" >
                        * You can schedule the publication of the course by setting the start and end publication dates. If neither date is set and the "Publish Offer" option is not enabled, the course will never be published.
                      </p>
                    </v-row>
                  </v-container>
                </v-card>

                <resource-editor :entity-id="courses[index].course_id" entity-table="courses" mode="EDITOR" ></resource-editor>

                <v-divider class="mt-12"></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn v-if="course.course_id" text @click="duplicate(course,index)">
                    <div class="text-gradient-half">Duplicate</div>
                  </v-btn>
                  <v-btn v-if="course.course_id" text @click="deleteCourses(course.course_id)">
                    <div class="text-gradient-half">Delete</div>
                  </v-btn>
                  <v-btn v-else text @click="getData">
                    <div class="text-gradient-half">Discard</div>
                  </v-btn>
                  <v-btn
                    text
                    @click="

                      updateCourses(course, langCourses[index]);
                    "
                  >
                    <div class="text-gradient-half">Save</div>
                  </v-btn>
                </v-card-actions>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
        <!-- <div class="ml-4 mr-4 mt-6">
          <label>Upload courses from Excel file </label>
          <br />
          <input
            type="file"
            accept=".xls,.xlsx"
            @change="importExcel"
            value="Import courses from Excel file"
          />
        </div> -->
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar_show" :color="snackbar_color" top  timeout="1500">
      <v-icon>{{ snackbar_icon }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>

</template>


<script>
import XLSX from "xlsx";
import ResourceEditor from "@/pages/app/common/ResourceEditor.vue";


export default {
  props: {
    back_user: {
      type: Boolean,
      def: false,
    }
  },
  components: {ResourceEditor},
  data: () => ({
    //

    // Selector items
    languages: [],
    partners: [],
    types: [],
    licenses: [],
    categories: [],
    relevances: [],
    topics: [],
    skills: [],
    competences: [],
    accesses: ["Free", "Paid"],
    // dateOfCreation: [],
    // All the courses displayed on the page
    courses: [],
    langCourses: [],
    // Images stuff
    formRules: [(v) => !!v || "Required field"],
    imageRules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Image size should be less than 2 MB!",
    ],
    imageData: {
      image: null,
      imageURL: null
    },
    // Skills
    skillsAutocompleteLoading: false, // Loading animation autocomplete
    skillsAutocompleteSearch: [], // Results of the skills search autocomplete
    skillAutocompleteSearch: null, // Autocomplete search field
    skillsSelectedAutocomplete: [], // Autocomplete skills selected
    // Competences
    competencesAutocompleteLoading: false, // Loading animation autocomplete
    competencesAutocompleteSearch: [], // Results of the competences search autocomplete
    competenceAutocompleteSearch: null, // Autocomplete search field
    competencesSelectedAutocomplete: [], // Autocomplete competences selected
    // Snackbar
    snackbar_show: false,
    snackbar_color: null,
    snackbar_text: null,
    snackbar_icon: null,
    // env
    VUE_APP_API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,
  }),
  computed: {

  },
  watch: {
    skillAutocompleteSearch(name) {
      name && name !== this.select && this.searchSkillsByName(name);
    },
    competenceAutocompleteSearch(name) {
      name && name !== this.select && this.searchCompetencesByName(name);
    },
  },
  async mounted() {
    // const currentYear = new Date().getFullYear();
    // for (let year = currentYear - 10; year <= currentYear + 2; year++) {
    //   this.dateOfCreation.push(year);
    // }

    try {
      await this.getData();
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        this.$router.push("/auth-error");
      }
    }
  },

  methods: {
    handleImageChange() {
      // Si ya existe una URL de imagen, revócala para liberar recursos
      if (this.imageData.imageURL) {
        URL.revokeObjectURL(this.imageData.imageURL);
      }

      // Asegurarte de que hay un archivo seleccionado
      if (this.imageData.image) {
        // Crear una nueva URL para el archivo de imagen seleccionado
        this.imageData.imageURL = URL.createObjectURL(this.imageData.image);
      } else {
        // Limpiar la URL si no hay archivo seleccionado
        this.imageData.imageURL = null;
      }
    },

    formatDateToYYYYMMDD(date) {
      const anio = date.getFullYear();
      const mes = String(date.getMonth() + 1).padStart(2, "0");
      const dia = String(date.getDate()).padStart(2, "0");
      return `${anio}-${mes}-${dia}`;
    },
    formatDate(date) {
      console.log("formatDate",date)
      if (!date) return null
      if(date.length > 10){
        const [year, month, day] = date.substring(0,10).split(/[/-]/)

        console.log("formatDate",`${day}/${month}/${year}`)
        return `${day}/${month}/${year}`
      }else{
        const [year, month, day] = date.split(/[/-]/)

        console.log("formatDate",`${day}/${month}/${year}`)
        return `${day}/${month}/${year}`
      }

    },
    async getData() {
      let url = this.back_user ? "backuser/publish-training-courses" : "training-provider/publish-training-courses";
      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT +url,
        {
          headers: {
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      if (response.success) {
        console.log("EL CURSO ES: " + JSON.stringify(response.result))
        this.partners = response.result.partners;
        this.topics = response.result.topics;
        this.types = response.result.types;
        this.languages = response.result.languages;
        this.licenses = response.result.licenses;
        this.categories = response.result.categories;
        this.relevances = response.result.relevances;
        this.skills = response.result.skills;
        this.competences = response.result.competences;
        this.courses = response.result.courses;
        this.langCourses.length = 0
        for (let i = 0; i<this.courses.length; i++){
          this.langCourses[i] = JSON.parse(this.courses[i].languages)
          //this.courses[i].languages = JSON.parse(this.courses[i].langugaes)
        }
        if(this.courses){
          this.courses.forEach(course => {
            course.init_date_modal = false
            course.end_date_modal = false
            course.created_at_modal = false;


            if(course.created_at){
              //console.log("test",this.formatDateToYYYYMMDD(new Date(course.init_date)));
              course.created_at = this.formatDateToYYYYMMDD(new Date(course.created_at))
              course.created_at = course.created_at.substring(0,10)
              course.date_dialog_created_at = course.created_at
              course.date_dialog_created_at_formatted = this.formatDate(course.created_at)
            }
            if(course.init_date){
              //console.log("test",this.formatDateToYYYYMMDD(new Date(course.init_date)));
              course.init_date = this.formatDateToYYYYMMDD(new Date(course.init_date))
              course.init_date = course.init_date.substring(0,10)
              course.date_dialog_data_start = course.init_date
              course.date_dialog_data_start_formatted = this.formatDate(course.init_date)
            }
            if(course.end_date){
              course.end_date = this.formatDateToYYYYMMDD(new Date(course.end_date))
              course.end_date =  course.end_date.substring(0,10)
              course.date_dialog_data_end = course.end_date
              course.date_dialog_data_end_formatted = this.formatDate(course.end_date)
            }

            course.publis_init_date_modal = false
            course.publis_end_date_modal = false

            if(course.publish_ini){
              //console.log("test",this.formatDateToYYYYMMDD(new Date(course.init_date)));
              course.publish_ini = this.formatDateToYYYYMMDD(new Date(course.publish_ini))
              course.publish_ini = course.publish_ini.substring(0,10)
              course.publish_date_dialog_data_start = course.publish_ini
              course.publish_date_dialog_data_start_formatted = this.formatDate(course.publish_ini)
            }

            if(course.publish_end){
              course.publish_end = this.formatDateToYYYYMMDD(new Date(course.publish_end))
              course.publish_end =  course.publish_end.substring(0,10)
              course.publish_date_dialog_data_end = course.publish_end
              course.publish_date_dialog_data_end_formatted = this.formatDate(course.publish_end)
            }
            if(course.manual_publish == 0)
              course.manual_publish = false
            if(course.expired == 0)
              course.expired = false

          })
        }
      } else {
        throw new Error(response.message);
      }
    },
    async searchSkillsByName(name) {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/search-skills-by-name",
          {
            method: "POST",
            body: JSON.stringify({ name: name }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
      } catch (error) {
        console.log(error.message);
      }
      this.skillsAutocompleteLoading = false;
      this.skillsAutocompleteSearch = response.result;
    },
    async searchCompetencesByName(name) {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT +
          "common/search-competences-by-name",
          {
            method: "POST",
            body: JSON.stringify({ name: name }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
      } catch (error) {
        console.log(error.message);
      }
      this.competencesAutocompleteLoading = false;
      this.competencesAutocompleteSearch = response.result;
    },
    async updateCourses(course, languages) {

      //console.log(course)
      course.language = languages
      console.log("EL CURSO ES: " + JSON.stringify(course))

      try {
        let url = this.back_user ? "backuser/update-courses" : "training-provider/update-courses";
        let response;
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + url,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
            body: JSON.stringify({
              course: course,
            }),
          }
        );
        response = await response.json();
        if (response.success) {

          console.log(JSON.stringify(response.result))
          console.log("courseId: "+response.result.courseId)
          if (this.imageData.image != null) {
            this.uploadCourseImage(response.result.courseId, course.image);
          }else{
            // Info message
            this.snackbar_color = "success";
            this.snackbar_text = "Courses updated";
            this.snackbar_icon = "mdi-check";
            this.snackbar_show = true;
            // Reload page data only if the response is successful
            await this.getData();
          }

        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = error.message;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },
    duplicate(course,index){

      console.log(JSON.stringify(course))
      course = JSON.parse(JSON.stringify(course))

      course.title = course.title+" (Duplicated)"
      delete course.course_id
      delete course.image

      console.log("course.duration",course.duration)

      this.courses.splice(index+1,0,course)
      this.langCourses.splice(index+1,0,JSON.parse(course.languages))
    },

    async deleteCourses(courseId) {
      try {
        console.log("deleteCourses",courseId)
        let url = this.back_user ? "backuser/delete-courses/" : "training-provider/delete-courses/";
        let response;
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT +
          url +
          courseId,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
        if (response.success) {
          // Info message
          this.snackbar_color = "success";
          this.snackbar_text = "Courses updated";
          this.snackbar_icon = "mdi-check";
          this.snackbar_show = true;
          // Reload page data only if the response is successful
          await this.getData();
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = error.message;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },
    async uploadCourseImage(course_id,old_image) {
      let response;
      try {

        let needUpdate = false;
        let formData = new FormData();
        if (this.imageData.image != null) {
          formData.append(
            "image",
            this.imageData.image,
            this.imageData.image.name
          );
          needUpdate = true;
        }else if(!old_image){
          needUpdate = true;
        }
        if(needUpdate) {
          formData.append("course_id", course_id);
          // Remove the file from the browser
          this.imageData.image = null;
          if(this.imageData.imageURL){
            URL.revokeObjectURL(this.imageData.imageURL)
            this.imageData.imageURL = null;
          }

          let url = this.back_user ? "backuser/upload-course-image" : "training-provider/upload-course-image";
          response = await fetch(
            process.env.VUE_APP_API_ENDPOINT +
            url,
            {
              method: "POST",
              body: formData,
              // Weirdly, the auth-token is not sent as an auth-token header
              // We need to sent it with the cookies header (credentials: include)
              headers: {
                "Auth-Token": this.$cookie.get("jwt_token"),
              },
            }
          );
          if (response.ok) {
            /*
            this.snackbar_color = "success";
            this.snackbar_text = "Image uploaded succesfully";
            this.snackbar_icon = "mdi-alert-circle";
            this.snackbar_show = true;

             */
            console.log("reload")
            await this.getData();
            // Info message
            this.snackbar_color = "success";
            this.snackbar_text = "Courses updated";
            this.snackbar_icon = "mdi-check";
            this.snackbar_show = true;
            // Reload page data only if the response is successful

          }
        }else{
          // no se ha modificado la imagen y existe una anterior
        }
      } catch (error) {
        // The browser returns a CORS error but the request succeeds
        // This is a quick fix to avoid displaying a error message to
        // the user even if the request is successful
        if (response.ok) return;
        this.snackbar_color = "error";
        this.snackbar_text = error;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },

    async importExcel(excel) {
      const files = excel.target.files;
      const fileReader = new FileReader();
      fileReader.onload = async (ev) => {
        const data = ev.target.result;
        const workbook = XLSX.read(data, {
          type: "binary",
        });

        const wsname = workbook.SheetNames[0]; // Take the first sheet
        const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]); // Get the data from this table

        // Fetch the excel file and insert each row in the courses array
        for (const excelRow of ws) {
          const newCourse = {
            title: excelRow["Title of the learning resource "],
            partner:
              excelRow[
                "Allview partner institution who added learning resource to database"
                ],
            type: excelRow["Type of learning resource (choose)"],
            courses_topic: excelRow["Topic (choose)"],
            // category:
            //   excelRow["Allview learning resource mapping category (choose)"],
            license_name: excelRow["License type (choose)"],
            // relevance: excelRow["Relevance/\r\nvalidity (choose)"],
            language: excelRow["Language of learning resource"],
            description:
              excelRow["Description of the content of the learning ressource"],
            contact_email:
              excelRow[
                "Contact EMail to learning resource."
                ],
            weblink:
              excelRow[
                "Weblink to learning resource.\r\nA permanent link to the exact webpage where the digital object is hosted (please make sure of the permanency, often it’s not valid to copy/paste the search bar. In most of the webs there is one already shown in the description) "
                ],
            eqf_nqf_level:
              excelRow[
                "EQF level of the learning ressource\r\n --> LINK to EQF definition (choose)"
                ],
            ecvet_points:
              excelRow[
                "ECVET points (or ECTS) to estimate the volume of the learning ressource (1point = ±30h of work)"
                ],
            learning_outcomes:
              excelRow[
                "Learning outcomes / objectives\r\n(possibility how to write them -->LINK)"
                ],
            media: excelRow["Media type (image, video, text, etc.) "],
            institution:
              excelRow[
                "Name of the institution or project that holds the learning resource"
                ],
            keywords:
              excelRow[
                "Keywords  \r\nup to 5 keywords to describe the learning resource"
                ],
            access: excelRow["Access (choose)"],
            archive_name:
              excelRow[
                "Name of archive, local file, folder (please assign a suitable name for storage and search)"
                ],
            created_at: excelRow["Date of creation\r\n(choose)"],
          };
          this.courses.push(newCourse);
        }
        // Save courses in the db
        for (const course of this.courses) {
          await this.updateCourses(course);
        }
      };
      // Read file, trigger onload function
      await fileReader.readAsBinaryString(files[0]);
    },
  },
};
</script>

<style>
.card-form {
  margin: 25px;
}

.white-icon {
  color: white !important;
}

h3:first-letter {
  text-transform: capitalize;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
}

.display-inline {
  display: inline-block;
}

.input {
  max-width: 300px;
  display: inline-block;
  margin: 20px;
  margin-bottom: 40px;
}

.background-gradient {
  color: white;
  box-shadow: 0 0 20px #eee;
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 200%);
}

.text-gradient-half {
  background: linear-gradient(to right, #047ec3 0%, #52ae32 150%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>

