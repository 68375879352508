<template>
  <div>
    <Toolbar/>
    <div class="center-in-page">
      <div class="wrapper">
        <v-card>
          <v-toolbar color="primary" dark>Recover your password</v-toolbar>
          <v-card-text class="pl-5 pr-5">
            <div class="text-h6 pt-5 pb-5">Please set your new password</div>
            <v-text-field
              v-model="password"
              label="New Password"
              type="password"
              required
            ></v-text-field>
            <v-text-field
              v-model="passwordCheck"
              label="Repeat Password"
              type="password"
              required
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="recoverPassword">Set New Password</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
    <Footer />

    <v-snackbar v-model="snackbar_show" :color="snackbar_color" top>
      <v-icon>{{ snackbar_icon }}</v-icon>
      &nbsp;&nbsp;
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import Toolbar from "@/components/carbon-ui/toolbar/Toolbar2.vue";
import Footer from "@/components/carbon-ui/footer/Footer4.vue";

export default {
  components: {
    Toolbar,
    Footer,
  },
  data: () => ({
    // Snackbar
    snackbar_show: false,
    snackbar_color: null,
    snackbar_text: null,
    snackbar_icon: null,
  }),
  methods: {
    async recoverPassword() {
      let response;
      try {
        // Get the token from the url parameter
        const token = this.$route.query.token;

        // Send the token to the backend to verify the user
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "home/recover-password/" + token,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              password: this.password,
              passwordCheck: this.passwordCheck
            }),
          }
        );
        response = await response.json();
        if (response.success) {
          // Login success message
          this.login_form_show = false;
          this.snackbar_color = "success";
          this.snackbar_text =
            "Success: new password saved. You can now login.";
          this.snackbar_icon = "mdi-check";
          this.snackbar_show = true;

          // Keep the user waiting to see the success message
          await new Promise((resolve) => setTimeout(resolve, 2000));

          // Redirect the user to the login page
          this.$router.push("/login");
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = error.message;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },
  },
};
</script>

<style scoped>
.center-in-page {
  margin-top: 200px;
  margin-bottom: 400px;
}
.wrapper {
  max-width: 1200px;
  margin: 0 auto;
}
</style>
