<template>
  <div class="wrapper">
    <div class="text-center">
      <p class="text-h4 mt-6 mb-4 primary-color">
        Courses
      </p>
    </div>
    <div>
      <v-row class="mb-0">
        <template>
          <div style="text-align: center; width: 100%">
            <v-card-title>
              <p class="text-h5 font-weight-black">All Courses</p>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="courses"
              :options.sync="options"
              :loading="loading"
              :items-per-page="30"
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50,-1]
              }"
              :search="search"
              class="elevation-1"
            >
              <template v-slot:item.actions = "{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:top>

                <v-btn class="button-gradient-half"
                    style="margin-top: 8px; margin-left: 20px; margin-bottom: 8px"
                    @click="downloadCSV">
                      Download CSV
                  </v-btn>

                  <v-dialog
                      v-model="dialog"
                      max-width="1000px"
                    >
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">Edit Course</span>
                        </v-card-title>
                        <v-card-text class="pt-4 pl-8">
                          <div class="image-container">
                            <v-img
                              v-model="imagePic"
                              class="display-inline uploaded-image"
                              width="400px"
                              height="300px"
                              :src="(editedItem.image || VUE_APP_API_ENDPOINT +
                                     'images/courses/' + 'default.jpg')"
                              @click="openImageFileDialog(editedIndex)"
                            >
                            </v-img>
                          </div>
                          <div>
                            <a v-if="editedItem.image" style="vertical-align: top; margin-left: 8px !important;"
                               v-on:click="removeImage(editedItem.id,editedIndex)"> X </a>

                            <v-file-input
                              ref = "img_ctrl"
                              :id = "'image-input-'+editedIndex"
                              :rules="imageRules"
                              accept="image/png, image/jpeg, image/bmp"
                              placeholder="Upload course photo (500x300px) **Optional"
                              v-model="imageData.image"
                              prepend-icon="mdi-camera"
                              class="input"
                              chips
                              show-size
                              @change="uploadCourseImage(editedItem.id,editedIndex,false)"
                            ></v-file-input>

                          </div>
                        <v-text-field
                          v-model="editedItem.title"
                          label="Title *"
                          :rules="formRules"
                        ></v-text-field>

                        <v-text-field
                          v-model="editedItem.description"
                          label="Description"
                        ></v-text-field>
                        <!--
                        <v-text-field
                          v-model="courses[index].duration"
                          label="Duration (Deprecated)"
                          placeholder="Type a duration like: One month or two weeks"
                        ></v-text-field>
                        -->
                        <v-container>
                          <v-row>

                            <v-col cols="12" md="6" lg="6">

                              <v-menu
                                v-model="editedItem.init_date_modal"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">

                                  <v-text-field
                                    v-model="editedItem.date_dialog_data_start_formatted"
                                    label="Start date"
                                    hint="DD/MM/YYYY format"
                                    persistent-hint
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>

                                </template>
                                <v-date-picker v-model="editedItem.date_dialog_data_start" no-title @input="editedItem.init_date_modal = false"
                                               @change="
                              editedItem.date_dialog_data_start_formatted = formatDate(editedItem.date_dialog_data_start);
                              editedItem.init_date = editedItem.date_dialog_data_start;

                            ">

                                </v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="12" md="6" lg="6">

                              <v-menu
                                v-model="editedItem.end_date_modal"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="editedItem.date_dialog_data_end_formatted"
                                    label="End date"
                                    hint="DD/MM/YYYY format"
                                    persistent-hint
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker v-model="editedItem.date_dialog_data_end" no-title @input="editedItem.end_date_modal = false"
                                               @change="

                              editedItem.date_dialog_data_end_formatted = formatDate(editedItem.date_dialog_data_end);
                              editedItem.end_date = editedItem.date_dialog_data_end;

                            "
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </v-container>

                        <v-combobox
                          v-model="editedItem.partner"
                          :items="items_partners"
                          placeholder="Select a training provider or type a new one"
                          label="Training Provider Name *"
                          :rules="formRules"
                        ></v-combobox>

                        <!-- <v-combobox
                          v-model="courses[index].category"
                          :items="categories"
                          placeholder="Select a category or type a new one"
                          label="Category *"
                        ></v-combobox> -->

                        <v-select
                          v-model="editedItem.topicsSelected"
                          :items="items_topics"
                          hide-no-data
                          hide-details
                          label="Topics"
                          placeholder="Select one or more topics"
                          multiple
                          flat
                        >
                        </v-select>
                        <br>

                        <!-- <v-text-field
                          v-model="courses[index].learning_outcomes"
                          label="Learning outcomes"
                        ></v-text-field> -->

                        <v-text-field
                          v-model="editedItem.keywords"
                          label="Comma-Separated Keywords *"
                        ></v-text-field>

                        <v-combobox
                          v-model="editedItem.skills"
                          :items="items_skills"
                          placeholder="Start typing"
                          label="Skills"

                          multiple
                          hide-no-data
                          hide-details
                          clearable
                          chips
                          deletable-chips
                        >
                        </v-combobox>
                          <v-banner>
                            <p class="caption text-decoration-none secondary--text" >
                              * This service uses the ESCO classification of the European Commission.
                            </p>
                          </v-banner>
                        <v-combobox
                          v-model="editedItem.competences"
                          :items="items_competences"
                          placeholder="Start typing"
                          label="Competences"

                          multiple
                          hide-no-data
                          hide-details
                          clearable
                          chips
                          deletable-chips
                        >
                        </v-combobox>
                          <v-banner>
                            <p class="caption text-decoration-none secondary--text" >
                              * This service uses the ESCO classification of the European Commission.
                            </p>
                          </v-banner>
                        <v-combobox
                          v-model="editedItem.type"
                          :items="item_types"
                          placeholder="Select a course type or create a new one"
                          label="Course Type"
                        ></v-combobox>

                        <v-select
                          v-model="editedItem.access"
                          :items="items_accesses"
                          placeholder="Select an access type"
                          label="Access Type"
                        ></v-select>

                        <v-select
                          v-model="editedItem.license"
                          :items="items_licenses"
                          hide-no-data
                          hide-details
                          label="License *"
                          placeholder="Select a license"
                          flat
                        />

                        <!-- <v-text-field
                          v-model="courses[index].institution"
                          label="Name of the institution"
                        ></v-text-field> -->

                        <v-text-field
                          v-model="editedItem.contact_mail"
                          label="Contact E-Mail"
                        ></v-text-field>

                        <v-text-field
                          v-model="editedItem.weblink"
                          label="Weblink"
                        ></v-text-field>

                        <v-row>
                            <v-col cols="12" md="6" lg="6">
                              <v-select
                                v-model="langCourses[editedItem.id]"
                                :items="items_languages"
                                hide-no-data
                                hide-details
                                label="Language *"
                                placeholder="Select a language"
                                multiple
                                flat
                              />
                            </v-col>

<!--                        <v-select-->
<!--                          v-model="editedItem.created_at"-->
<!--                          :items="items_date_of_creation"-->
<!--                          label="Date of creation"-->
<!--                        ></v-select>-->

                            <v-col cols="12" md="6" lg="6">

                              <v-menu
                                v-model="editedItem.created_at_modal"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">

                                  <v-text-field
                                    v-model="editedItem.date_dialog_created_at_formatted"
                                    label="Date of creation"
                                    hint="DD/MM/YYYY format"
                                    persistent-hint
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    clearable
                                    @click:clear="editedItem.created_at = null"
                                  ></v-text-field>

                                </template>
                                <v-date-picker v-model="editedItem.date_dialog_created_at" no-title @input="editedItem.created_at_modal = false"
                                               @change="
                                editedItem.date_dialog_created_at_formatted = formatDate(editedItem.date_dialog_created_at);
                                editedItem.created_at = editedItem.date_dialog_created_at;
                              ">

                                </v-date-picker>
                              </v-menu>
                            </v-col>
                         </v-row>
                        <!-- <v-combobox
                        :items="dateOfCreation"
                          v-model="courses[index].relevance"
                          :items="relevances"
                          placeholder="Select a course relevance or type a new one"
                          label="Course Relevance"
                        ></v-combobox> -->

                        <v-text-field
                          v-model="editedItem.eqf_nqf_level"
                          label="EQF NQF level"
                        ></v-text-field>

                        <v-text-field
                          v-model="editedItem.ecvet_points"
                          label="ECVET/ECTS points"
                        ></v-text-field>

                        <v-text-field
                          v-model="editedItem.archive_name"
                          label="Link to online training platform (if applicable)"
                        ></v-text-field>

                        <v-text-field
                          v-model="editedItem.media"
                          label="Type of training material (e.g. pdf, videos, lab work, etc.)"
                        ></v-text-field>

                          <v-container>
                            <v-row>

                              <v-col cols="12" md="6" lg="6">

                                <v-menu
                                  v-model="editedItem.init_date_modal"
                                  :close-on-content-click="true"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">

                                    <v-text-field
                                      v-model="editedItem.date_dialog_data_start_formatted"
                                      label="Start date of course position"
                                      hint="DD/MM/YYYY format"
                                      persistent-hint
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      clearable
                                      @click:clear="editedItem.init_date = null"
                                    ></v-text-field>

                                  </template>
                                  <v-date-picker v-model="editedItem.date_dialog_data_start" no-title @input="editedItem.init_date_modal = false"
                                                 @change="
                              editedItem.date_dialog_data_start_formatted = formatDate(editedItem.date_dialog_data_start);
                              editedItem.init_date = editedItem.date_dialog_data_start;
                            ">

                                  </v-date-picker>
                                </v-menu>
                              </v-col>
                              <v-col cols="12" md="6"  lg="6">
                                <v-menu
                                  v-model="editedItem.end_date_modal"
                                  :close-on-content-click="true"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="editedItem.date_dialog_data_end_formatted"
                                      label="End date of course position"
                                      hint="DD/MM/YYYY format"
                                      persistent-hint
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      clearable
                                      @click:clear="editedItem.end_date = null"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker v-model="editedItem.date_dialog_data_end" no-title @input="editedItem.end_date_modal = false"
                                                 @change="
                                                      editedItem.date_dialog_data_end_formatted = formatDate(editedItem.date_dialog_data_end);
                                                      editedItem.end_date = editedItem.date_dialog_data_end;

                                                    "
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>
                          </v-container>

                          <v-card class="mt-3 mb-3" >

                            <v-container>
                              <v-row>
                                <v-checkbox
                                  class="ms-2"
                                  v-model="editedItem.expired"
                                  label="Expire course "
                                ></v-checkbox>

                                <v-checkbox
                                  v-if="!editedItem.expired"
                                  class="ms-2"
                                  v-model="editedItem.manual_publish"
                                  label="Publish offer (disable this option if you want to schedule the course publication)"
                                ></v-checkbox>

                              </v-row>
                              <!--
                              <v-row v-if="courses[index].manual_publish">
                                <p class="caption text-decoration-none secondary--text ms-2" >
                                  * You have to disable this option for been able to schedule the job publication
                                </p>
                              </v-row>
                              -->
                              <v-row v-if="!editedItem.manual_publish && !editedItem.expired">
                                <v-col cols="12" md="6" lg="6">
                                  <v-menu
                                    v-model="editedItem.publish_init_date_modal"
                                    :close-on-content-click="true"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                  >
                                    <template v-slot:activator="{ on, attrs }">

                                      <v-text-field
                                        v-model="editedItem.publish_date_dialog_data_start_formatted"
                                        label="Publish after date"
                                        hint="DD/MM/YYYY format"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :disabled="editedItem.manual_publish"
                                        clearable
                                        @click:clear="editedItem.publish_ini = null"
                                      ></v-text-field>

                                    </template>
                                    <v-date-picker v-model="editedItem.publish_date_dialog_data_start" no-title @input="editedItem.publish_init_date_modal = false"
                                                   @change="
                              editedItem.publish_date_dialog_data_start_formatted = formatDate(editedItem.publish_date_dialog_data_start);
                              editedItem.publish_ini = editedItem.publish_date_dialog_data_start;
                            ">

                                    </v-date-picker>
                                  </v-menu>
                                </v-col>
                                <v-col cols="12" md="6"  lg="6">
                                  <v-menu
                                    v-model="editedItem.publish_end_date_modal"
                                    :close-on-content-click="true"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                  >
                                    <template v-slot:activator="{ on, attrs }">

                                      <v-text-field
                                        v-model="editedItem.publish_date_dialog_data_end_formatted"
                                        label="Publish end date"
                                        hint="DD/MM/YYYY format"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :disabled="editedItem.manual_publish"
                                        clearable
                                        @click:clear="editedItem.publish_end = null"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="editedItem.publish_date_dialog_data_end" no-title @input="editedItem.publish_end_date_modal = false"
                                                   @change="

                              editedItem.publish_date_dialog_data_end_formatted = formatDate(editedItem.publish_date_dialog_data_end);
                              editedItem.publish_end = editedItem.publish_date_dialog_data_end;

                            "
                                    ></v-date-picker>
                                  </v-menu>
                                </v-col>
                              </v-row>
                              <v-row>
                                <p class="caption text-decoration-none secondary--text ms-2 me-2" v-if="!editedItem.manual_publish && !editedItem.expired">
                                  * You can schedule the publication of the offer by setting the start and end publication dates. If neither date is set and the "Publish Offer" option is not enabled, the offer will never be published.
                                </p>
                              </v-row>
                            </v-container>
                          </v-card>

                        <v-checkbox
                          v-model="editedItem.receive_candidates_recommendations"
                          label="Receive recommendations about candidates"
                        ></v-checkbox>

                        <p class="caption text-decoration-none secondary--text">
                          * Required field
                        </p>
                        </v-card-text>




                        <resource-editor :entity-id="editedItem.id" entity-table="courses" mode="EDITOR" ></resource-editor>


                        <v-col cols="12" sm="12" md="12">
                          <h2 class="text-center">Course Ratings</h2>
                          <br />
                          <v-data-table
                            :headers="rateCourses"
                            :items="editedItem.ratings"
                            :options.sync="options"
                            :loading="loading"
                            :items-per-page="30"
                            :footer-props="{
                                'items-per-page-options': [10, 20, 30, 40, 50,-1]
                              }"
                            :search="editedItem.rating_search"
                            class="elevation-1"
                          >
                            <template v-slot:item.stat_icon = "{ item }">
                              <div class="text-center">


                              <v-icon
                                v-if="item.status_id === 1"
                                small
                                style="color: #f1d77e"
                                title="Pending"
                              >
                                mdi-clock
                              </v-icon>
                              <v-icon
                                v-else-if="item.status_id === 2"
                                small
                                style="color: #36d836"
                                title="Accepted"
                              >
                                mdi-check-circle

                              </v-icon>
                              <v-icon
                                v-else-if="item.status_id === 3"
                                small
                                style="color: #ff4646"
                                title="Rejected"
                              >
                                mdi-times-circle
                              </v-icon>
                              </div>
                            </template>
                            <template v-slot:item.actions = "{ item }">
<!--                              <v-icon-->
<!--                                small-->
<!--                                class="mr-2"-->
<!--                                @click="editRateItem(editedItem.id,item)"-->
<!--                              >-->
<!--                                mdi-pencil-->
<!--                              </v-icon>-->
                              <v-icon
                                small
                                @click="deleteRateItemDialog(editedItem.id,item)"
                              >
                                mdi-delete
                              </v-icon>

                              <v-dialog v-model="dialogDeleteRating" max-width="500px">
                                <v-card>
                                  <v-card-title class="text-h5">Are you sure you want to delete this rating?</v-card-title>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="dialogDeleteRating = null">Cancel</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteRateItem">OK</v-btn>
                                    <v-spacer></v-spacer>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </template>

                          </v-data-table>
                        </v-col>

                        <v-divider class="mt-12"></v-divider>
                        <v-card-actions>
                          <!--
                          <v-spacer></v-spacer>
                          <v-btn v-if="editedItem.course_id" text @click="duplicate(editedItem)">
                            <div class="text-gradient-half">Duplicate</div>
                          </v-btn>
                          <v-btn v-if="editedItem.course_id" text @click="deleteCourses(editedItem.course_id)">
                            <div class="text-gradient-half">Delete</div>
                          </v-btn>
                          <v-btn v-else text @click="getData">
                            <div class="text-gradient-half">Discard</div>
                          </v-btn>
                          <v-btn
                            text
                            @click="
                              updateCourses(editedItem, langeditedItem);
                            "
                          >
                            <div class="text-gradient-half">Save</div>
                          </v-btn>
                          -->
                        </v-card-actions>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="dialog = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="save()"
                          >
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="dialogDelete = false">Cancel</v-btn>
                          <v-btn color="blue darken-1" text @click="deleteItemSelected">OK</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
              </template>

            </v-data-table>
            <v-snackbar
              v-model="snack"
              :timeout="1500"
              :color="snackColor"
            >
              {{ snackText }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  v-bind="attrs"
                  text
                  @click="snack = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
          </div>
        </template>

      </v-row>
      <br /><br />
      <br /><br />
    </div>
  </div>
</template>

<script>
import ResourceEditor from "@/pages/app/common/ResourceEditor.vue";

export default {
  name: "Home",
  components: {ResourceEditor},

  data: () => ({
    formRules: [(v) => !!v || "Required field"],
    imageRules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Image size should be less than 2 MB!",
    ],
    imageData: {
      image: null,
    },
    imagePic: [],
    topRatedCourses: [],
    recommendedCourses: [],
    newJobs: [],
    recommendedJobs: [],
    totalCourses: 0,
    courses: [],
    loading: true,
    options: {},
    dialogDelete: false,
    dialog: false,
    editedItem: {
      id: null,
      title: null,
      learning_outcomes: null,
      keywords: null,
      institution: null,
      duration: null,
      weblink: null,
      archive_name: null,
      languages: [],
      //license: {},
      category: {},
      //partner: {},
      receive_recommendations: false,
      image: null,
      /////
      contact_mail: null,
      type:null,
      competences:[],
      skills:[],
      created_at : null,
      topicsSelected:[],
      license: null,
      date_dialog_created_at: null,
      date_dialog_created_at_formatted: null,
      date_dialog_data_start: null,
      date_dialog_data_start_formatted: null,
      date_dialog_data_end: null,
      date_dialog_data_end_formatted: null,
      access: null,
      partner: null,
      eqf_nqf_level: null,
      ecvet_points: null,
      media: null,

      ///
    },
    snack: false,
    snackColor: '',
    snackText: '',
    arr:[],
   // text_area: "",
    editedIndex: -1,
    defaultItem: {},
    langCourses: [],
    items_languages: [],
    items_licenses:[],
    items_categories: [],
    items_partners: [],
    items_competences: [],
    items_skills: [],
    items_topics: [],
    items_relevances:[],
    item_types: [],
    items_accesses: ["Free", "Paid"],
    // items_date_of_creation:[],
    // this.headers = ["id", "Name", "E-Mail", "Phone", "State", "Verified"],
    headers: [
    {text: 'id', value: 'course_id'},
    {text: 'Title', value: 'title'},
    // {text: 'Description', value: 'description_dhort'},
    {text: 'Institution', value: 'institution'},
    {text: 'Keywords', value: 'keywords'},
    {text: 'Created', value: 'date_dialog_created_at_formatted', width: "8%"},
      {text: 'Status', value: 'status'},
   //{text: 'Link', value: 'weblink'},
    {text: 'Actions', value: 'actions', width: "7%"}
    ],
    rateCourses: [
      { text: "id", value: "id" },
      { text: "Rating", value: "rating" },
      { text: "Justification", value: "reason" },
      { text: "Comment", value: "comment" },
      { text: "User", value: "full_name" },
      { text: "Status", value: "stat_icon",width: "4%"},
      {text: 'Actions', value: 'actions', width: "7%"}
    ],

    search: '',
    rating_search: '',
    dialogDeleteRatingItem:null,
    dialogDeleteRating: false,
    // env
    VUE_APP_API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,
  }),
  async mounted() {
    // const currentYear = new Date().getFullYear();
    // for (let year = currentYear - 10; year <= currentYear + 2; year++) {
    //   this.items_date_of_creation.push(year);
    // }

    try {
      await this.getData();
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        this.$router.push("/auth-error");
      }
    }
  },
  watch: {
      dialogDeleteRating (val) {
        console.log("EL VALOR ES " + val)
        val || this.closeDeleteRateDialog()
      },
      dialogDelete (val) {
        console.log("EL VALOR ES " + val)
        val || this.closeDelete()
      },
      dialog (val) {
        console.log("EL VALOR ES " + val)
        val || this.closeDialog()
      },
    },
  methods: {
    // async uploadCourseImage(course_id) {
    //   let response;
    //   //console.log("EL NOMBRE DE LA FOTO ES: " + this.imageData.image.name)
    //   try {
    //     //console.log("LAS IMAGENES SON: " + this.imageData.image.name)
    //     if (this.imageData.image.name != null || this.imageData.image.name ) {
    //       console.log("Entro aquí")
    //       let formData = new FormData();
    //       formData.append(
    //         "image",
    //         this.imageData.image,
    //         this.imageData.image.name
    //       );
    //       formData.append("course_id", course_id);
    //       // Remove the file from the browser
    //       this.imageData.image = null;
    //       response = await fetch(
    //         process.env.VUE_APP_API_ENDPOINT + "/training-provider/upload-course-image",
    //         {
    //           method: "POST",
    //           body: formData,
    //           // Weirdly, the auth-token is not sent as an auth-token header
    //           // We need to sent it with the cookies header (credentials: include)
    //           headers: {
    //             "Auth-Token": this.$cookie.get("jwt_token"),
    //           },
    //         }
    //       );
    //       console.log(response)
    //       if (response.success) {
    //         this.snackbar_color = "success";
    //         this.snackbar_text = "Image uploaded succesfully";
    //         this.snackbar_icon = "mdi-alert-circle";
    //         this.snackbar_show = true;
    //
    //
    //       }
    //
    //     }
    //   } catch (error) {
    //     // The browser returns a CORS error but the request succeeds
    //     // This is a quick fix to avoid displaying a error message to
    //     // the user even if the request is successful
    //     if (response.success) return;
    //     this.snackbar_color = "error";
    //     this.snackbar_text = error;
    //     this.snackbar_icon = "mdi-alert-circle";
    //     this.snackbar_show = true;
    //   }
    //   this.getData()
    // },
    formatDateToYYYYMMDD(date) {
      const anio = date.getFullYear();
      // console.log(anio)
      const mes = String(date.getMonth() + 1).padStart(2, "0");
      // console.log(mes)
      const dia = String(date.getDate()).padStart(2, "0");
      // console.log(dia)

      return `${anio}-${mes}-${dia}`;
    },
    formatDate(date) {
      // console.log("formatDate",date)
      if (!date) return null
      if(date.length > 10){
        const [year, month, day] = date.substring(0,10).split(/[/-]/)

        // console.log("formatDate",`${day}/${month}/${year}`)
        return `${day}/${month}/${year}`
      }else{
        const [year, month, day] = date.split(/[/-]/)

        // console.log("formatDate",`${day}/${month}/${year}`)
        return `${day}/${month}/${year}`
      }

    },
    async getData() {
      this.loading = true;

      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "backoffice/courses",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        })
      response = await response.json();
      if (response.success) {
        //AQUÍ TENEMOS QUE RELLENAR LA TABLA
       console.log("reponse OK")
        this.items_categories = response.result.categories;
        this.items_competences = response.result.competences;
        this.items_languages = response.result.languages;
        this.items_partners = response.result.partners;
        this.items_topics = response.result.topics;
        this.item_types = response.result.types;
        this.items_licenses = response.result.licenses;

        this.items_relevances = response.result.relevances;
        this.items_skills = response.result.skills;




        this.arr = response.result.courses

        this.langCourses.length = 0
        // for (let i = 0; i<this.arr.length; i++){
        //   this.langCourses[i] = JSON.parse(this.courses[i].languages)
        //   //this.courses[i].languages = JSON.parse(this.courses[i].langugaes)
        // }

        this.totalCourses = this.arr.length
        console.log("totalCourses ",this.totalCourses)
        var arrCourses = []
        const now = new Date(); // Fecha y hora actuales
        this.arr.forEach((course) => {
          // console.log(course.title)
          this.langCourses[course.course_id] = JSON.parse(course.languages)

          course.skills = [...new Set(course.skills)];

          course.status = "NO STATUS";
          if(course.expired){
            course.status = "MANUAL EXPIRED";
          }else if(course.manual_publish === 1 || course.manual_publish === true){
            course.status = "MANUAL PUBLISHED";
          }else{
            let publishIniPassed = course.publish_ini === null || now > new Date(course.publish_ini);
            let publishEndNotPassed = course.publish_end === null || now < new Date(course.publish_end);
            if(publishIniPassed && publishEndNotPassed){
              course.status = "PUBLISHED";
            }else if(!publishIniPassed){
              course.status = "NOT JET";
            }else{
              course.status = "EXPIRED";
            }
          }

          course.description_short = course.description && course.description.length > 150 ?course.description.substring(0,150)+"...":course.description

          course.rating_search = '';
          course.created_at_modal = false;
          course.init_date_modal = false
          course.end_date_modal = false

          if(course.created_at){
            //console.log("test",this.formatDateToYYYYMMDD(new Date(course.init_date)));
            course.created_at = this.formatDateToYYYYMMDD(new Date(course.created_at))
            course.created_at = course.created_at.substring(0,10)
            course.date_dialog_created_at = course.created_at
            course.date_dialog_created_at_formatted = this.formatDate(course.created_at)
          }

          if(course.init_date){
            //console.log("test",this.formatDateToYYYYMMDD(new Date(course.init_date)));
            course.init_date = this.formatDateToYYYYMMDD(new Date(course.init_date))
            course.init_date = course.init_date.substring(0,10)
            course.date_dialog_data_start = course.init_date
            course.date_dialog_data_start_formatted = this.formatDate(course.init_date)
          }
          if(course.end_date){
            course.end_date = this.formatDateToYYYYMMDD(new Date(course.end_date))
            course.end_date =  course.end_date.substring(0,10)
            course.date_dialog_data_end = course.end_date
            course.date_dialog_data_end_formatted = this.formatDate(course.end_date)
          }

          course.publis_init_date_modal = false
          course.publis_end_date_modal = false

          if(course.publish_ini){
            //console.log("test",this.formatDateToYYYYMMDD(new Date(course.init_date)));
            course.publish_ini = this.formatDateToYYYYMMDD(new Date(course.publish_ini))
            course.publish_ini = course.publish_ini.substring(0,10)
            course.publish_date_dialog_data_start = course.publish_ini
            course.publish_date_dialog_data_start_formatted = this.formatDate(course.publish_ini)
          }

          if(course.publish_end){
            course.publish_end = this.formatDateToYYYYMMDD(new Date(course.publish_end))
            course.publish_end =  course.publish_end.substring(0,10)
            course.publish_date_dialog_data_end = course.publish_end
            course.publish_date_dialog_data_end_formatted = this.formatDate(course.publish_end)
          }

          if(course.manual_publish == 0){
            course.manual_publish = false
          }else if(course.manual_publish == 1){
            course.manual_publish = true
          }

          this.getUserPic(course)
          if(course.expired == 0){
            course.expired = false
          }else if(course.expired == 1){
            course.expired = true
          }
          arrCourses.push(course)
        })
        console.log("end ")
        console.log(arrCourses.length)
        this.courses = arrCourses
        this.$emit('missingProfile', response.result.missingProfile)
        this.loading = false
      } else {
        throw new Error(response.message);
      }
    },

    async getUserPic(course){
      // let response = await fetch(
      //   process.env.VUE_APP_API_ENDPOINT + "company/user-profile/",
      //   {
      //     headers: {
      //       "Auth-Token": this.$cookie.get("jwt_token"),
      //     },
      //   }
      // );
      // response = await response.json();
      // // console.log(response.result.personalInformation)
      // // console.log(job.image  + "------" + response.result.personalInformation.image )
      // if (response.success){
      //   if (!job.image || job.image == response.result.personalInformation.image ){
      //     if(response.result.personalInformation.image) {
      //       var userPic = process.env.VUE_APP_API_ENDPOINT + "images/profiles/" + response.result.personalInformation.image
      //       job.image = userPic
      //       console.log("LA IMAGEN recibida GETPIC ES: " + job.image)
      //     }
      //   }else{
      //     job.image =  process.env.VUE_APP_API_ENDPOINT + "images/jobs/" + job.image
      //     //this.imagePic.src = process.env.VUE_APP_API_ENDPOINT + "images/jobs/" + job.image
      //   }
      // }
      if(course.image)
        course.image =  process.env.VUE_APP_API_ENDPOINT + "images/courses/" + course.image
    },
    closeDialog() {
      this.dialog = false
      //this.editedIndex = this.courses.indexOf(item)
      //this.editedItem = Object.assign({}, item)
      // this.editedItem = item
    },
    close(){
      this.closeDialog()
    },
    deleteItem(item){
      console.log(item)
      this.dialogDelete = true
      this.editedIndex = this.courses.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem = item

    },


    editRateItem(id_course,item){
      console.log("editRateItem",id_course,item)
      // this.dialogDelete = true
      // this.editedIndex = this.courses.indexOf(item)
      // this.editedItem = Object.assign({}, item)
      // this.editedItem = item

    },

    closeDeleteRateDialog() {
      this.dialogDeleteRating = false
      //this.editedIndex = this.courses.indexOf(item)
      //this.editedItem = Object.assign({}, item)
      // this.editedItem = item
    },
    deleteRateItemDialog(id_course,item){
      console.log("editRateItem",id_course,item)
      this.dialogDeleteRatingItem = item;
      this.dialogDeleteRating = true;
    },
    async deleteRateItem() {
      console.log("deleteRateItem", this.dialogDeleteRatingItem)

      await fetch(
        process.env.VUE_APP_API_ENDPOINT + "backoffice/remove-course-rating",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
          body: JSON.stringify({
            rating_id: this.dialogDeleteRatingItem.id
          })
        });

      this.snack = true
      this.snackColor = 'success'
      this.snackText = 'Rating deleted'

      console.log("this.editedItem",this.editedItem,this.editedItem.ratings)
      let index = this.editedItem.ratings.findIndex(rt => rt.id === this.dialogDeleteRatingItem.id)
      if(index > -1)
        this.editedItem.ratings.splice(index, 1);
      // this.courses.splice(0, this.courses.length);
      // await this.getData()
      // this.closeDelete()
      this.dialogDeleteRatingItem = null;
      this.dialogDeleteRating = false;
    },
    async deleteItemSelected(){
      console.log(JSON.stringify(this.editedItem))
      //this.courses = this.courses.filter( o => o.course_id != this.editedItem.id)
      await fetch(
        process.env.VUE_APP_API_ENDPOINT + "backoffice/delete-courses",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
          body: JSON.stringify({
              course_to_delete: this.editedItem.course_id,
              user_id: this.$cookie.get("jwt_token")
          })
        });

      //this.editedIndex = this.courses.indexOf(objToSave)
      //console.log("EL OBJETO EN EL INDEX ES: "  + JSON.stringify(this.users[this.editedIndex]))
      //this.editedItem = Object.assign(objToSave, this.users[this.editedIndex])
      //this.courses = this.courses.filter(obj => obj.id != this.editedItem.id)



      this.snack = true
      this.snackColor = 'success'
      this.snackText = 'Course deleted'
      let index = this.courses.findIndex(o => o.course_id === this.editedItem.course_id)
      if(index > -1)
        this.courses.splice(index, 1);
      // this.courses.splice(0, this.courses.length);
      // await this.getData()
      this.closeDelete()

    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },


     editItem(item){
      // console.log(item)
      // console.log(this.arr)
      var itemAux = this.arr.filter(o => o.course_id == item.course_id)
      console.log("EL OBJETO ES: " + JSON.stringify(itemAux[0]))
      this.editedItem.id = itemAux[0].course_id
      this.editedItem.title = itemAux[0].title
      this.editedItem.learning_outcomes = itemAux[0].learning_outcomes
      this.editedItem.keywords = itemAux[0].keywords
      this.editedItem.institution =itemAux[0].institution
      this.editedItem.duration = itemAux[0].duration
      this.editedItem.weblink = itemAux[0].weblink
      this.editedItem.archive_name = itemAux[0].archive_name
      this.editedItem.image = itemAux[0].image
      this.editedItem.description = itemAux[0].description
       this.editedItem.init_date = itemAux[0].init_date
       this.editedItem.end_date = itemAux[0].end_date

       // added 29/05/24 bug report
       this.editedItem.contact_mail = itemAux[0].contact_mail
       this.editedItem.type = itemAux[0].type
       this.editedItem.competences = itemAux[0].competences
       this.editedItem.skills = itemAux[0].skills
       this.editedItem.topicsSelected = itemAux[0].topicsSelected
       this.editedItem.license = itemAux[0].license



       this.editedItem.date_dialog_data_start = itemAux[0].date_dialog_data_start
       this.editedItem.date_dialog_data_start_formatted = itemAux[0].date_dialog_data_start_formatted

       this.editedItem.date_dialog_data_end = itemAux[0].date_dialog_data_end
       this.editedItem.date_dialog_data_end_formatted = itemAux[0].date_dialog_data_end_formatted
       this.editedItem.access = itemAux[0].access
       this.editedItem.partner = itemAux[0].partner
       this.editedItem.eqf_nqf_level = itemAux[0].eqf_nqf_level
       this.editedItem.ecvet_points = itemAux[0].ecvet_points
       this.editedItem.media = itemAux[0].media

       this.editedItem.category = itemAux[0].category

       this.editedItem.created_at = itemAux[0].created_at
       this.editedItem.date_dialog_created_at = itemAux[0].date_dialog_created_at
       this.editedItem.date_dialog_created_at_formatted = itemAux[0].date_dialog_created_at_formatted


       this.editedItem.created_at_date_modal = itemAux[0].created_at_date_modal;
       this.editedItem.publis_init_date_modal = itemAux[0].publis_init_date_modal;
       this.editedItem.publis_end_date_modal = itemAux[0].publis_end_date_modal;

       this.editedItem.publish_ini = itemAux[0].publish_ini;
       this.editedItem.publish_date_dialog_data_start = itemAux[0].publish_date_dialog_data_start;
       this.editedItem.publish_date_dialog_data_start_formatted = itemAux[0].publish_date_dialog_data_start_formatted;

       this.editedItem.publish_end = itemAux[0].publish_end;
       this.editedItem.publish_date_dialog_data_end = itemAux[0].publish_date_dialog_data_end;
       this.editedItem.publish_date_dialog_data_end_formatted = itemAux[0].publish_date_dialog_data_end_formatted;


       this.$set(this.editedItem, 'manual_publish', itemAux[0].manual_publish);
       this.$set(this.editedItem, 'expired', itemAux[0].expired);
       /////

       this.editedItem.duration = itemAux[0].duration
       this.editedItem.cost = itemAux[0].cost
       this.editedItem.partner_id = itemAux[0].partner_id

       this.editedItem.rating_search = '';
       this.editedItem.ratings =  itemAux[0].ratings;

       this.editedItem.languages = JSON.parse(itemAux[0].languages)

      //var lang = this.items_languages.filter(o=>o.id == itemAux[0].language_id)
      //this.editedItem.language = lang[0]

      // var lic = this.items_licenses.filter(o=>o.id == itemAux[0].license_id)
      // this.editedItem.license = lic[0]

      // var cat = this.items_categories.filter(o=>o.id == itemAux[0].category_id)
      // this.editedItem.category = cat[0]

      // var par = this.items_partners.filter(o=>o.id == itemAux[0].partner_id)
      // this.editedItem.partner = par[0]
      // this.editedItem.partner_id = itemAux[0].partner_id


      // this.text_area = itemAux[0].description

      if (itemAux[0].receive_candidates_recommendations == 1){
        this.editedItem.receive_recommendations = true
      }else{
        this.editedItem.receive_recommendations = false
      }

      this.editedIndex = this.courses.indexOf(item)


      this.dialog = true
     },


     async save(){

       let current = this.arr.filter(o => o.course_id == this.editedItem.id)
       current = current[0]
       //this.editedItem.id = itemAux[0].course_id
       current.title = this.editedItem.title
       current.learning_outcomes = this.editedItem.learning_outcomes
       current.keywords = this.editedItem.keywords
       current.institution = this.editedItem.institution
       current.duration = this.editedItem.duration
       current.weblink = this.editedItem.weblink
       current.archive_name = this.editedItem.archive_name
       current.image = this.editedItem.image;
       current.language = this.langCourses[this.editedItem.id]

       //var lang = this.items_languages.filter(o=>o.id == itemAux[0].language_id)
       //this.editedItem.language = lang[0]

       //var lic = this.items_licenses.filter(o=>o.id == itemAux[0].license_id)
       current.license = this.editedItem.license

       //var cat = this.items_categories.filter(o=>o.id == itemAux[0].category_id)
       current.category = this.editedItem.category

       //var par = this.items_partners.filter(o=>o.id == itemAux[0].partner_id)
       current.partner = this.editedItem.partner

       current.description =this.editedItem.description

       current.receive_recommendations = this.editedItem.receive_recommendations

       current.updated_at = new Date();

       current.contact_mail = this.editedItem.contact_mail;
       current.type = this.editedItem.type;
       current.competences = this.editedItem.competences;
       current.skills = this.editedItem.skills;
       current.created_at = this.editedItem.created_at;
       current.topicsSelected = this.editedItem.topicsSelected;

       current.init_date = this.editedItem.init_date;
       // current.date_dialog_data_start = this.editedItem.date_dialog_data_start;
       // current.date_dialog_data_start_formatted = this.editedItem.date_dialog_data_start_formatted;
       current.end_date = this.editedItem.end_date;
       // current.date_dialog_data_end = this.editedItem.date_dialog_data_end;
       // current.date_dialog_data_end_formatted = this.editedItem.date_dialog_data_end_formatted;
       current.access = this.editedItem.access;
       current.eqf_nqf_level = this.editedItem.eqf_nqf_level;
       current.ecvet_points = this.editedItem.ecvet_points;
       current.media = this.editedItem.media;

       current.publish_ini = this.editedItem.publish_ini;
       current.publish_end = this.editedItem.publish_end;
       current.manual_publish = this.editedItem.manual_publish;
       current.expired = this.editedItem.expired;

       current.rating_search = '';

       console.log("SAVE: "+JSON.stringify(current));

         await fetch(
           process.env.VUE_APP_API_ENDPOINT + "training-provider/update-courses",
           {
             method: "PUT",
             headers: {
               "Content-Type": "application/json",
               "Auth-Token": this.$cookie.get("jwt_token"),
             },
             body: JSON.stringify({
                 course: current,
                 user_id: this.$cookie.get("jwt_token")
             })
           });

       let courseEntry = this.courses[this.editedIndex];
       courseEntry.title = this.editedItem.title
       courseEntry.institution = this.editedItem.institution
       //courseEntry.description = this.text_area
       courseEntry.description = this.editedItem.description
       courseEntry.keywords = this.editedItem.keywords

         console.log("COURSE TO TAB: " + JSON.stringify(courseEntry))
         console.log("El item es: " + this.editedIndex)
         //this.courses = Object.assign(courseToTab, this.courses[this.editedIndex])
         //this.closeDelete()
         this.snack = true
         this.snackColor = 'success'
         this.snackText = 'Course Updated'
         this.close();

     },
     async downloadCSV(){
      import(/* webpackChunkName: "js2excel" */ 'js2excel').then(({json2excel}) => {
        json2excel({
            data: this.arr,
            name: 'Courses',
            formateDate: 'dd/mm/yyyy'
        });
      }).catch((e) => {
          console.log(e.toString())
      });
    },
    openImageFileDialog(index){
      if(document.getElementById('image-input-'+index))
        document.getElementById('image-input-'+index).click();
    },
    async removeImage(course_id,courseIndex){
      if(!this.imageData){
        this.imageData = {
          image: null,
          name: 'noname'
        };
      }
      this.imageData.image = null
      this.uploadCourseImage(course_id,courseIndex,true)
    },
    async uploadCourseImage(course_id,courseIndex,delete_image) {
      let response;
      //console.log("EL NOMBRE DE LA FOTO ES: " + this.imageData.image.name)
      try {
        //console.log("LAS IMAGENES SON: " + this.imageData.image.name)
        console.log("uploadcourseImage "+course_id,this.imageData)
        if (delete_image || (this.imageData.image.name != null || this.imageData.image.name) ) {
          console.log("Entro aquí",this.imageData)
          let formData = new FormData();
          if(delete_image){
            formData.append("delete_image",true);
          }else {
            formData.append(
              "image",
              this.imageData.image,
              this.imageData.image.name
            );
          }
          formData.append("course_id", course_id);
          // Remove the file from the browser
          this.imageData.image = null;
          response = await fetch(
            process.env.VUE_APP_API_ENDPOINT + "training-provider/upload-course-image",
            {
              method: "POST",
              body: formData,
              // Weirdly, the auth-token is not sent as an auth-token header
              // We need to sent it with the cookies header (credentials: include)
              headers: {
                "Auth-Token": this.$cookie.get("jwt_token"),
              },
            }
          );
          response = await response.json();
          console.log(response)
          if (response.success) {
            this.snackbar_color = "success";
            this.snackbar_text = "Image uploaded succesfully";
            this.snackbar_icon = "mdi-alert-circle";
            this.snackbar_show = true;

            if(response.result){

              if(response.result.image_deleted){
                let course = this.arr.find(o => o.course_id === course_id)
                course.image = null;
                this.editedItem.image = null;
                this.imagePic =  this.VUE_APP_API_ENDPOINT +'images/courses/default.jpg'
                console.log("DELETE IMAGE check course index to update image",course,this.editedItem)
                this.imageData.image = null;
                this.imageData.name = null;

              }else if(response.result.filename){
                let course = this.arr.find(o => o.course_id === course_id)
                course.image = response.result.filename;
                this.editedItem.image = this.VUE_APP_API_ENDPOINT +'images/courses/'+response.result.filename
                this.imagePic =  this.VUE_APP_API_ENDPOINT +'images/courses/'+response.result.filename
                this.imageData.image = null;
                this.imageData.name = null;
              }
            }
          }
        }
      } catch (error) {
        // The browser returns a CORS error but the request succeeds
        // This is a quick fix to avoid displaying a error message to
        // the user even if the request is successful
        if (response.success) return;
        this.snackbar_color = "error";
        this.snackbar_text = error;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },
  }
};
</script>

<style scoped>
.centertext {
  margin-left: auto;
  margin-right: auto;
}
.no-link {
  text-decoration: none;
  color: black;
}
.card-title {
  text-transform: capitalize;
  word-break: normal;
}
.primary-color {
  color: #0096c7;
}
.center-list {
  max-width: 240px;
  margin: 0 auto;
}

.wrapper {
  max-width: 90%;
  margin: 0 auto;
  margin-top: 40px;
}

.button-gradient-half:hover {
  background-position: right center;
}
.button-gradient-half {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 100%);
}


.image-container {
  width: 400px; /* Ancho deseado del contenedor */
  height: 300px; /* Alto deseado del contenedor */

  justify-content: center;
  align-items: center;
}
.uploaded-image{
  object-fit: cover; /* Puedes ajustar esto según tus necesidades */
}
</style>
