<template>
  <v-container class="wrapper">
    <v-row justify="center">
      <v-col>
        <v-card class="card-form">
          <v-card-title class="background-gradient white--text headline">
            <v-icon class="white-icon">mdi-account-edit</v-icon>
            <span class="headline ml-3">Company profile</span>
          </v-card-title>
          <v-card-text class="pt-6 pl-8">
            <v-avatar size="100" class="display-inline">
              <v-img
                :src="
                  VUE_APP_API_ENDPOINT +
                  'images/profiles/' +
                  (personalInformation.image || 'default.jpg') +
                  '?rand=' + rand
                "
              >
              </v-img>
            </v-avatar>
            <a v-if="personalInformation.image" style="vertical-align: top"
               v-on:click="removeImage()"> X </a>
            <v-file-input
              :rules="imageRules"
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Upload photo (500x300px) **Optional"
              v-model="imageData.image"
              prepend-icon="mdi-camera"
              class="input"
              chips
              show-size
              @change="uploadImage()"
            ></v-file-input>
            <v-text-field
              v-model="personalInformation.fullName"
              label="Name of the company"
              placeholder="Start typing"
            ></v-text-field>
            <v-text-field
              v-model="personalInformation.description"
              label="Description of the company"
              placeholder="Start typing"
            ></v-text-field>
            <v-text-field
              v-model="personalInformation.company_identification"
              label="Company identification (CIF, ID, VAT...)"
              placeholder="Start typing"
            ></v-text-field>
            <v-text-field
              v-model="personalInformation.email"
              label="E-mail"
              disabled
            ></v-text-field>
            <v-text-field
              v-model="personalInformation.contact_mail"
              label="Contact E-mail"
              placeholder="Start typing"
              enabled
            ></v-text-field>
            <v-text-field
              v-model="personalInformation.street"
              label="Street"
              placeholder="Start typing"
            ></v-text-field>
            <v-text-field
              v-model="personalInformation.city"
              label="City"
              placeholder="Start typing"
            ></v-text-field>
            <v-text-field
              v-model="personalInformation.state"
              label="State/Province/Region"
              placeholder="Start typing"
            ></v-text-field>
            <v-autocomplete
              v-model="personalInformation.country"
              :items="countries"
              label="Country"
              placeholder="Select"
            ></v-autocomplete>
            <v-text-field
              v-model="personalInformation.contact_person"
              label="Contact person"
              placeholder="Start typing"
            ></v-text-field>
            <v-text-field
              v-model="personalInformation.website"
              label="Website"
              placeholder="Start typing"
            ></v-text-field>

            <v-combobox
              v-model="personalInformation.sector"
              :items="sectors"
              hide-no-data
              hide-details
              label="Professional sector"
              placeholder="Start typing"
              small-chips
              clearable
              deletable-chips
              multiple
              flat

            >
            </v-combobox>
          </v-card-text>
          <v-divider class="mt-12"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="primary"
              text
              @click="
                uploadImage();
                updatePersonalInformation();
              "
            >
              <div class="text-gradient-half">Update</div>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar_show" :color="snackbar_color" top>
      <v-icon>{{ snackbar_icon }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    // Personal information
    personalInformation: {
      user_id: null,
      fullName: null,
      email: null,
      contact_mail: null,
      city: null,
      state: null,
      street: null,
      country: null,
      phone: null,
      image: null,
      description: null,
      company_identification: null,
      contact_person: null,
      sector: null,
      website: null,
      main_product: null,
    },

    imageData: {
      image: null,
    },
    countries: [],
    imageRules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Avatar size should be less than 2 MB!",
    ],
    requiredRules: [(v) => !!v || "This field is required"],
    profilePicture: "default.jpg",
    sectors: [],

    formHasErrors: false,
    // Snackbar
    snackbar_show: false,
    snackbar_color: null,
    snackbar_text: null,
    snackbar_icon: null,
    // env
    VUE_APP_API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,
    rand: new Date(),
  }),
  async created() {
    try {
      await this.getData();
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        this.$router.push("/auth-error");
      }
    }
    await this.getCountries();
  },

  methods: {
    async getData() {

      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "company/user-profile/",
        {
          headers: {
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      if (response.success) {
        this.personalInformation.user_id =
          response.result.personalInformation.user_id;
        this.personalInformation.fullName =
          response.result.personalInformation.full_name;
        this.personalInformation.email =
          response.result.personalInformation.email;
        this.personalInformation.city =
          response.result.personalInformation.city;
        this.personalInformation.state =
          response.result.personalInformation.state;
        this.personalInformation.street =
          response.result.personalInformation.street;
        this.personalInformation.country =
          response.result.personalInformation.country;
        this.personalInformation.phone =
          response.result.personalInformation.phone;
        this.personalInformation.image = response.result.personalInformation.image;
        this.personalInformation.description =
          response.result.personalInformation.description;
        this.personalInformation.company_identification =
          response.result.personalInformation.company_identification;
        this.personalInformation.contact_person =
          response.result.personalInformation.contact_person;
        this.personalInformation.website =
          response.result.personalInformation.website;
        this.personalInformation.main_product =
          response.result.personalInformation.main_product; // Not used

        console.log(response.result.sectors)
        // if (Array.isArray(response.result.sectors)){
        //   this.personalInformation.sector = response.result.sectors
        // }else{
        console.log("ENTRO AQUÍ")
        this.personalInformation.sector = response.result.user_sectors
        // }

        this.personalInformation.contact_mail =
          response.result.personalInformation.contact_mail
        this.sectors = response.result.sectors;

      } else {
        throw new Error(response.message);
      }
    },
    async getCountries() {
      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "common/countries/",
        {
          headers: {
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      this.countries = response.result;
    },
    async removeImage(){
      this.imageData.image = null
      this.uploadImage()
    },

    async uploadImage() {
      let response;
      try {
        let formData = new FormData();
        if (this.imageData.image != null) {
          formData.append(
            "image",
            this.imageData.image,
            this.imageData.image.name
          );
        }
        formData.append("userId", this.personalInformation.user_id);
        // Remove the file from the browser
        this.imageData.image = null

        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT +
            "common/upload-profile-image",
          {
            method: "POST",
            body: formData,
            headers: {
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json()
        if (response.success) {
          this.snackbar_color = "success";
          this.snackbar_text = "Image uploaded succesfully";
          this.snackbar_icon = "mdi-alert-circle";
          this.snackbar_show = true;
          this.personalInformation.image = response.result
        }

      } catch (error) {
        // The browser returns a CORS error but the request succeeds
        // This is a quick fix to avoid displaying a error message to
        // the user even if the request is successful
        if (response.success) return
        this.snackbar_color = "error";
        this.snackbar_text = "Failed " + error;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;

      }
    },

    async updatePersonalInformation() {
      let response;
      console.log("EL OBJETO ES: " + JSON.stringify(this.personalInformation))
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT +
            "company/user-profile/personal-information",
          {
            method: "PUT",
            body: JSON.stringify(this.personalInformation),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
        if (response.success) {
          // Info message
          this.snackbar_color = "success";
          this.snackbar_text = "Personal information updated";
          this.snackbar_icon = "mdi-check";
          // Display the result as a short message
          this.snackbar_show = true;
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = response.message;
        this.snackbar_icon = "mdi-alert-circle";
      }
      // Display the result as a short message
      this.snackbar_show = true;
      // Update page data
      await this.getData();
    },
  },
};
</script>

<style>
.card-form {
  margin: 25px;
}

.white-icon {
  color: white !important;
}

h3:first-letter {
  text-transform: capitalize;
}

.v-treeview-node__label {
  text-transform: capitalize;
  word-wrap: break-word;
  white-space: initial;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
}

.display-inline {
  display: inline-block;
}

.input {
  max-width: 300px;
  display: inline-block;
  margin: 20px;
  margin-bottom: 40px;
}

.background-gradient {
  color: white;
  box-shadow: 0 0 20px #eee;
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 200%);
}

.text-gradient-half {
  background: linear-gradient(to right, #047ec3 0%, #52ae32 150%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
