<template>
  <div class="wrapper">
    <div class="text-center">
      <p class="text-h4 mt-6 mb-4 primary-color">
        Search for Courses & Jobs in the ALLVIEW Platform
      </p>
    </div>
    <div>
      <p class="text-h5 font-weight-black">Top Rated Courses</p>
      <v-row class="mb-0">
        <v-col
          xl="3"
          lg="3"
          md="3"
          sm="6"
          xs="6"
          v-for="course in topRatedCourses"
          :key="course.course_id"
        >
          <v-card hover min-height="350px" height="100%" class="card-outter">
            <router-link
              text
              :to="'/app/company/course/' + course.course_id"
              class="centertext no-link"
            >
              <v-img
                height="175px"
                :src="
                  VUE_APP_API_ENDPOINT +
                  'images/courses/' +
                  (course.image || 'default.jpg')
                "
                contain
              ></v-img>

              <v-card-title>
                <div class="text-subtitle-1 card-title no-link">
                  {{ course.title.toLowerCase() }}
                </div>
              </v-card-title>
            </router-link>

            <v-card-actions class="card-actions">
              <div class="ma-2 pa-0 text-body-1">
                {{ course.partner }}
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-list class="center-list">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Explore all the courses</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <router-link class="no-link" to="/app/company/search-courses">
              <v-icon color="primary"> mdi-arrow-right-circle </v-icon>
            </router-link>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
      <br /><br />
      <p class="text-h5 font-weight-black">
        Recommended Courses For Your Employees
      </p>
      <v-row class="mb-0">
        <v-col
          xl="3"
          lg="3"
          md="3"
          sm="6"
          xs="6"
          v-for="course in recommendedCourses"
          :key="course.course_id"
        >
          <v-card hover min-height="350px" height="100%" class="card-outter">
            <!-- <router-link
              text
              :to="'/app/company/course/' + course.course_id"
              class="centertext no-link"
            > -->
              <v-img
                height="175px"
                :src="
                  VUE_APP_API_ENDPOINT +
                  'images/courses/' +
                  (course.image || 'default.jpg')
                "
                contain
              ></v-img>

              <v-card-title>
                <div class="text-subtitle-1 card-title no-link">
                  {{ course.title.toLowerCase() }}
                </div>
              </v-card-title>

               <v-card-text>
                <div class="ma-2 pa-0 text-body-1">
                  {{ course.partner }}
                </div>
              </v-card-text>
            <!-- </router-link> -->

            <!-- <v-card-actions class="card-actions">
              <div class="ma-2 pa-0 text-body-1">
                {{ course.partner }}
              </div>
            </v-card-actions> -->
            <!-- @click.stop ="(dialog_course = true), (selectedCourse = course)"> -->
            <v-card-actions class="card-actions">
                <v-btn
                  block
                  class="button-gradient-half"
                  @click.stop ="openDialogCourse(course.course_id)">
                  Details
                </v-btn>
                <!-- <v-btn
                  block
                  class="button-gradient-half"
                  @click="viewSelectedCourse(course)">
                  Details
                </v-btn> -->
              </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-list class="center-list">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Explore all the courses</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <router-link class="no-link" to="/app/company/search-courses">
              <v-icon color="primary"> mdi-arrow-right-circle </v-icon>
            </router-link>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
      <br /><br />
      <p class="text-h5 font-weight-black">
        Recommended Candidates For Your Job Offers
      </p>
      <v-row class="mb-0">
        <v-col
          xl="3"
          lg="3"
          md="3"
          sm="6"
          xs="6"
          v-for="candidate in recommendedCandidates"
          :key="candidate.user_job_recommendation_id"
        >
          <v-card
            hover
            min-height="350px"
            height="100%"
            class="card-outter"
            @click.stop="(dialog = true), (selectedPerson = candidate)"
          >
            <v-img
              height="175px"
              :src="
                VUE_APP_API_ENDPOINT +
                'images/profiles/' +
                (candidate.image || 'default.jpg')
              "
              contain
            ></v-img>

            <v-card-title>
              <div class="text-subtitle-1 card-title no-link">
                {{ candidate.full_name.toLowerCase() }}
              </div>
            </v-card-title>

            <v-card-actions class="card-actions">
              <div class="ma-2 pa-0 text-body-1">
                {{ candidate.job_name }}
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-list class="center-list">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Explore all candidates</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <router-link class="no-link" to="/app/company/look-for-candidates">
              <v-icon color="primary"> mdi-arrow-right-circle </v-icon>
            </router-link>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
      <br /><br />
      <br /><br />
    </div>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          Details for {{ selectedPerson.full_name }}
        </v-card-title>

        <v-card-text>
          <p v-if="selectedPerson.cv">
            CV:
            <a
              :href="VUE_APP_API_ENDPOINT + 'cvs/' + selectedPerson.cv"
              target="blank"
              >{{ selectedPerson.cv }}</a
            >
          </p>
          <p v-if="selectedPerson.description">
            Description: {{ selectedPerson.description }}
          </p>
          <p v-if="selectedPerson.country">
            Country: {{ selectedPerson.country }}
          </p>
          <p v-if="selectedPerson.state">State: {{ selectedPerson.state }}</p>
          <p v-if="selectedPerson.city">City: {{ selectedPerson.city }}</p>
          <p v-if="selectedPerson.phone">Phone: {{ selectedPerson.phone }}</p>
          <p v-if="selectedPerson.email">Email: <a :href='"mailto: " + selectedPerson.email'>{{ selectedPerson.email }}</a></p>
          <p v-if="selectedPerson.userProfile">
            Skills: {{ selectedPerson.userProfile.skills.join(", ") }}
          </p>
          <p v-if="selectedPerson.userProfile">
            Competences: {{ selectedPerson.userProfile.competences.join(", ") }}
          </p>
          <p v-if="selectedPerson.userProfile">
            Experiences: {{ selectedPerson.userProfile.experiences.join(", ") }}
          </p>
          <div v-if="selectedPerson.userProfile">
            Educations:
            <div
              v-for="education in selectedPerson.userProfile.educations"
              :key="education.users_educations_id"
            >
              &nbsp;Name: {{ education.name }}<br />
              &nbsp;Level: {{ education.level }}<br />
              &nbsp;University {{ education.university }}<br />
              &nbsp;Start Year: {{ education.start_year }}<br />
              &nbsp;End year: {{ education.end_year }}
            </div>
            <br />
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_course"
    max-width="800"
    >
        <course-view :selected-course = "selectedCourse"
                     v-on:close="closeDialog"

        />
    </v-dialog>

  </div>
</template>

<script>
import CourseView from "@/pages/app/common/CourseView.vue";

export default {
  name: "Home",
  components: {CourseView},

  data: () => ({
    topRatedCourses: [],
    recommendedCourses: [],
    recommendedCandidates: [],
    // User details dialog
    dialog: false,
    dialog_course: false,
    selectedCourse: {},
    selectedPerson: {},
    // env
    VUE_APP_API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,
  }),
  async mounted() {
    try {
      await this.getData();
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        this.$router.push("/auth-error");
      }
    }
  },
  methods: {
    async getData() {
      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "company/home",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      if (response.success) {
        console.log(JSON.stringify(response.result))
        this.topRatedCourses = response.result.topRatedCourses;
        this.recommendedCourses = response.result.recommendedCourses;
        this.recommendedCandidates = response.result.recommendedCandidates;
      } else {
        throw new Error(response.message);
      }
    },
  async closeDialog(course){
    this.dialog_course = false
    console.log(JSON.stringify(course))
  },
   async openDialogCourse(course_id){
    this.dialog_course = true
    let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT +
          "common/courses/" + course_id,
          //this.$route.params.pathMatch,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      if (response.success) {
        this.selectedCourse = response.result;
      } else {
        throw new Error(response.message);
      }
  }

  },
};
</script>

<style>
.centertext {
  margin-left: auto;
  margin-right: auto;
}
.no-link {
  text-decoration: none;
  color: black;
}
.card-title {
  text-transform: capitalize;
  word-break: normal;
}
.primary-color {
  color: #0096c7;
}
.center-list {
  max-width: 240px;
  margin: 0 auto;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}
.button-gradient:hover {
  background-position: right center;
}

.button-gradient {
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(
    to right,
    #047ec3 0%,
    #52ae32 51%,
    #047ec3 100%
  );
}

.gray {
  color: #222c3a;
}

.button-gradient-half:hover {
  background-position: right center;
}

.button-gradient-half {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 100%);
}

.button-gradient-error-half:hover {
  background-position: right center;
}
.button-gradient-error-half {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  background-image: linear-gradient(to right, #ec6608 0%, #f2e500 150%);
}
</style>
