<template>
  <v-container class="wrapper">
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <h2 class="text-center">Accepted Ratings</h2>
        <br />
        <v-data-table
          :headers="rateCourses"
          :items="rateAccepted"
          class="elevation-1"
        >
        </v-data-table>
      </v-col>

      <v-col cols="12" sm="12" md="6">
        <h2 class="text-center">Rejected Ratings</h2>
        <br />
        <v-data-table
          :headers="rateCourses"
          :items="rateRejected"
          class="elevation-1"
        >
        </v-data-table>
      </v-col>

      <v-row justify="center">
        <h2 class="text-center mt-2 mb-2">Pending Ratings</h2>
      </v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        class="mb-2"
        v-for="rating in ratePending"
        :key="rating"
      >
        <div>
          
          <v-card min-height="10" class="pa-3">
            <v-avatar size="100" class="display-inline">
              <v-img :src="VUE_APP_API_ENDPOINT + 'images/' + rating.image">
              </v-img>
            </v-avatar>
            <v-card-text class="ma-2 pa-0"
              ><b>Course title: </b>{{ rating.title }}</v-card-text
            >
            <v-card-text class="ma-2 pa-0"
              ><b>Comment: </b>{{ rating.comment }}
            </v-card-text>
            <v-card-text class="ma-2 pa-0"
              ><b>Justification: </b>{{ rating.reason }}</v-card-text
            >
            <v-card-text class="ma-2 pa-0"
              ><b>Rating: </b> {{ rating.rating }}
            </v-card-text>
            <v-card-text class="ma-2 pa-0"
              ><b>User Name: </b> {{ rating.full_name }}
            </v-card-text>

            <v-card-text class="ma-2 pa-0"
              ><b>User Email: </b> {{ rating.email }}
            </v-card-text>

            <v-btn
              block
              class="button-gradient-half mb-2"
              @click="acceptRating(rating.users_courses_id)"
            >
              Accept
            </v-btn>
            <v-btn
              block
              class="button-gradient-error-half"
              @click="rejectRating(rating.users_courses_id)"
            >
              Reject
            </v-btn>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar_show" :color="snackbar_color" top>
      <v-icon>{{ snackbar_icon }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "ConfirmCourses",
  data: () => ({
    coursesRate: [],
    courseRateAccepted: [],
    courseRateRejected: [],
    courseRatePending: [],
    rateAccepted: [],
    rateRejected: [],
    ratePending: [],

    snackbar_show: false,
    snackbar_color: null,
    snackbar_text: null,
    snackbar_icon: null,

    // env
    VUE_APP_API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,

    rateCourses: [
      { text: "title", value: "title" },
      { text: "User", value: "full_name" },
      { text: "Comment", value: "comment" },
      { text: "Justification", value: "reason" },
      { text: "Rating", value: "rating" },
    ],
  }),

  async mounted() {
    try {
      await this.getData();
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        this.$router.push("/auth-error");
      }
    }
  },
  methods: {
    async getData() {
      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "training-provider/validate-courses",
        {
          headers: {
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      if (response.success) {
        this.ratePending = response.result.pendingCourses;
        this.rateAccepted = response.result.acceptedCourses;
        this.rateRejected = response.result.rejectedCourses;
      } else {
        throw new Error(response.message);
      }
    },
    async acceptRating(rateid) {
      let coursesRate;
      try {
        coursesRate = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "training-provider/accept-rate",
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
            body: JSON.stringify({
              rateid: rateid,
            }),
          }
        );
        coursesRate = await coursesRate.json();
        if (coursesRate.success) {
          await this.getData();
          this.snackbar_color = "success";
          this.snackbar_text = "Rate status updated succesfully";
          this.snackbar_show = true;
        } else {
          throw new Error(coursesRate.message);
        }
        return coursesRate;
      } catch (error) {
        this.snackbar_color = "error";
        this.snackbar_text = error.message;
        this.snackbar_show = true;
      }
    },
    async rejectRating(rateid) {
      let coursesRate;
      try {
        coursesRate = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "training-provider/reject-rate",
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
            body: JSON.stringify({
              rateid: rateid,
            }),
          }
        );
        coursesRate = await coursesRate.json();
        if (coursesRate.success) {
          await this.getData();
          this.snackbar_color = "success";
          this.snackbar_text = "Rate status updated succesfully";
          this.snackbar_show = true;
        } else {
          throw new Error(coursesRate.message);
        }
        return coursesRate;
      } catch (error) {
        this.snackbar_color = "error";
        this.snackbar_text = error.message;
        this.snackbar_show = true;
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}
.display-inline {
  display: inline-block;
}


.button-gradient-half {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 100%);
}

.button-gradient-error-half {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  background-image: linear-gradient(to right, #ec6608 0%, #f2e500 150%);
}
</style>