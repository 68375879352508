<template>
  <v-container class="wrapper">
    <div class="text-center">
      <p class="text-h4 gray">
        Learning Paths
      </p>
    </div>
    <div v-if="userChallenges.length != 0 && Object.keys(userChallenges).length === 0">
        <p class="text-center">
          Please, configure in your profile any job challenge first so the platform can calculate the learning paths.
        </p>
      </div>
    <v-card
      class="card-form mt-4"
      v-for="(jobChallengeSkills, jobChallengeName) in userChallenges"
      :key="jobChallengeName"
    >
      <v-card-title class="white--text headline background-gradient">
        <v-icon class="white-icon">mdi-map-marker-path</v-icon>
        <span class="headline ml-3"
          >Skills and competences for: {{ jobChallengeName }}</span
        >
      </v-card-title>

      <div
        v-for="(_, essentialOrOptional) in jobChallengeSkills"
        :key="essentialOrOptional"
      >
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(item2, j) in jobChallengeSkills[essentialOrOptional]"
            :key="j"
          >
            <v-expansion-panel-header disable-icon-rotate>
              {{ item2.skill_name }} ({{ essentialOrOptional }})
              <template v-slot:actions>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="
                        userSkills.filter(
                          (o) => o.skill_name === item2.skill_name
                        ).length > 0
                      "
                      color="success"
                    >
                      mdi-check-circle
                    </v-icon>
                    <v-icon v-else color="error" v-bind="attrs" v-on="on">
                      mdi-close-circle</v-icon
                    >
                  </template>
                  <span>You don't have this skill</span>
                </v-tooltip>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ item2.skill_description }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Learning paths",
  data: () => ({
    userSkills: [],
    userChallenges: [],
  }),
  async mounted() {
    try {
      await this.getData();
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        this.$router.push("/auth-error");
      }
    }
  },
  methods: {
    async getData() {
      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "people/learning-paths/",
        {
          headers: {
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      if (response.success) {
        this.userSkills = response.result.userSkills;
        this.userChallenges = response.result.userChallenges;
      } else {
        throw new Error(response.message);
      }
    },
  },
};
</script>

<style>
.white-icon {
  color: white !important;
}
.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}
.gray {
  color: #222c3a;
}

.background-gradient {
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 100%);
}
</style>