<template>
  <div class="wrapper">
    <div class="text-center">
      <p class="text-h4 mt-6 mb-4 primary-color">
        Search for Courses & Jobs in the ALLVIEW Platform
      </p>
    </div>
    <div>
      <p class="text-h5 font-weight-black">Top Rated Courses</p>
      <v-row class="mb-0">
        <v-col
          xl="3"
          lg="3"
          md="3"
          sm="6"
          xs="6"
          v-for="course in topRatedCourses"
          :key="course.course_id"
        >
          <v-card hover min-height="350px" height="100%" class="card-outter">
            <router-link
              text
              to="register"
              class="centertext no-link"
            >
              <v-img
                height="175px"
                :src="
                  VUE_APP_API_ENDPOINT +
                  'images/courses/' +
                  (course.image || 'default.jpg')
                "
                contain
              ></v-img>

              <v-card-title>
                <div class="text-subtitle-1 card-title">
                  {{ course.title.toLowerCase() }}
                </div>
              </v-card-title>
            </router-link>

            <v-card-actions class="card-actions">
              <div class="ma-2 pa-0 text-body-1">
                {{ course.partner }}
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-list class="center-list">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Explore all the courses</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <router-link
              class="no-link"
              to="register"
              target="_blank"
            >
              <v-icon color="primary"> mdi-arrow-right-circle </v-icon>
            </router-link>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </div>

    <!-- JOBS -->

    <div>
      <p class="text-h5 font-weight-black">Most recent job offers</p>
      <v-row class="mb-0">
        <v-col
          xl="3"
          lg="3"
          md="3"
          sm="6"
          xs="6"
          v-for="job in jobs"
          :key="job.job_id"
        >
          <v-card hover min-height="350px" height="100%" class="card-outter">
            <router-link
              text
              to="register"
              class="centertext link no-link"
              target="_blank"
            >
              <v-img
                height="175px"
                :src="
                    VUE_APP_API_ENDPOINT +
                    'images/jobs/' +
                    (job.image || 'default.jpg')
                  "
                contain
              ></v-img>

              <v-card-title>
                <div class="text-subtitle-1 card-title">
                  {{ job.name.toLowerCase() }}
                </div>
              </v-card-title>
            </router-link>

            <v-card-actions class="card-actions">
              <div class="ma-2 pa-0 text-body-1">
                {{ job.full_name }}
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-list class="center-list">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Explore all jobs</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <router-link
              class="no-link"
              to="register"
              target="_blank"
            >
              <v-icon color="primary"> mdi-arrow-right-circle </v-icon>
            </router-link>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </div>

  </div>
</template>



<script>

export default {
  name: "CoursesJobsTease",


  data: () => ({
    topRatedCourses: [],
    jobs: [],
    // env
    VUE_APP_API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,
  }),
  async mounted() {
    try {
      await this.getData();
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        this.$router.push("/auth-error");
      }
    }
  },
  methods: {
    async getData() {
      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "home/landing_courses_jobs",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            //"Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      if (response.success) {
        this.topRatedCourses = response.result.topRatedCourses;
        this.jobs = response.result.jobs;
      } else {
        throw new Error(response.message);
      }
    },

  },
};
</script>

<style>
.centertext {
  margin-left: auto;
  margin-right: auto;
}
.no-link {
  text-decoration: none;
  color: black;
}
.card-title {
  text-transform: capitalize;
  word-break: normal;
}
.primary-color {
  color: #0096c7;
}
.center-list {
  max-width: 240px;
  margin: 0 auto;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}
.button-gradient:hover {
  background-position: right center;
}

.button-gradient {
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(
    to right,
    #047ec3 0%,
    #52ae32 51%,
    #047ec3 100%
  );
}

.gray {
  color: #222c3a;
}

.button-gradient-half:hover {
  background-position: right center;
}

.button-gradient-half {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 100%);
}

.button-gradient-error-half:hover {
  background-position: right center;
}
.button-gradient-error-half {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  background-image: linear-gradient(to right, #ec6608 0%, #f2e500 150%);
}
</style>
