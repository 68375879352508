<template>
  <v-container class="wrapper">
    <v-row justify="center">
      <v-col>
        <v-card class="card-form">
          <v-card-title
            class="primary white--text headline background-gradient"
          >
            <v-icon class="white-icon">mdi-account-edit</v-icon>
            <span class="headline ml-3">Personal Information</span>
          </v-card-title>
          <v-card-text class="pt-6 pl-8">
            <v-avatar size="100" class="display-inline">
              <v-img
                :src="
                  VUE_APP_API_ENDPOINT +
                  'images/profiles/' +
                  (personalInformation.image || 'default.jpg') +
                  '?rand=' + rand"
              >
              </v-img>
            </v-avatar>
            <a v-if="personalInformation.image" style="vertical-align: top; margin-left: 8px !important;"
               v-on:click="removeImage()"> X </a>
            <v-file-input
              :rules="fileRules"
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Upload photo (500x300px) **Optional"
              v-model="imageData.image"
              prepend-icon="mdi-camera"
              class="input"
              chips
              show-size
              @change="uploadImage()"
            ></v-file-input>
            <v-text-field
              v-model="personalInformation.fullName"
              label="Full Name"
              placeholder="John Doe"
            ></v-text-field>

            <v-text-field
              v-model="personalInformation.email"
              label="E-mail"
              disabled
            ></v-text-field>
            <v-text-field
              v-model="personalInformation.city"
              label="City"
              placeholder="Cartagena"
            ></v-text-field>
            <v-text-field
              v-model="personalInformation.state"
              label="State/Province/Region"
              placeholder="Murcia"
            ></v-text-field>
            <v-autocomplete
              v-model="personalInformation.country"
              :items="countries"
              label="Country"
              placeholder="Select..."
            ></v-autocomplete>
            <v-text-field
              v-model="personalInformation.phone"
              label="Phone Number"
            ></v-text-field>
            <v-file-input
              :rules="fileRules"
              accept="application/pdf"
              placeholder="Upload CV (PDF)"
              v-model="cvData.cv"
              show-size
              chips
            ></v-file-input>
            <div v-if="personalInformation.cv">
              Check your saved CV
              <a
                :href="VUE_APP_API_ENDPOINT + 'cvs/' + personalInformation.cv"
                target="blank"
                >here</a
              >
            </div>
          </v-card-text>
          <v-divider class="mt-12"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="personalInformation.cv" text class="text-gradient-half" @click="deleteCV"
              ><div class="text-gradient-half">Delete CV</div></v-btn
            >
            <v-btn
              text
              @click="
                uploadImage();
                uploadCV();
                updatePersonalInformation();
              "
            >
              <div class="text-gradient-half">Update</div>
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="card-form">
          <v-card-title
            class="primary white--text headline background-gradient"
          >
            <v-icon class="white-icon">mdi-briefcase</v-icon>
            <span class="headline ml-3">Curriculum vitae: Skills</span>
          </v-card-title>
          <v-row class="pa-4">
            <v-col>
              <v-autocomplete
                v-model="skillsSelectedAutocomplete"
                :loading="skillsAutocompleteLoading"
                :items="skillsAutocompleteSearch"
                :search-input.sync="skillAutocompleteSearch"
                hide-no-data
                hide-details
                label="Search your skills"
                chips
                clearable
                deletable-chips
                multiple
                solo
                flat
                prepend-icon="mdi-magnify"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="pa-4">
            <v-col>
              <v-chip-group show-arrows column active-class="primary--text">
                <v-chip
                  color="primary"
                  close
                  v-for="tag in skillsSelected"
                  :key="tag"
                  @click:close="deleteSkill(tag)"
                >
                  {{ tag }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="updateUserSkills">
              <div class="text-gradient-half">Update</div>
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card class="card-form">
          <v-card-title
            class="primary white--text headline background-gradient"
          >
            <v-icon class="white-icon">mdi-briefcase</v-icon>
            <span class="headline ml-3">Curriculum vitae: Competences</span>
          </v-card-title>
          <v-row class="pa-4">
            <v-col>
              <v-autocomplete
                v-model="competencesSelectedAutocomplete"
                :loading="competencesAutocompleteLoading"
                :items="competencesAutocompleteSearch"
                :search-input.sync="competenceAutocompleteSearch"
                hide-no-data
                hide-details
                label="Search your competences"
                chips
                clearable
                deletable-chips
                multiple
                solo
                flat
                prepend-icon="mdi-magnify"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="pa-4">
            <v-col>
              <v-chip-group show-arrows column active-class="primary--text">
                <v-chip
                  color="primary"
                  close
                  v-for="tag in competencesSelected"
                  :key="tag"
                  @click:close="deleteCompetence(tag)"
                >
                  {{ tag }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="updateUserCompetences">
              <div class="text-gradient-half">Update</div>
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card class="card-form">
          <v-card-title
            class="primary white--text headline background-gradient"
          >
            <v-icon class="white-icon">mdi-pickaxe</v-icon>
            <span class="headline ml-3">Curriculum vitae: Job Experiences</span>
          </v-card-title>
          <v-row class="pa-4">
            <v-col>
              <v-autocomplete
                v-model="experiencesSelectedAutocomplete"
                :loading="experiencesAutocompleteLoading"
                :items="experiencesAutocompleteSearch"
                :search-input.sync="experienceAutocompleteSearch"
                hide-no-data
                hide-details
                label="Search your experiences"
                chips
                clearable
                deletable-chips
                multiple
                solo
                flat
                prepend-icon="mdi-magnify"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="pa-4">
            <v-col>
              <v-chip-group show-arrows column active-class="primary--text">
                <v-chip
                  color="primary"
                  close
                  v-for="tag in experiencesSelected"
                  :key="tag"
                  @click:close="deleteExperience(tag)"
                >
                  {{ tag }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="updateUserExperiences">
              <div class="text-gradient-half">Update</div>
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card class="card-form">
          <v-card-title
            class="primary white--text headline background-gradient"
          >
            <v-icon class="white-icon">mdi-school</v-icon>
            <span class="headline ml-3">Curriculum vitae: Education</span>
            <v-spacer></v-spacer>
            <v-btn
              text
              small
              elevation="0"
              @click="
                educationsSelected.push({
                  name: 'Education name (Eg: Telecommunication Engineering)',
                  startAndEndYear: [2004, 2014],
                })
              "
            >
              <v-icon class="white-icon"> mdi-plus </v-icon>
            </v-btn>
          </v-card-title>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(education, index) in educationsSelected"
              :key="index"
            >
              <v-expansion-panel-header class="font-weight-medium">
                {{ education.name }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text class="pt-6 pl-8">
                  <v-text-field
                    v-model="educationsSelected[index].university"
                    label="University/Academy/School/Other (Eg: Politechnical University of Cartagena)"
                    required
                    :rules="requiredRules"
                  ></v-text-field>

                  <v-autocomplete
                    clearable
                    v-model="educationsSelected[index].level"
                    label="Education level"
                    placeholder="Select your degree..."
                    :items="degrees"
                    required
                  ></v-autocomplete>
                  <v-text-field
                    label="Education name (Eg: Telecommunications)"
                    v-model="educationsSelected[index].name"
                    required
                    :rules="requiredRules"
                  ></v-text-field>
                  <br />
                  <v-range-slider
                    v-model="educationsSelected[index].startAndEndYear"
                    label="Start and end year"
                    hint="Select the start and end year"
                    max="2025"
                    min="1970"
                    step="1"
                    color="primary"
                    thumb-label="always"
                  ></v-range-slider>
                  <v-divider class="mt-12"></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      @click="
                        educationsSelected.splice(index, 1);
                        updateUserEducations();
                      "
                    >
                      <div class="text-gradient-half">Delete</div>
                    </v-btn>
                    <v-btn color="primary" text @click="updateUserEducations">
                      <div class="text-gradient-half">Update</div>
                    </v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>

        <v-card class="card-form">
          <v-card-title
            class="primary white--text headline background-gradient"
          >
            <v-icon class="white-icon">mdi-briefcase</v-icon>
            <span class="headline ml-3">Job Challenges</span>
          </v-card-title>
          <v-row class="pa-4">
            <v-col>
              <v-autocomplete
                v-model="challengesSelectedAutocomplete"
                :loading="challengesAutocompleteLoading"
                :items="challengesAutocompleteSearch"
                :search-input.sync="challengeAutocompleteSearch"
                hide-no-data
                hide-details
                label="Search your job challenges"
                chips
                clearable
                deletable-chips
                multiple
                solo
                flat
                prepend-icon="mdi-magnify"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="pa-4">
            <v-col>
              <v-chip-group show-arrows column active-class="primary--text">
                <v-chip
                  color="primary"
                  close
                  v-for="tag in challengesSelected"
                  :key="tag"
                  @click:close="deleteChallenge(tag)"
                >
                  {{ tag }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="updateUserChallenges">
              <div class="text-gradient-half">Update</div>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar_show" :color="snackbar_color" top>
      <v-icon>{{ snackbar_icon }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    // Personal information
    personalInformation: {
      user_id: null,
      fullName: null,
      email: null,
      city: null,
      state: null,
      country: null,
      phone: null,
      image: null,
      cv: null,
    },

    imageData: {
      image: null,
    },
    cvData: {
      cv: null,
    },
    countries: [],
    fileRules: [
      (value) =>
        !value || value.size < 2000000 || "File size should be less than 2 MB!",
    ],
    requiredRules: [(v) => !!v || "This field is required"],

    formHasErrors: false,
    // https://en.wikipedia.org/wiki/International_Standard_Classification_of_Education
    degrees: [
      "Early childhood Education",
      "Primary education",
      "Lower secondary education",
      "Upper secondary education",
      "Post-secondary non-tertiary education",
      "Short-cycle tertiary education",
      "Bachelor's or equivalent",
      "Master's or equivalent",
      "Doctoral or equivalent",
      "Other",
    ],
    modal: false,
    // Skills
    skillsSelected: null, // Skills saved by the user
    skillsAutocompleteLoading: false, // Loading animation autocomplete
    skillsAutocompleteSearch: [], // Results of the skills search autocomplete
    skillAutocompleteSearch: null, // Autocomplete search field
    skillsSelectedAutocomplete: [], // Autocomplete skills selected
    // Competences
    competencesSelected: null, // Competences saved by the user
    competencesAutocompleteLoading: false, // Loading animation autocomplete
    competencesAutocompleteSearch: [], // Results of the competences search autocomplete
    competenceAutocompleteSearch: null, // Autocomplete search field
    competencesSelectedAutocomplete: [], // Autocomplete competences selected
    // Experiences
    experiencesSelected: null, // Experiences saved by the user
    experiencesAutocompleteLoading: false, // Loading animation autocomplete
    experiencesAutocompleteSearch: [], // Results of the experiences search autocomplete
    experienceAutocompleteSearch: null, // Autocomplete search field
    experiencesSelectedAutocomplete: [], // Autocomplete experiences selected
    // Challenges
    challengesSelected: null, // Challenges saved by the user
    challengesAutocompleteLoading: false, // Loading animation autocomplete
    challengesAutocompleteSearch: [], // Results of the challenges search autocomplete
    challengeAutocompleteSearch: null, // Autocomplete search field
    challengesSelectedAutocomplete: [], // Autocomplete challenges selected
    // Educations
    educationsSelected: [],
    // Snackbar
    snackbar_show: false,
    snackbar_color: null,
    snackbar_text: null,
    snackbar_icon: null,
    // env
    VUE_APP_API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,
    rand: new Date()
  }),
  watch: {
    skillAutocompleteSearch(name) {
      name && name !== this.select && this.searchSkillsByName(name);
    },
    competenceAutocompleteSearch(name) {
      name && name !== this.select && this.searchCompetencesByName(name);
    },
    experienceAutocompleteSearch(name) {
      name && name !== this.select && this.searchExperiencesByName(name);
    },
    challengeAutocompleteSearch(name) {
      name && name !== this.select && this.searchChallengesByName(name);
    },
  },
  async created() {
    try {
      await this.getData();
      await this.getCountries();
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        this.$router.push("/auth-error");
      }
    }
  },

  methods: {
    async getData() {
      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "people/user-profile/",
        {
          headers: {
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      if (response.success) {
        this.personalInformation.user_id =
          response.result.personalInformation.user_id;
        console;
        this.personalInformation.fullName =
          response.result.personalInformation.full_name;
        this.personalInformation.email =
          response.result.personalInformation.email;
        this.personalInformation.city =
          response.result.personalInformation.city;
        this.personalInformation.state =
          response.result.personalInformation.state;
        this.personalInformation.country =
          response.result.personalInformation.country;
        this.personalInformation.phone =
          response.result.personalInformation.phone;
        this.personalInformation.image =
          response.result.personalInformation.image;
        this.personalInformation.cv = response.result.personalInformation.cv;
        this.skillsSelected = response.result.skills;
        this.competencesSelected = response.result.competences;
        this.challengesSelected = response.result.challenges;
        this.experiencesSelected = response.result.experiences;
        this.educationsSelected = response.result.educations;
        this.$emit('missingProfile', response.result.missingProfile)
      } else {
        throw new Error(response.message);
      }
    },
    async getCountries() {
      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "common/countries/",
        {
          headers: {
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      this.countries = response.result;
    },
    async updatePersonalInformation() {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT +
            "people/user-profile/personal-information",
          {
            method: "PUT",
            body: JSON.stringify(this.personalInformation),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
        if (response.success) {
          // Info message
          this.snackbar_color = "success";
          this.snackbar_text = "Personal information updated";
          this.snackbar_icon = "mdi-check";
          // Display the result as a short message
          this.snackbar_show = true;
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = response.message;
        this.snackbar_icon = "mdi-alert-circle";
      }
      // Display the result as a short message
      this.snackbar_show = true;
      await this.getData();
    },
    async searchSkillsByName(name) {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/search-skills-by-name",
          {
            method: "POST",
            body: JSON.stringify({ name: name }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
      } catch (error) {
        console.log(error.message);
      }
      this.skillsAutocompleteLoading = false;
      this.skillsAutocompleteSearch = response.result;
    },
    async searchCompetencesByName(name) {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT +
            "common/search-competences-by-name",
          {
            method: "POST",
            body: JSON.stringify({ name: name }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
      } catch (error) {
        console.log(error.message);
      }
      this.competencesAutocompleteLoading = false;
      this.competencesAutocompleteSearch = response.result;
    },
    async searchExperiencesByName(name) {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT +
            "common/search-experiences-by-name",
          {
            method: "POST",
            body: JSON.stringify({ name: name }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
      } catch (error) {
        console.log(error.message);
      }
      this.experiencesAutocompleteLoading = false;
      this.experiencesAutocompleteSearch = response.result;
    },
    async searchChallengesByName(name) {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/search-challenges-by-name",
          {
            method: "POST",
            body: JSON.stringify({ name: name }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
      } catch (error) {
        console.log(error.message);
      }
      this.challengesAutocompleteLoading = false;
      this.challengesAutocompleteSearch = response.result;
    },
    async updateUserEducations() {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT +
            "people/user-profile/user-educations",
          {
            method: "PUT",
            body: JSON.stringify({ educations: this.educationsSelected }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
        if (response.success) {
          // Info message
          this.snackbar_color = "success";
          this.snackbar_text = "Educations updated";
          this.snackbar_icon = "mdi-check";
          // Display the result as a short message
          this.snackbar_show = true;
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = response.message;
        this.snackbar_icon = "mdi-alert-circle";
      }
      // Display the result as a short message
      this.snackbar_show = true;
      await this.getData();
    },
    async updateUserSkills() {
      // Join the autocomplete skills with the saved skills and remove duplicates
      const newSkills = this.skillsSelectedAutocomplete.concat(
        this.skillsSelected.filter(
          (item) => this.skillsSelectedAutocomplete.indexOf(item) < 0
        )
      );
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "people/user-profile/user-skills",
          {
            method: "PUT",
            body: JSON.stringify({ skills: newSkills }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
        if (response.success) {
          // Info message
          this.snackbar_color = "success";
          this.snackbar_text = "Skills updated";
          this.snackbar_icon = "mdi-check";
          // Display the result as a short message
          this.snackbar_show = true;
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = response.message;
        this.snackbar_icon = "mdi-alert-circle";
      }
      // Display the result as a short message
      this.snackbar_show = true;
      // Update the displayed data
      await this.getData();
      this.skillsSelectedAutocomplete = [];
    },
    deleteSkill(name) {
      for (const i in this.skillsSelected) {
        if (this.skillsSelected[i] === name) {
          this.skillsSelected.splice(i, 1);
        }
      }
    },
    deleteExperience(name) {
      for (const i in this.experiencesSelected) {
        if (this.experiencesSelected[i] === name) {
          this.experiencesSelected.splice(i, 1);
        }
      }
    },
    async updateUserCompetences() {
      // Join the autocomplete competences with the saved skills and remove duplicates
      const newCompetences = this.competencesSelectedAutocomplete.concat(
        this.competencesSelected.filter(
          (item) => this.competencesSelectedAutocomplete.indexOf(item) < 0
        )
      );
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT +
            "people/user-profile/user-competences",
          {
            method: "PUT",
            body: JSON.stringify({ skills: newCompetences }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
        if (response.success) {
          // Info message
          this.snackbar_color = "success";
          this.snackbar_text = "Competences updated";
          this.snackbar_icon = "mdi-check";
          // Display the result as a short message
          this.snackbar_show = true;
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = response.message;
        this.snackbar_icon = "mdi-alert-circle";
      }
      // Display the result as a short message
      this.snackbar_show = true;
      // Update the displayed data
      await this.getData();
      this.competencesSelectedAutocomplete = [];
    },
    deleteCompetence(name) {
      for (const i in this.competencesSelected) {
        if (this.competencesSelected[i] === name) {
          this.competencesSelected.splice(i, 1);
        }
      }
    },
    async updateUserChallenges() {
      // Join the autocomplete challenges with the saved challenges and remove duplicates
      const newChallenges = this.challengesSelectedAutocomplete.concat(
        this.challengesSelected.filter(
          (item) => this.challengesSelectedAutocomplete.indexOf(item) < 0
        )
      );
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT +
            "people/user-profile/user-challenges",
          {
            method: "PUT",
            body: JSON.stringify({ challenges: newChallenges }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
        if (response.success) {
          // Info message
          this.snackbar_color = "success";
          this.snackbar_text = "Challenges updated";
          this.snackbar_icon = "mdi-check";
          // Display the result as a short message
          this.snackbar_show = true;
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = response.message;
        this.snackbar_icon = "mdi-alert-circle";
      }
      // Display the result as a short message
      this.snackbar_show = true;
      // Update the displayed data
      await this.getData();
      this.challengesSelectedAutocomplete = [];
    },

    async removeImage(){
      this.imageData.image = null
      this.uploadImage()
    },

    async uploadImage() {

      let response;

      try {
        let formData = new FormData();
        if (this.imageData.image != null) {
          formData.append(
            "image",
            this.imageData.image,
            this.imageData.image.name
          );
        }
        formData.append("userId", this.personalInformation.user_id);
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/upload-profile-image",
          {
            method: "POST",
            body: formData,
            headers: {
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        )
        response = await response.json()
        // console.log(response)
        // console.log(JSON.stringify(response))
        if (response.success) {
          this.snackbar_color = "success";
          this.snackbar_text = "Image uploaded succesfully";
          this.snackbar_icon = "mdi-alert-circle";
          this.snackbar_show = true;
          this.personalInformation.image = response.result
        }

      } catch (error) {
        // The browser returns a CORS error but the request succeeds
        // This is a quick fix to avoid displaying a error message to
        // the user even if the request is successful
        if (response.success) return;
        this.snackbar_color = "error";
        this.snackbar_text = "Failed " + error;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },

    async uploadCV() {
      let response;
      try {
        if (this.cvData.cv != null) {
          let formData = new FormData();
          formData.append("cv", this.cvData.cv, this.cvData.cv.name);
          formData.append("userId", this.personalInformation.user_id);
          // Remove the file from the browser
          this.imageData.image = null;

          response = await fetch(
            process.env.VUE_APP_API_ENDPOINT + "people/upload-cv",
            {
              method: "POST",
              body: formData,
              headers: {
                "Auth-Token": this.$cookie.get("jwt_token"),
              },
            }
          );
          if (response.success) {
            this.snackbar_color = "success";
            this.snackbar_text = "CV uploaded succesfully";
            this.snackbar_icon = "mdi-alert-circle";
            this.snackbar_show = true;
          }
        }
      } catch (error) {
        // The browser returns a CORS error but the request succeeds
        // This is a quick fix to avoid displaying a error message to
        // the user even if the request is successful
        if (response.success) return;
        this.snackbar_color = "error";
        this.snackbar_text = "Failed " + error;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },
    async deleteCV() {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "people/delete-cv",
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
        if (response.success) {
          this.snackbar_color = "success";
          this.snackbar_text = "CV deleted succesfully";
          this.snackbar_icon = "mdi-alert-circle";
          this.snackbar_show = true;
          // Update the displayed data
          await this.getData();
        }
      } catch (error) {
        this.snackbar_color = "error";
        this.snackbar_text = error.message;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },
    deleteChallenge(challengeName) {
      for (const i in this.challengesSelected) {
        if (this.challengesSelected[i] === challengeName) {
          this.challengesSelected.splice(i, 1);
        }
      }
    },
    async updateUserExperiences() {
      // Join the autocomplete experiences with the saved experiences and remove duplicates
      const newExperiences = this.experiencesSelectedAutocomplete.concat(
        this.experiencesSelected.filter(
          (item) => this.experiencesSelectedAutocomplete.indexOf(item) < 0
        )
      );
      let response;
      console.log("Updating experiences:", newExperiences);
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT +
            "people/user-profile/user-experiences",
          {
            method: "PUT",
            body: JSON.stringify({ experiences: newExperiences }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
        if (response.success) {
          // Info message
          this.snackbar_color = "success";
          this.snackbar_text = "Experiences updated";
          this.snackbar_icon = "mdi-check";
          // Display the result as a short message
          this.snackbar_show = true;
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = response.message;
        this.snackbar_icon = "mdi-alert-circle";
      }
      // Display the result as a short message
      this.snackbar_show = true;
      // Update the displayed data
      await this.getData();
      this.experiencesSelectedAutocomplete = [];
    },
  },
};
</script>

<style>
.card-form {
  margin: 25px;
}

.white-icon {
  color: white !important;
}

h3:first-letter {
  text-transform: capitalize;
}

.v-treeview-node__label {
  text-transform: capitalize;
  word-wrap: break-word;
  white-space: initial;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
}

.display-inline {
  display: inline-block;
}

.input {
  max-width: 300px;
  display: inline-block;
  margin: 20px;
  margin-bottom: 40px;
}

.background-gradient {
  color: white;
  box-shadow: 0 0 20px #eee;
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 200%);
}

.text-gradient-half {
  background: linear-gradient(to right, #047ec3 0%, #52ae32 150%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
