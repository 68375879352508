<template>
  <v-container style="margin-top: -2.8em;">
    <div class="d-flex">
      <v-divider class="my-1"></v-divider>
      <div class="mx-3 overline">Allview Video</div>
      <v-divider class="my-1"></v-divider>
    </div>
      <v-col cols="12" md="12" lg="12" class="video-container d-flex justify-center align-start" ref="videoSection" >
    <video
      :poster="require('@/assets/video/portada.png')"
      autoplay
      muted
      loop
      playsinline
      controls>
      <source :src="videoUrl" type="video/mp4" />
      Tu navegador no soporta el video.
    </video>
      </v-col>

  </v-container>
</template>

<script>
import { onMounted, ref } from 'vue';

export default {
  name: 'PromoVideo',
  data() {
    return {
      videoUrl: require('@/assets/video/VIDEO.mp4'), // Ruta del video
      poster:  require('@/assets/video/portada.png'), // Imagen de fondo mientras carga
    };
  },
  setup() {
    const videoVisible = ref(false);
    const videoSection = ref(null);

    onMounted(() => {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          videoVisible.value = true;
          observer.disconnect(); // Detenemos el observador después de la carga
        }
      });
      if (videoSection.value) observer.observe(videoSection.value);
    });

    return { videoVisible, videoSection };
  },
};
</script>

<style scoped>
.video-container {
  margin-top:0em;
}
video {
  width: 100%;
  max-height: 500px;

  object-fit: contain; /* Ajusta el video al contenedor */
}
</style>
