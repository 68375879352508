<template>
  <v-container class="wrapper">
    <div style="text-align: center;">
      <div class="text-h4 mb-6 gray">Training Course Details</div>
    </div>
    <center v-if="!selectedCourse">
      <h2 class="mx-auto red--text" > Course {{getCourseId()}} NOT FOUND  </h2>
      <h3 v-if="response_error"> {{response_error}}</h3>
      <br><br><br>
    </center>
    <v-card class="mx-auto" v-if="selectedCourse">
      <v-card-title class="card-title">
        {{
          selectedCourse.title.toLowerCase().charAt(0).toUpperCase() +
          selectedCourse.title.toLowerCase().slice(1)
        }}
      </v-card-title>
      <v-img
        :src="
          VUE_APP_API_ENDPOINT +
          'images/courses/' +
          (selectedCourse.image || 'default.jpg')
        "
        height="500px"
        contain
        class="mt-5 mb-5"
      ></v-img>
      <v-card-subtitle>
        <p class="bold" v-html="selectedCourse.description">

        </p>
      </v-card-subtitle>

      <br />
      <v-divider></v-divider>
      <br />
      <v-card-text >
        <v-row >
          <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <course-detail-view :selected-course="selectedCourse"/>
            <br />
            <br />

            <v-row v-if="!visitor()">
              <v-col>
                <v-btn block class="button-gradient-half" @click="addFav" v-if="this.selectedCourse.fav_id == null"
                  >Add Course To Favorites</v-btn>
                <v-btn block class="button-gradient-error-half" @click="delFav" v-else
                >Remove From Favorites</v-btn>
              </v-col>

            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-snackbar v-model="snackbar_show" :color="snackbar_color" top>
      <v-icon>{{ snackbar_icon }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>


<script>
import CourseDetailView from "@/pages/app/common/CourseDetailView";
export default {
  name: "Course",

  components: {CourseDetailView},
  data: () => ({
    selectedCourse: null,
    response_error:null,
    lang: null,
    // Snackbar
    snackbar_show: false,
    snackbar_color: null,
    snackbar_text: null,
    snackbar_icon: null,
    // env
    VUE_APP_API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,
  }),

  async mounted() {
    try {
      await this.getData();
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        this.$router.push("/auth-error");
      }
    }
  },

  methods: {
    visitor(){
      return true//!this.$cookie.get("jwt_token")
    },
    getCourseId(){
      if(this.$route && this.$route.params && this.$route.params.pathMatch)
        return ""+this.$route.params.pathMatch
      return "NO ID";
    },
    async getData() {
      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT +
          "visitor/courses/" +
          this.$route.params.pathMatch,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      if (response.success) {
        this.selectedCourse = response.result;
        this.lang = this.selectedCourse.language.join()
      } else {
        this.response_error = response.message;
        throw new Error(response.message);
      }
    },
    // Insert a course in favourites
    async addFav() {
      let responseInsert;
      try {
        responseInsert = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/add-fav-courses",
          {
            method: "POST",
            body: JSON.stringify({
              course_id: this.selectedCourse.course_id,
            }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        responseInsert = await responseInsert.json();
        if (responseInsert.success) {
          this.snackbar_color = "success";
          this.snackbar_text = "Success: course added to favorites";
          this.snackbar_icon = "mdi-success-circle";
          this.snackbar_show = true;

          this.selectedCourse.fav_id = -1;
        } else {
          throw new Error(responseInsert.message);
        }
      } catch (error) {
        if (error.message === "alreadySaved") {
          // Warning message
          this.snackbar_color = "orange";
          this.snackbar_text = "The course was already saved as favorite";
          this.snackbar_icon = "mdi-alert-outline";
        } else {
          // Error message
          this.snackbar_color = "error";
          this.snackbar_text = error.message;
          this.snackbar_icon = "mdi-alert-circle";
        }
        this.snackbar_show = true;
      }
    },
    // Delete a course in favourites
    async delFav() {
      let responseDelete;
      try {
        responseDelete = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/delete-fav-courses",
          {
            method: "POST",
            body: JSON.stringify({
              course_id: this.selectedCourse.course_id,
            }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        responseDelete = await responseDelete.json();
        if (responseDelete.success) {
          this.snackbar_color = "success";
          this.snackbar_text = "Success: course removed from favorites";
          this.snackbar_icon = "mdi-success-circle";
          this.snackbar_show = true;

          this.selectedCourse.fav_id = null;
        } else {
          throw new Error(responseDelete.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = error.message;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}

.no-link {
  text-decoration: none;
  color: black;
}
.card-title {
  word-break: normal;
  margin-bottom: 20px;
  font-weight: bold;
}
.bold {
  font-weight: bold;
}
.gray {
  color: #283444;
}

.button-gradient-half:hover {
  background-position: right center;
}

.button-gradient-half {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 100%);
}

.button-gradient-error-half:hover {
  background-position: right center;
}
.button-gradient-error-half {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  background-image: linear-gradient(to right, #ec6608 0%, #f2e500 150%);
}
</style>
