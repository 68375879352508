<template>
  <v-container class="wrapper">
    <div class="text-center">
      <h1 class="text-h4 mt-2">
        Look for Candidates
      </h1>
    </div>
    <v-form>
      <v-container>
        <v-row>
          <v-col>
            <v-combobox
              v-model="skillsSelectedAutocomplete"
              :loading="skillsAutocompleteLoading"
              :items="skillsAutocompleteSearch"
              :search-input.sync="skillAutocompleteSearch"
              hide-no-data
              hide-details
              label="Search Skills (ESCO classification and others included by the user)"
              placeholder="Start typing"
              chips
              clearable
              deletable-chips
              multiple
              solo
            >
            </v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="competencesSelectedAutocomplete"
              :loading="competencesAutocompleteLoading"
              :items="competencesAutocompleteSearch"
              :search-input.sync="competenceAutocompleteSearch"
              hide-no-data
              hide-details
              label="Search Competences"
              placeholder="Start typing"
              chips
              clearable
              deletable-chips
              multiple
              solo
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="experiencesSelectedAutocomplete"
              :loading="experiencesAutocompleteLoading"
              :items="experiencesAutocompleteSearch"
              :search-input.sync="experienceAutocompleteSearch"
              hide-no-data
              hide-details
              placeholder="Start typing"
              label="Search Professional Experience"
              chips
              clearable
              deletable-chips
              multiple
              solo
            >
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-autocomplete
              v-model="coursesSelectedAutocomplete"
              :loading="coursesAutocompleteLoading"
              :items="coursesAutocompleteSearch"
              :search-input.sync="courseAutocompleteSearch"
              hide-no-data
              hide-details
              placeholder="Start typing"
              label="Search by favorited courses"
              chips
              clearable
              deletable-chips
              multiple
              solo
            >
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn block class="button-gradient" @click="searchCandidates()">
              Search Candidates
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="persons">
          <v-col
            cols="6"
            xl="6"
            lg="6"
            md="6"
            sm="12"
            xs="12"
            v-for="(person,index) in persons"
            :key="`person_${index}`"
          >
            <v-card min-height="10" max-width="830">
              <div class="pa-2">
                <v-avatar size="100" class="display-inline">
                  <v-img
                    :src="
                      VUE_APP_API_ENDPOINT +
                      'images/profiles/' +
                      (person.image || 'default.jpg')
                    "
                  >
                  </v-img>
                </v-avatar>

                <v-card-title class="ma-2 pa-0">{{
                  person.full_name
                }}</v-card-title>
                <v-card-title class="ma-2 pa-0"
                  ><a :href="'mailto:' + person.email">{{
                    person.email
                  }}</a></v-card-title>
                <v-spacer></v-spacer>

                <v-btn
                  block
                  class="button-gradient-half"
                  @click.stop="(dialog = true), (selectedPerson = person)">
                  Details
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-dialog v-model="dialog" max-width="600">
            <v-card>
              <v-card-title class="text-h5">
                Details for {{ selectedPerson.full_name }}
              </v-card-title>

              <v-card-text>
                <p v-if="selectedPerson.cv">
                  CV: <a :href="VUE_APP_API_ENDPOINT + 'cvs/' + selectedPerson.cv" target="blank">{{ selectedPerson.cv }}</a>
                </p>
                <p v-if="selectedPerson.description">
                  Description: {{ selectedPerson.description }}
                </p>
                <p v-if="selectedPerson.country">
                  Country: {{ selectedPerson.country }}
                </p>
                <p v-if="selectedPerson.state">State: {{ selectedPerson.state }}</p>
                <p v-if="selectedPerson.city">City: {{ selectedPerson.city }}</p>
                <p v-if="selectedPerson.phone">Phone: {{ selectedPerson.phone }}</p>
                <p v-if="selectedPerson.email">Email: <a :href='"mailto: " + selectedPerson.email'>{{ selectedPerson.email }}</a></p>
                <p v-if="selectedPerson.userProfile">
                  Skills: {{ selectedPerson.userProfile.skills.join(", ") }}
                </p>
                <p v-if="selectedPerson.userProfile">
                  Competences: {{ selectedPerson.userProfile.competences.join(", ") }}
                </p>
                <p v-if="selectedPerson.userProfile">
                  Experiences: {{ selectedPerson.userProfile.experiences.join(", ") }}
                </p>
                <div v-if="selectedPerson.userProfile">
                  Educations:
                  <div
                    v-for="education in selectedPerson.userProfile.educations"
                    :key="education.users_educations_id"
                  >
                    &nbsp;Name: {{ education.name }}<br />
                    &nbsp;Level: {{ education.level }}<br />
                    &nbsp;University {{ education.university }}<br />
                    &nbsp;Start Year: {{ education.start_year }}<br />
                    &nbsp;End year: {{ education.end_year }}
                  </div>
                  <br />
                </div>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="addToFav()">
                  Add to favorites
                </v-btn>

                <v-btn color="green darken-1" text @click="dialog = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </v-form>
    <v-snackbar v-model="snackbar_show" :color="snackbar_color" :timeout="timeout" top>
      <v-icon>{{ snackbar_icon }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "SearchCandidates",
  data() {
    return {
      persons: [],
      selectedPerson: {},
      // Skills
      skillsAutocompleteLoading: false, // Loading animation autocomplete
      skillsAutocompleteSearch: [], // Results of the skills search autocomplete
      skillAutocompleteSearch: null, // Autocomplete search field
      skillsSelectedAutocomplete: [], // Autocomplete skills selected
      // Competences
      competencesAutocompleteLoading: false, // Loading animation autocomplete
      competencesAutocompleteSearch: [], // Results of the competences search autocomplete
      competenceAutocompleteSearch: null, // Autocomplete search field
      competencesSelectedAutocomplete: [], // Autocomplete competences selected
      // Experiences
      experiencesAutocompleteLoading: false, // Loading animation autocomplete
      experiencesAutocompleteSearch: [], // Results of the experiences search autocomplete
      experienceAutocompleteSearch: null, // Autocomplete search field
      experiencesSelectedAutocomplete: [], // Autocomplete experiences selected

      // COURSES
      coursesAutocompleteLoading: false, // Loading animation autocomplete
      coursesAutocompleteSearch: [], // Results of the experiences search autocomplete
      courseAutocompleteSearch: null, // Autocomplete search field
      coursesSelectedAutocomplete: [], // Autocomplete experiences selected


      // Snackbar
      snackbar_show: false,
      snackbar_color: null,
      snackbar_text: null,
      snackbar_icon: null,
      timeout: 1500,
      // User details dialog
      dialog: false,
      // env
      VUE_APP_API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,
    };
  },
  watch: {
    skillAutocompleteSearch(name) {
      name && name !== this.select && this.searchSkillsByName(name);
    },
    competenceAutocompleteSearch(name) {
      name && name !== this.select && this.searchCompetencesByName(name);
    },
    experienceAutocompleteSearch(name) {
      name && name !== this.select && this.searchExperiencesByName(name);
    },
    courseAutocompleteSearch(name) {
      name && name !== this.select && this.searchCoursesByName(name);
    },
  },
  mounted() {
    // TODO
    console.log(this.persons)
  },
  methods: {
    async addToFav(){
        //console.log(this.selectedPerson)
        let result
        try {
          //console.log("EL ID ES: " + JSON.stringify(this.selectedPerson))
          result = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/addCandidates",
          {
            method: "POST",
            body: JSON.stringify({candidateId: this.selectedPerson.user_id}),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        console.log(result)
        if (result.status == 200){
            this.snackbar_color = "success";
            this.snackbar_text = "Candidated added to favorites";
            this.snackbar_icon = "mdi-alert-circle";
            this.snackbar_show = true;
        }
        return this.persons;
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = error.message;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },

    async searchCandidates() {
      let personsFound;
      try {
        personsFound = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/search-candidates",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
            body: JSON.stringify({
              skills: this.skillsSelectedAutocomplete,
              competences: this.competencesSelectedAutocomplete,
              experiences: this.experiencesSelectedAutocomplete,
              courses: this.coursesSelectedAutocomplete
            }),
          }
        );
        personsFound = await personsFound.json();
        console.log(personsFound)
        this.persons = personsFound.result;
        if (this.persons.length === 0) {
          this.snackbar_text = "No candidates found";
          this.snackbar_icon = "mdi-alert-circle";
          this.snackbar_show = true;
        }
        return this.persons;
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = error.message;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },
    async searchSkillsByName(name) {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/search-skills-by-name",
          {
            method: "POST",
            body: JSON.stringify({ name: name }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
      } catch (error) {
        console.log(error.message);
      }
      this.skillsAutocompleteLoading = false;
      this.skillsAutocompleteSearch = response.result;
    },
    async searchCompetencesByName(name) {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT +
            "common/search-competences-by-name",
          {
            method: "POST",
            body: JSON.stringify({ name: name }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
      } catch (error) {
        console.log(error.message);
      }
      this.competencesAutocompleteLoading = false;
      this.competencesAutocompleteSearch = response.result;
    },
    async searchExperiencesByName(name) {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT +
            "common/search-experiences-by-name",
          {
            method: "POST",
            body: JSON.stringify({ name: name }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
      } catch (error) {
        console.log(error.message);
      }
      this.experiencesAutocompleteLoading = false;
      this.experiencesAutocompleteSearch = response.result;
    },

    async searchCoursesByName(name) {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT +
          "common/search-courses-by-name",
          {
            method: "POST",
            body: JSON.stringify({ name: name }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
      } catch (error) {
        console.log(error.message);
      }
      this.coursesAutocompleteLoading = false;
      this.coursesAutocompleteSearch = response.result;
    },
  },
};
</script>

<style scoped>

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}
.display-inline {
  display: inline-block;
}
.button-gradient:hover {
  background-position: right center;
}

.button-gradient {
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(
    to right,
    #047ec3 0%,
    #52ae32 51%,
    #047ec3 100%
  );
}

.button-gradient-half {
  text-transform: uppercase;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 100%);
}
</style>
