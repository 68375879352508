<template>
  <div>
    <Toolbar />
    <Hero />
    <Logos title="Partners" :logos="logos" />
    <Stats class="mb-4"/>

    <Feature11 class="pt-4"/>
    <CoursesJobsTease class="pb-4"/>
    <Feature8 />
    <Footer />
  </div>
</template>

<script>
import Toolbar from "@/components/carbon-ui/toolbar/Toolbar2.vue";
import Hero from "@/components/carbon-ui/hero/Hero2.vue";
import Logos from "@/components/carbon-ui/logos/Logos2.vue";
import Stats from "@/components/carbon-ui/stats/Stats2.vue"
import Feature11 from '@/components/carbon-ui/feature/Feature11.vue'
import Feature8 from "@/components/carbon-ui/feature/Feature8.vue";
import Footer from "@/components/carbon-ui/footer/Footer4.vue";
import CoursesJobsTease from "@/components/carbon-ui/feature/CoursesJobsTease.vue";

export default {
  components: {

    CoursesJobsTease,
    Toolbar,
    Hero,
    Logos,
    Stats,
    Feature11,
    Feature8,
    Footer,
  },
  data() {
    return {
      logos: [
        {
          image: require("@/assets/images/logos/cetem-logo.png"),
          link: "http://www.cetem.es",
        },
        {
          image: require("@/assets/images/logos/upct-logo.png"),
          link: "https://upct.es",
        },
        {
          image: require("@/assets/images/logos/sggw-logo.svg"),
          link: "https://www.sggw.edu.pl",
        },
        {
          image: require("@/assets/images/logos/sit-poland-logo.png"),
          link: "https://www.sitlid.pl/en/"
        },
        {
          image: require("@/assets/images/logos/kit-logo.svg"),
          link: "https://www.kit.edu",
        },
        {
          image: require("@/assets/images/logos/cpi-logo.png"),
          link: "https://cpi.si"
        },
        {
          image: require("@/assets/images/logos/bf-logo.svg"),
          link: "https://www.bf.uni-lj.si"
        },
        {
          image: require("@/assets/images/logos/eurada-belgium.png"),
          link:"https://eurada.org/"
        },
        {
          image: require("@/assets/images/logos/innova-wood-belgium.png"),
          link:"http://www.innovawood.com/"
        },
        {
          image: require("@/assets/images/logos/vdab-belgium.png"),
          link:"https://www.vdab.be/"
        },
        {
          image: require("@/assets/images/logos/ceipes-italy.png"),
          link:"https://ceipes.org/"
        },
        {
          image: require("@/assets/images/logos/FCBAquadri1ligne-logo.png"),
          link:"https://www.fcba.fr/"
        },
        {
          image: require("@/assets/images/logos/sef-logo.png"),
          link:"http://sefcarm.es/web/pagina?IDCONTENIDO=8&IDTIPO=180"
        },
        /*
        {
          image: require('@/assets/images/logos/cenfim-logo.png'),
          link: "https://www.cenfim.org"
        },
         */
        {
          image: require('@/assets/images/logos/ambit_logo.png'),
          link: "https://ambitcluster.org"
        },
        {
          image: require('@/assets/images/logos/fla-logo.jpg'),
          link: "https://www.federlegnoarredo.it"
        },
        {
           image: require('@/assets/images/logos/hdh-logo.png'),
           link: "https://www.holzindustrie.de"
        },
        {
          image: require('@/assets/images/logos/hmc-logo.png'),
          link: "https://hmcollege.nl"
        },
        {
          image: require('@/assets/images/logos/aslam-logo-v2.png'),
          link: "http://www.aslam.it"
        },
        {
          image: require('@/assets/images/logos/amuebla-logo.png'),
          link: "http://www.amueblacooperacion.es"
        },
        {
          image: require('@/assets/images/logos/lg-logo.png'),
          link: "https://www.lesarski-grozd.si"
        },
        {
          image: require('@/assets/images/logos/woodwize-logo.png'),
          link: "https://www.woodwize.be"
        },
        {
          image: require('@/assets/images/logos/sint_paulusschool.png'),
          link: "https://www.vtiwaregem.eu/"
        },
      ]
    };
  },
};
</script>
<style scoped>

video {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ajusta el video al contenedor */
}


.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Mantén otros elementos por encima */
}
</style>
