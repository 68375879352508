<template>
  <v-container class="wrapper">
    <v-row justify="center">
      <v-col>
        <v-card class="card-form">
          <v-card-title class="background-gradient white--text headline">
            <v-icon class="white-icon">mdi-pencil-plus</v-icon>
            <span class="headline ml-3">Create Job Offers</span>
            <v-spacer></v-spacer>
            <v-btn
              small
              text
              elevation="0"
              @click="
                jobs.unshift({
                  name: 'New Job Offer',
                })
              "
            >
              <!--   type of contract, duration)  -->
              <v-icon class="white-icon"> mdi-plus </v-icon>
            </v-btn>
          </v-card-title>
          <v-expansion-panels>
            <v-expansion-panel v-for="(job, index) in jobs" :key="job.job_id">
              <v-expansion-panel-header class="font-weight-medium">
                #{{ jobs[index].job_id }} - {{ jobs[index].name }}
              </v-expansion-panel-header>
              <!-- :src="(job.image || VUE_APP_API_ENDPOINT +
                      'images/jobs/' + 'default.jpg')" -->
                      <!-- :src="loadUserPic(jobs[index])" -->
              <v-expansion-panel-content>

                <v-card-text class="pt-1 pl-8">
                  <div style="display: flex; margin-bottom: 18px">
                    <div class="image-container">
                      <v-img
                        v-model="imagePic"
                        class="display-inline uploaded-image"
                        width="400px"
                        height="300px"
                        :src="(job.image || VUE_APP_API_ENDPOINT +
                          'images/jobs/' + 'default.jpg')"
                        @click="openImageFileDialog(index)"
                      >
                      </v-img>
                    </div>
                    <div>
                      <a v-if="jobs[index].image" style="vertical-align: top; margin-left: 8px !important;"
                         v-on:click="removeImage(jobs[index].job_id,index)"> X </a>
                      <v-file-input
                        :id = "'image-input-'+index"
                        :rules="imageRules"
                        accept="image/png, image/jpeg, image/bmp"
                        placeholder="Upload job photo (500x300px) **Optional"
                        v-model="imageData.image"
                        prepend-icon="mdi-camera"
                        class="input"
                        chips
                        show-size
                        @change="uploadJobImage(jobs[index].job_id,index)"
                      ></v-file-input>
                      <!--
                      <v-btn
                        v-if="jobs[index].image"
                        block
                        class="button-gradient-half"
                        @click.stop="removeImage(jobs[index].job_id,index)">
                        Eliminar imagen
                      </v-btn>
                      -->
                    </div>

                  </div>
                  <v-text-field
                    placeholder="Job name"
                    label="Title *"
                    v-model="jobs[index].name"
                    counter
                    maxlength="60"
                  />
                  <v-text-field
                    placeholder="A brief description of the job"
                    label="Description"
                    v-model="jobs[index].description"
                    counter
                    maxlength="120"
                  />
                  <v-combobox
                    v-model="jobs[index].sector"
                    :items="sectors"
                    label="Professional Sector (ESCO classification and others included by the user)*"
                    placeholder="Start typing"
                    clearable
                    chips
                    multiple
                    deletable-chips
                    flat
                  >

                  </v-combobox>

                  <!-- v-model="jobs[index].essentialSkillsSelectedAutocomplete" -->
                  <v-combobox
                    v-model="jobs[index].essentialSkillsSelected"
                    :loading="essentialSkillsAutocompleteLoading"
                    :items="essentialSkillsAutocompleteSearch"
                    :search-input.sync="essentialSkillAutocompleteSearch"
                    label="Demanded Skills (ESCO classification and others included by the user) *"
                    placeholder="Start typing"
                    clearable
                    chips
                    multiple
                    deletable-chips
                    flat
                  >
                  </v-combobox>
                  <!-- <v-chip-group show-arrows column active-class="primary--text">
                    <v-chip
                      color="primary"
                      close
                      v-for="tag in jobs[index].essentialSkillsSelected"
                      :key="tag"
                      @click:close="
                        deleteFromList(tag, index, 'essential skill')
                      "
                    >
                      {{ tag }}
                    </v-chip>
                  </v-chip-group> -->

                  <br />

                  <v-combobox
                    v-model="jobs[index].optionalSkillsSelected"
                    :loading="optionalSkillsAutocompleteLoading"
                    :items="optionalSkillsAutocompleteSearch"
                    :search-input.sync="optionalSkillAutocompleteSearch"
                    label="Optional Skills (ESCO classification and others included by the user)"
                    placeholder="Start typing"
                    clearable
                    chips
                    multiple
                    deletable-chips
                    flat
                  >
                  </v-combobox>
                  <!-- <v-chip-group show-arrows column active-class="primary--text">
                    <v-chip
                      color="primary"
                      close
                      v-for="tag in jobs[index].optionalSkillsSelected"
                      :key="tag"
                      @click:close="
                        deleteFromList(tag, index, 'optional skill')
                      "
                    >
                      {{ tag }}
                    </v-chip>
                  </v-chip-group> -->

                  <v-combobox
                    v-model="jobs[index].experiencesSelected"
                    :loading="experiencesAutocompleteLoading"
                    :items="experiencesAutocompleteSearch"
                    :search-input.sync="experienceAutocompleteSearch"
                    label="Job Requeriments *"
                    placeholder="Start typing"
                    clearable
                    chips
                    multiple
                    deletable-chips
                    flat
                  >
                  </v-combobox>
                  <!-- <v-chip-group show-arrows column active-class="primary--text">
                    <v-chip
                      color="primary"
                      close
                      v-for="tag in jobs[index].experiencesSelected"
                      :key="tag"
                      @click:close="deleteFromList(tag, index, 'experience')"
                    >
                      {{ tag }}
                    </v-chip>
                  </v-chip-group> -->

                  <v-select
                    v-model="jobs[index].languages"
                    :items="languages"
                    hide-no-data
                    hide-details
                    label="Languages used in workspace *"
                    placeholder="Select a language"
                    multiple
                    flat
                  />
                  <!--
                  <v-autocomplete
                    v-model="jobs[index].language"
                    :items="languages"
                    clearable
                    label="Language *"
                    flat
                  >

                  </v-autocomplete>
                  -->
                  <br/>
                  <!--
                  <v-select
                    v-model="jobs[index].contract"
                    :items="contracts"
                    label="Type of contract *"
                  />
                  -->
                  <v-select
                    v-model="jobs[index].contract_types"
                    :items="contracts"
                    hide-no-data
                    hide-details
                    label="Type of contract *"
                    placeholder="Select a language"
                    multiple
                    flat
                  />

                  <!--
                  <v-text-field
                    v-if="jobs[index].duration != 'Undefined'"
                    v-model="jobs[index].duration"
                    suffix="months"
                    label="Duration in months"
                    placeholder="Start typing"
                    type="number"
                  />
                  -->
                  <v-text-field

                    v-model="jobs[index].place"
                    label="Job place"
                    placeholder="Place"
                  />

                  <v-container>
                    <v-row>

                      <v-col cols="12" lg="6">

                        <v-menu
                          v-model="jobs[index].init_date_modal"
                          :close-on-content-click="true"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">

                            <v-text-field
                              v-model="jobs[index].date_dialog_data_start_formatted"
                              label="Start date of job position"
                              hint="DD/MM/YYYY format"
                              persistent-hint
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              clearable
                              @click:clear="jobs[index].init_date = null"
                            ></v-text-field>

                          </template>
                          <v-date-picker v-model="jobs[index].date_dialog_data_start" no-title @input="jobs[index].init_date_modal = false"
                                         @change="
                              jobs[index].date_dialog_data_start_formatted = formatDate(jobs[index].date_dialog_data_start);
                              jobs[index].init_date = jobs[index].date_dialog_data_start;
                            ">

                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-menu
                          v-model="jobs[index].end_date_modal"
                          :close-on-content-click="true"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="jobs[index].date_dialog_data_end_formatted"
                              label="End date of job position"
                              hint="DD/MM/YYYY format"
                              persistent-hint
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              clearable
                              @click:clear="jobs[index].end_date = null"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="jobs[index].date_dialog_data_end" no-title @input="jobs[index].end_date_modal = false"
                                         @change="

                              jobs[index].date_dialog_data_end_formatted = formatDate(jobs[index].date_dialog_data_end);
                              jobs[index].end_date = jobs[index].date_dialog_data_end;

                            "
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-container>



                  <v-text-field
                    v-model="jobs[index].contact_details"
                    label="Contact data *"
                    placeholder="Start typing"
                  />
                  <v-text-field
                    v-model="jobs[index].salary"
                    suffix="€"
                    label="Gross salary"
                    placeholder="Start typing"
                    type="number"
                  />
                  <v-checkbox
                    v-model="jobs[index].receive_candidates_recommendations"
                    label="Receive recommendations about candidates"
                  ></v-checkbox>

                  <v-card class="mt-3 mb-3" >

                    <v-container>
                    <v-row>
                      <v-checkbox
                        class="ms-2"
                        v-model="jobs[index].expired"
                        label="Expire job "
                      ></v-checkbox>
                      <v-checkbox
                        v-if="!jobs[index].expired"
                        class="ms-2"
                        v-model="jobs[index].manual_publish"
                        label="Publish offer (disable this option if you want to schedule the job publication)"
                      ></v-checkbox>

                    </v-row>
                    <!--
                    <v-row v-if="jobs[index].manual_publish">
                      <p class="caption text-decoration-none secondary--text ms-2" >
                        * You have to disable this option for been able to schedule the job publication
                      </p>
                    </v-row>
                    -->
                    <v-row v-if="!jobs[index].manual_publish && !jobs[index].expired">
                      <v-col cols="12" lg="6">
                        <v-menu
                          v-model="jobs[index].publish_init_date_modal"
                          :close-on-content-click="true"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">

                            <v-text-field
                              v-model="jobs[index].publish_date_dialog_data_start_formatted"
                              label="Publish after date"
                              hint="DD/MM/YYYY format"
                              persistent-hint
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :disabled="jobs[index].manual_publish"
                              clearable
                              @click:clear="jobs[index].publish_ini = null"
                            ></v-text-field>

                          </template>
                          <v-date-picker v-model="jobs[index].publish_date_dialog_data_start" no-title @input="jobs[index].publish_init_date_modal = false"
                                         @change="
                              jobs[index].publish_date_dialog_data_start_formatted = formatDate(jobs[index].publish_date_dialog_data_start);
                              jobs[index].publish_ini = jobs[index].publish_date_dialog_data_start;
                            ">

                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-menu
                          v-model="jobs[index].publish_end_date_modal"
                          :close-on-content-click="true"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">

                            <v-text-field
                              v-model="jobs[index].publish_date_dialog_data_end_formatted"
                              label="Publish end date"
                              hint="DD/MM/YYYY format"
                              persistent-hint
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :disabled="jobs[index].manual_publish"
                              clearable
                              @click:clear="jobs[index].publish_end = null"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="jobs[index].publish_date_dialog_data_end" no-title @input="jobs[index].publish_end_date_modal = false"
                                         @change="

                              jobs[index].publish_date_dialog_data_end_formatted = formatDate(jobs[index].publish_date_dialog_data_end);
                              jobs[index].publish_end = jobs[index].publish_date_dialog_data_end;

                            "
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <p class="caption text-decoration-none secondary--text ms-2 me-2" v-if="!jobs[index].manual_publish && ! jobs[index].expired">
                        * You can schedule the publication of the offer by setting the start and end publication dates. If neither date is set and the "Publish Offer" option is not enabled, the offer will never be published.
                      </p>
                    </v-row>
                  </v-container>
                  </v-card>

                  <p class="caption text-decoration-none secondary--text">
                    * Required field
                  </p>


                  <resource-editor :entity-id="jobs[index].job_id" entity-table="jobs" mode="EDITOR"></resource-editor>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="
                        //jobs.splice(index, 1);
                        jobs[index].job_id ?
                          deleteJob(jobs[index]) : jobs.splice(index, 1)
                      "
                    >
                      <div class="text-gradient-half" v-if="jobs[index].job_id">Delete</div>
                      <div class="text-gradient-half" v-else>Clear</div>
                    </v-btn>
                    <v-btn
                      color="primary"
                      text
                      @click="
                        updateJob(jobs[index],jobIndex);
                      "
                    >
                      <div class="text-gradient-half" v-if="jobs[index].job_id">Update</div>
                      <div class="text-gradient-half" v-else>Create</div>
                    </v-btn>
                  </v-card-actions>
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar_show" :color="snackbar_color" top>
      <v-icon>{{ snackbar_icon }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>
<script>
import ResourceEditor from "@/pages/app/common/ResourceEditor.vue";

export default {
  name: "OfferJobs",
  components: {ResourceEditor},
  data: () => ({
    userId: 0,
    skills: [],
    skillsName: [],
    contracts: [],
    sectors: [],
    experiencesName: [],
    langJobs:[],
    languages: [],
    imagePic: [],
    experiences: [],
    // Jobs
    jobs: [],
    // Images
    imageRules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Avatar size should be less than 2 MB!",
    ],
    imageData: {
      image: null,
    },
    // rules: {
    //   required: value => !!value || 'Required.',
    //   counter: value => value.length <= value.max_length || 'Max ' + value.max_length+ ' characters',
    // },
    // Skills
    essentialSkillsSelected: [], // Skills saved by the user
    essentialSkillsAutocompleteLoading: false, // Loading animation autocomplete
    essentialSkillsAutocompleteSearch: [], // Results of the skills search autocomplete
    essentialSkillAutocompleteSearch: null, // Autocomplete search field
    essentialSkillsSelectedAutocomplete: [], // Autocomplete skills selected
    optionalSkillsSelected: [], // Skills saved by the user
    optionalSkillsAutocompleteLoading: false, // Loading animation autocomplete
    optionalSkillsAutocompleteSearch: [], // Results of the skills search autocomplete
    optionalSkillAutocompleteSearch: null, // Autocomplete search field
    optionalSkillsSelectedAutocomplete: [], // Autocomplete skills selected
    // Experiences
    experiencesSelected: [], // Experiences saved by the user
    experiencesAutocompleteLoading: false, // Loading animation autocomplete
    experiencesAutocompleteSearch: [], // Results of the experiences search autocomplete
    experienceAutocompleteSearch: null, // Autocomplete search field
    experiencesSelectedAutocomplete: [], // Autocomplete experiences selected
    // Snackbar
    snackbar_show: false,
    snackbar_color: null,
    snackbar_text: null,
    snackbar_icon: null,
    // env
    VUE_APP_API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,
  }),
  watch: {
    essentialSkillAutocompleteSearch(name) {
      name &&
        name !== this.select &&
        this.searchSkillsByName(name, "essential");
    },
    optionalSkillAutocompleteSearch(name) {
      name && name !== this.select && this.searchSkillsByName(name, "optional");
    },
    experienceAutocompleteSearch(name) {
      name && name !== this.select && this.searchExperiencesByName(name);
    },
  },
  async mounted() {
    try {
      await this.getData();
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        this.$router.push("/auth-error");
      }
    }
  },
  methods: {
    async getData() {
      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "company/offer-jobs",
        {
          headers: {
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      //console.log(response.result)
      if (response.success) {
        this.languages = response.result.languages;
        this.sectors = response.result.sectors;
        this.contracts = response.result.contracts;
        this.jobs = response.result.jobs;
        //console.log(JSON.stringify(this.jobs))
        for (let i = 0; i<this.jobs.length; i++){
          this.getUserPic(this.jobs[i])

          this.jobs[i].experiencesSelected = JSON.parse(this.jobs[i].job_requirements)

          this.langJobs.length = 0

          this.jobs[i].contract_types = this.jobs[i].contract_types ? JSON.parse(this.jobs[i].contract_types) : []
          if(this.jobs[i].contract_types.length == 0 && this.jobs[i].contract){
            this.jobs[i].contract_types.push(this.jobs[i].contract)
          }

          this.jobs[i].languages = this.jobs[i].languages ? JSON.parse(this.jobs[i].languages) : []
          this.jobs[i].init_date_modal = false
          this.jobs[i].end_date_modal = false
          this.jobs[i].publis_init_date_modal = false
          this.jobs[i].publis_end_date_modal = false

          if(this.jobs[i].init_date){
            //console.log("test",this.formatDateToYYYYMMDD(new Date(course.init_date)));
            this.jobs[i].init_date = this.formatDateToYYYYMMDD(new Date(this.jobs[i].init_date))
            this.jobs[i].init_date = this.jobs[i].init_date.substring(0,10)
            this.jobs[i].date_dialog_data_start = this.jobs[i].init_date
            this.jobs[i].date_dialog_data_start_formatted = this.formatDate(this.jobs[i].init_date)
          }

          if(this.jobs[i].end_date){
            this.jobs[i].end_date = this.formatDateToYYYYMMDD(new Date(this.jobs[i].end_date))
            this.jobs[i].end_date =  this.jobs[i].end_date.substring(0,10)
            this.jobs[i].date_dialog_data_end = this.jobs[i].end_date
            this.jobs[i].date_dialog_data_end_formatted = this.formatDate(this.jobs[i].end_date)
          }

          this.jobs[i].publis_init_date_modal = false
          this.jobs[i].publis_end_date_modal = false

          if(this.jobs[i].publish_ini){
            //console.log("test",this.formatDateToYYYYMMDD(new Date(course.init_date)));
            this.jobs[i].publish_ini = this.formatDateToYYYYMMDD(new Date(this.jobs[i].publish_ini))
            this.jobs[i].publish_ini = this.jobs[i].publish_ini.substring(0,10)
            this.jobs[i].publish_date_dialog_data_start = this.jobs[i].publish_ini
            this.jobs[i].publish_date_dialog_data_start_formatted = this.formatDate(this.jobs[i].publish_ini)
          }

          if(this.jobs[i].publish_end){
            this.jobs[i].publish_end = this.formatDateToYYYYMMDD(new Date(this.jobs[i].publish_end))
            this.jobs[i].publish_end =  this.jobs[i].publish_end.substring(0,10)
            this.jobs[i].publish_date_dialog_data_end = this.jobs[i].publish_end
            this.jobs[i].publish_date_dialog_data_end_formatted = this.formatDate(this.jobs[i].publish_end)
          }
          if(this.jobs[i].manual_publish == 0)
            this.jobs[i].manual_publish = false

        }

      } else {
        throw new Error(response.message);
      }

    },
    async getUserPic(job){
      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "company/user-profile/",
        {
          headers: {
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      // console.log(response.result.personalInformation)
      // console.log(job.image  + "------" + response.result.personalInformation.image )
      if (response.success){
        if (!job.image || job.image == response.result.personalInformation.image ){
          if(response.result.personalInformation.image) {
            var userPic = process.env.VUE_APP_API_ENDPOINT + "images/profiles/" + response.result.personalInformation.image
            job.image = userPic
            console.log("LA IMAGEN recibida GETPIC ES: " + job.image)
          }
        }else{
          job.image =  process.env.VUE_APP_API_ENDPOINT + "images/jobs/" + job.image
          //this.imagePic.src = process.env.VUE_APP_API_ENDPOINT + "images/jobs/" + job.image
        }
      }
    },

     async loadUserPic(job){

      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "company/user-profile/",
        {
          headers: {
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );

      var respuesta = ""
      response = await response.json();
      if (response.success){
        if (!job.image || job.image == response.result.personalInformation.image){
          // var userPic = process.env.VUE_APP_API_ENDPOINT + "images/profiles/" + response.result.personalInformation.image
          //job.image = userPic
          var userPic =  response.result.personalInformation.image
          respuesta = userPic
          // console.log("LA IMAGEN recibida ES: " + job.image)
        }else{
          //job.image =  process.env.VUE_APP_API_ENDPOINT + "images/jobs/" + job.image
          // respuesta = process.env.VUE_APP_API_ENDPOINT + "images/jobs/" + job.image
          respuesta = job.image
          // console.log("LA IMAGEN recibida ES: " + job.image)
        }
      }

      return require(respuesta)
    },

    async searchSkillsByName(name, type) {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/search-skills-by-name",
          {
            method: "POST",
            body: JSON.stringify({ name: name }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
      } catch (error) {
        console.log(error.message);
      }
      if (type === "essential") {
        this.essentialSkillsAutocompleteLoading = false;
        this.essentialSkillsAutocompleteSearch = response.result;
      } else if (type === "optional") {
        this.optionalSkillsAutocompleteLoading = false;
        this.optionalSkillsAutocompleteSearch = response.result;
      }
    },


    async searchExperiencesByName(name) {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT +
            "common/search-experiences-by-name?level=3",
          {
            method: "POST",
            body: JSON.stringify({ name: name }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
      } catch (error) {
        console.log(error.message);
      }
      this.experiencesAutocompleteLoading = false;
      this.experiencesAutocompleteSearch = response.result;
    },

    async updateJobs_deprecated() {
      // console.log(JSON.stringify(this.jobs))
      try {
        let response;
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "company/update-jobs",
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
            body: JSON.stringify({
              jobs: this.jobs,
            }),
          }
        );
        response = await response.json();
        if (response.success) {
          // Info message
          this.snackbar_color = "success";
          this.snackbar_text = "Jobs updated";
          this.snackbar_icon = "mdi-check";
          this.snackbar_show = true;
          // Reload page data only if the response is successful
          await this.getData();
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = error.message;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },

    async updateJob(job,jobIndex) {
      let nuevo = job.job_id == undefined;

      console.log("job.languages",job.languages)
      if(job.languages.length > 0){
        const arrayStr = []
        job.languages.forEach(value => {
          console.log("job.languages.value",value)
          arrayStr.push(value)
        })
      }else{
        //job.languages = []
        this.snackbar_color = "error";
        this.snackbar_text = "Languages is mandatory";
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }

      console.log("job.contract_types",job.contract_types)
      if(job.contract_types.length > 0){
        const arrayStr = []
        job.contract_types.forEach(value => {
          console.log("job.contract_types.value",value)
          arrayStr.push(value)
        })
      }else{
        //job.languages = []
        this.snackbar_color = "error";
        this.snackbar_text = "Contract Type is mandatory";
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }

      console.log(JSON.stringify(job))

      try {
        let response;
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "company/update-job",
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
            body: JSON.stringify({
              jobs: job,
            }),
          }
        );
        response = await response.json();
        console.log("Update end",response)
        if (response.success) {
          // Info message
          this.snackbar_color = "success";
          this.snackbar_text = "Jobs updated";
          this.snackbar_icon = "mdi-check";
          this.snackbar_show = true;
          if(nuevo && this.imageData.image != null){
            console.log("Añadir foto")
            let id = response.result.job_id;
            await this.uploadJobImage(id,jobIndex,true);
          }else{
            // Reload page data only if the response is successful
            console.log("Refrescar  ")
            await this.getData();
          }

        } else {
          throw new Error(response.message);
        }

      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = error.message;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },

    async removeImage(job_id,jobIndex){
      this.imageData.image = null
      this.uploadJobImage(job_id,jobIndex)
    },
    async uploadJobImage(job_id,jobIndex,reload = false) {
      if(!job_id){
        console.log("NO EXISTE ID DEL JOB ES UNO NUEVO: ")
        const reader = new FileReader()

        reader.onload = (e) => {
          this.imagePic[jobIndex] = e.target.result
          this.jobs[jobIndex].image = e.target.result;
          this.imagePic = e.target.result;
          console.log(this.imagePic)
        }
        reader.readAsDataURL(this.imageData.image)
      }else {
        let response;
        //console.log("EL NOMBRE DE LA FOTO ES: " + this.imageData.image.name)
        try {
          //console.log("LAS IMAGENES SON: " + this.imageData.image.name)
          //if (this.imageData.image.name != null || this.imageData.image.name ) {
          console.log("uploadJobImage", job_id, jobIndex)
          let formData = new FormData();
          if (this.imageData.image != null) {
            formData.append(
              "image",
              this.imageData.image,
              this.imageData.image.name
            );
          } else {
            formData.append(
              "delete_image",
              true
            );
          }
          formData.append("job_id", job_id);
          // Remove the file from the browser
          this.imageData.image = null;
          response = await fetch(
            process.env.VUE_APP_API_ENDPOINT + "company/upload-job-image",
            {
              method: "POST",
              body: formData,
              // Weirdly, the auth-token is not sent as an auth-token header
              // We need to sent it with the cookies header (credentials: include)
              headers: {
                "Auth-Token": this.$cookie.get("jwt_token"),
              },
            }
          );
          response = await response.json();
          console.log(response)
          if (response.success || response.ok) {
            this.snackbar_color = "success";
            this.snackbar_text = "Image updated succesfully";
            this.snackbar_icon = "mdi-alert-circle";
            this.snackbar_show = true;

            console.log("Refrescar",reload)
            if(reload){
              this.getData();
            }else {
              if(response.result && response.result.filename) {
                console.log("response.result.filename", this.VUE_APP_API_ENDPOINT + 'images/jobs/' + response.result.filename)
                this.jobs[jobIndex].image = this.VUE_APP_API_ENDPOINT + 'images/jobs/' + response.result.filename;
                this.imagePic = this.VUE_APP_API_ENDPOINT + 'images/jobs/' + response.result.filename;
              }else{
                console.log("Foto eliminada")
                this.jobs[jobIndex].image = null;
              }
            }

            //this.imagePic = response.result;
          }
          //}
        } catch (error) {
          // The browser returns a CORS error but the request succeeds
          // This is a quick fix to avoid displaying a error message to
          // the user even if the request is successful
          if (response.success) return;
          this.snackbar_color = "error";
          this.snackbar_text = error;
          this.snackbar_icon = "mdi-alert-circle";
          this.snackbar_show = true;
        }
      }
    },
    deleteFromList(name, jobIndex, type) {
      if (type === "essential skill") {
        for (const i in this.jobs[jobIndex].essentialSkillsSelected) {
          if (this.jobs[jobIndex].essentialSkillsSelected[i] === name) {
            this.jobs[jobIndex].essentialSkillsSelected.splice(i, 1);
          }
        }
      } else if (type === "optional skill") {
        for (const i in this.jobs[jobIndex].optionalSkillsSelected) {
          if (this.jobs[jobIndex].optionalSkillsSelected[i] === name) {
            this.jobs[jobIndex].optionalSkillsSelected.splice(i, 1);
          }
        }
      } else if (type === "experience") {
        for (const i in this.jobs[jobIndex].experiencesSelected) {
          if (this.jobs[jobIndex].experiencesSelected[i] === name) {
            this.jobs[jobIndex].experiencesSelected.splice(i, 1);
          }
        }
      } else if (type === "sector") {
        for (const i in this.jobs[jobIndex].sectors) {
          if (this.jobs[jobIndex].sectors[i] === name) {
            this.jobs[jobIndex].sectors.splice(i, 1);
          }
        }
      }
    },
    formatDateToYYYYMMDD(date) {
      const anio = date.getFullYear();
      const mes = String(date.getMonth() + 1).padStart(2, "0");
      const dia = String(date.getDate()).padStart(2, "0");
      return `${anio}-${mes}-${dia}`;
    },
    formatDate(date) {
      console.log("formatDate",date)
      if (!date) return null
      if(date.length > 10){
        const [year, month, day] = date.substring(0,10).split(/[/-]/)

        console.log("formatDate",`${day}/${month}/${year}`)
        return `${day}/${month}/${year}`
      }else{
        const [year, month, day] = date.split(/[/-]/)

        console.log("formatDate",`${day}/${month}/${year}`)
        return `${day}/${month}/${year}`
      }

    },

    openImageFileDialog(index){
      if(document.getElementById('image-input-'+index))
        document.getElementById('image-input-'+index).click();
    },

    async deleteJob(job) {
      console.log("deleteJob",job)

      try {
        let response;
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "company/delete-job",
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
            body: JSON.stringify({
              job: job,
            }),
          }
        );
        response = await response.json();
        if (response.success) {
          // Info message
          this.snackbar_color = "success";
          this.snackbar_text = "Job deleted";
          this.snackbar_icon = "mdi-check";
          this.snackbar_show = true;
          // Reload page data only if the response is successful
          await this.getData();
        } else {
          throw new Error(response.message);
        }

      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = error.message;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },
  },
};
</script>

<style>
.card-form {
  margin: 25px;
}

.white-icon {
  color: white !important;
}

h3:first-letter {
  text-transform: capitalize;
}

.v-treeview-node__label {
  text-transform: capitalize;
  word-wrap: break-word;
  white-space: initial;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
}

.display-inline {
  display: inline-block;
}

.input {
  max-width: 300px;
  display: inline-block;
  margin: 20px;
  margin-bottom: 40px;
}

.background-gradient {
  color: white;
  box-shadow: 0 0 20px #eee;
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 200%);
}

.text-gradient-half {
  background: linear-gradient(to right, #047ec3 0%, #52ae32 150%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.image-container {
  width: 400px; /* Ancho deseado del contenedor */
  height: 300px; /* Alto deseado del contenedor */

  justify-content: center;
  align-items: center;
}
.uploaded-image{
  object-fit: cover; /* Puedes ajustar esto según tus necesidades */
}
</style>
