<template>
  <v-container class="wrapper">
    <div class="text-center">
    <div class="text-h4">Most Demanded Skills</div>
    </div>
    <v-data-table :headers="skillsheaders" :items="skills" class="elevation-1 mt-4">
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editCourse(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>

    <v-snackbar v-model="snackbar_show" :color="snackbar_color" top>
      <v-icon>{{ snackbar_icon }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "MostDemandSkills",
  data: () => ({
    skills: [],
    skillsName: [],
    snackbar_show: false,
    snackbar_color: null,
    snackbar_text: null,
    snackbar_icon: null,
    skillsheaders: [
      { text: "Skill Name", value: "skill_name" },
      { text: "Occurrences", value: "count" },
    ],
  }),

  async mounted() {
    try {
      await this.getData();
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        this.$router.push("/auth-error");
      }
    }
  },

  methods: {
    async getData() {
      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "training-provider/demanded-skills",
        {
          method: "GET",
          headers: {
            "Auth-Token": this.$cookie.get("jwt_token"),
            "Content-Type": "application/json",
          },
        }
      );
      response = await response.json();
      if (response.success) {
        this.skills = response.result;
      } else {
        throw new Error(response.message);
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}
</style>
