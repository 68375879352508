<template>
  <div>
    <!--
            <p>
              <strong>Category: </strong>
              <span v-if="selectedCourse.category">{{ selectedCourse.category }}</span>
              <span v-else>Not specified</span>
            </p>
    -->
            <p v-if="selectedCourse.courses_topic">
              <strong>Topics: </strong>
              <span v-if="selectedCourse.courses_topic">{{ selectedCourse.courses_topic }}</span>
              <span v-else>Not specified</span>
            </p>
            <!--
            <p>
              <strong>Duration: </strong>
              <span v-if="selectedCourse.duration">{{ selectedCourse.duration }}</span>
              <span v-else>Not specified</span>
            </p>
            -->
            <p>
              <strong>Dates: </strong>
              <span v-if="selectedCourse.init_date && selectedCourse.end_date">
                {{ new Date(selectedCourse.init_date).toLocaleDateString() }} - {{ new Date(selectedCourse.end_date).toLocaleDateString() }}
              </span>
              <span v-else>Not specified</span>
            </p>
            <!-- <p v-if="selectedCourse.Learning_Outcomes">
              <strong>Learning Outcomes:</strong> {{ selectedCourse.Learning_Outcomes }}
            </p> -->

            <p>
              <strong>Keywords: </strong>
              <span v-if="selectedCourse.keywords">{{ selectedCourse.keywords }}</span>
              <span v-else>Not specified</span>
            </p>

            <p>
              <strong>Course type: </strong>
              <span v-if="selectedCourse.course_type_id">{{ selectedCourse.course_type_name }}</span>
              <span v-else>Not specified</span>
            </p>



            <p>
              <strong>Type of learning resource: </strong>
              <span>{{ selectedCourse.media }}</span>
            </p>

            <p>
              <strong>Access: </strong>
              <span v-if="selectedCourse.access">{{ selectedCourse.access }}</span>
              <span v-else>Not specified</span>
            </p>

            <p v-if="selectedCourse.access.toUpperCase() === 'PAID'">
              <strong>Cost: </strong>
              <span>{{ selectedCourse.cost + " €"|| 'Not specified' }}</span>
            </p>

            <p v-else-if="selectedCourse.access.toUpperCase() === 'FREE'">
              <strong>Cost: </strong>
              <span>Free</span>
            </p>

            <p v-else>
              <strong>Cost: </strong>
              <span>{{ selectedCourse.cost + " €"|| 'Not specified' }}</span>
            </p>


            <p v-if="selectedCourse.rating">
              <strong>Rating:</strong>

              <v-rating
                color="primary"
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                half-icon="mdi-star-half"
                half-increments
                hover
                length="5"
                readonly
                size="32"
                :value="selectedCourse.rating"
              ></v-rating>
            </p>


            <p>
              <strong>License Type: </strong>
              <span v-if="selectedCourse.license_id">{{ selectedCourse.license_name }}</span>
              <span v-else>Not specified</span>
            </p>

            <p>
              <strong>Name of the Institution: </strong>
              <span v-if="selectedCourse.institution">
                      {{ selectedCourse.institution }}
                    </span>
              <span v-else>Not specified</span>
            </p>

            <p v-if="selectedCourse.contact_mail">
              <strong>Contact: </strong>
              <template v-if="selectedCourse.contact_mail">
                <a target="_blank" :href="'mailto:'  + selectedCourse.contact_mail">{{ selectedCourse.contact_mail }}</a>
              </template>
              <template v-else>
                <span >Not specified</span>
              </template>
            </p>

            <p v-if="selectedCourse.weblink">
              <strong>Weblink: </strong>
              <template v-if="selectedCourse.weblink">
                <a target="_blank" :href="selectedCourse.weblink">{{ selectedCourse.weblink }}</a>
              </template>
              <template v-else>
                <span >Not specified</span>
              </template>
            </p>


            <p>
              <strong>Online course link: </strong>
              <template v-if="selectedCourse.archive_name">
                <a target="_blank" :href="selectedCourse.archive_name">{{ selectedCourse.archive_name }}</a>
              </template>
              <template v-else>
                <span >Not specified</span>
              </template>
            </p>


            <p>
              <strong>Languages: </strong>
              <span v-if="selectedCourse.languages">
                      {{ JSON.parse(selectedCourse.languages).toString() }}
                    </span>
              <span v-else>Not specified</span>
            </p>

            <!--
            <p>
              <strong>Date of creation: </strong>
              <span v-if="selectedCourse.created_at">
                      {{ selectedCourse.created_at }}
                    </span>
              <span v-else>Not specified</span>
            </p>
            -->

            <!-- <p v-if="selectedCourse.relevance_id">
              <strong>Relevance:</strong> {{ selectedCourse.relevance }}
            </p> -->
            <p class="detacurso text-body-1">
              <strong>EQF NQF level: </strong>
              <span v-if="selectedCourse.eqf_nqf_level && selectedCourse.eqf_nqf_level !== 'null'">
                      {{ selectedCourse.eqf_nqf_level }}
                    </span>
              <span v-else>Not specified</span>
            </p>

            <p>
              <strong>ECVET points: </strong>
              <span v-if="selectedCourse.ecvet_points && selectedCourse.ecvet_points !== 'null'">
                      {{ selectedCourse.ecvet_points }}
                    </span>
              <span v-else>Not specified</span>
            </p>

            <p>
              <strong>Partner: </strong>
              <span v-if="selectedCourse.partner_id">
                      {{ selectedCourse.partner }}
                    </span>
              <span v-else>Not specified</span>
            </p>


            <resource-editor :entity-id="selectedCourse.course_id" entity-table="courses" ></resource-editor>
            <br />
            <br />


  </div>

</template>

<script>
import ResourceEditor from "@/pages/app/common/ResourceEditor.vue";

export default {
  name: "CourseDetailView",
  components: {ResourceEditor},
  props:[
    "selectedCourse"
  ],
  data: () => ( {

  }),

  async mounted() {

  },

  methods: {

  }
}

</script>

<style scoped>
.card-outter {
  position: relative;
  padding-bottom: 50px;
}
.card-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
  flex: 1;
}

.detailbtn {
  float: right;
}

.cart {
  margin-bottom: 100px;
  padding: 40px;
}

.detacurso {
  margin-bottom: 20px;
}

.titlecourse {
  margin-top: 40px;
  margin-bottom: 20px;
}

.title {
  margin-bottom: 25px;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}

.no-link {
  text-decoration: none;
  color: black;
}
.card-title {
  text-transform: capitalize;
  word-break: normal;
}
.gray {
  color: #283444;
}

.button-gradient:hover {
  background-position: right center;
}

.button-gradient {
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(
    to right,
    #047ec3 0%,
    #52ae32 51%,
    #047ec3 100%
  );
}

.button-gradient-half {
  text-transform: uppercase;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 100%);
}
</style>
