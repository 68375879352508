<template>
  <v-container class="wrapper">
    <div class="text-center">
      <h1 class="text-h4 gray mb-4">Rate Training Courses</h1>
    </div>
    <v-autocomplete
      v-model="course"
      label="Select a course to rate"
      @change="courseAltered()"
      :items="courses"
      item-text="title"
      return-object
    />
    <p class="p-0 mt-2" v-if="ratingCourse">What rate do you give it?</p>
    <v-rating
      v-model="rating"
      v-if="ratingCourse"
      empty-icon="mdi-star-outline"
      full-icon="mdi-star"
      half-icon="mdi-star-half-full"
      color="yellow"
      hover
    ></v-rating>
    <v-textarea
      v-model="comment"
      v-if="ratingCourse"
      label="Any comments about this course? (Optional)"
      rows="1"
      auto-grow
    />
    <v-textarea
      v-model="reason"
      v-if="ratingCourse"
      label="Would you mind telling us what made you do this course? (Optional)"
      rows="1"
      auto-grow
    />
    <v-btn block class="button-gradient-half" @click="rateCourse()" v-if="ratingCourse"
      >Send Rating</v-btn
    >

    <v-card class="mt-3">
      <v-list subheader>
        <v-subheader><strong>List of courses rated: pending of approval comments</strong></v-subheader>
        <v-list-item v-for="course in pendingCourses" :key="course.course_id">
          <v-list-item-avatar>
            <v-img
              :src="
                VUE_APP_API_ENDPOINT +
                'images/courses/' +
                (course.image || 'default.jpg')
              "
            ></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="course.title"></v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon>
            <router-link
              class="no-link"
              :to="'/app/' + userType + '/course/' + course.course_id"
            >
              <v-icon color="primary"> mdi-arrow-right-circle </v-icon>
            </router-link>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-card>

    <v-card class="mt-3">
      <v-list subheader>
        <v-subheader><strong>List of courses rated: comments accepted</strong></v-subheader>
        <v-list-item v-for="course in acceptedCourses" :key="course.course_id">
          <v-list-item-avatar>
            <v-img
              :src="
                VUE_APP_API_ENDPOINT +
                'images/courses/' +
                (course.image || 'default.jpg')
              "
            ></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="course.title"></v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon>
            <router-link
              class="no-link"
              :to="'/app/' + userType + '/course/' + course.course_id"
            >
              <v-icon color="primary"> mdi-arrow-right-circle </v-icon>
            </router-link>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-card>

    <v-card class="mt-3">
      <v-list subheader>
        <v-subheader><strong>List of courses rated: comments rejected</strong></v-subheader>
        <v-list-item v-for="course in rejectedCourses" :key="course.course_id">
          <v-list-item-avatar>
            <v-img
              :src="
                VUE_APP_API_ENDPOINT +
                'images/courses/' +
                (course.image || 'default.jpg')
              "
            ></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="course.title"></v-list-item-title>
          </v-list-item-content>

          <v-list-item-icon>
            <router-link
              class="no-link"
              :to="'/app/' + userType + '/course/' + course.course_id"
            >
              <v-icon color="primary"> mdi-arrow-right-circle </v-icon>
            </router-link>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-card>

    <v-snackbar v-model="snackbar_show" :color="snackbar_color" top>
      <v-icon>{{ snackbar_icon }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "mycourses",
  data: () => ({
    courses: [],
    acceptedCourses: [],
    pendingCourses: [],
    rejectedCourses: [],
    course: null,
    ratingCourse: false,
    rating: 1,
    comment: "",
    reason: "",
    // Snackbar
    snackbar_show: false,
    snackbar_color: null,
    snackbar_text: null,
    snackbar_icon: null,
    // env
    VUE_APP_API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,
    // Used to build the courses urls
    userType: null,
  }),
  async mounted() {
    try {
      await this.getData();
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        this.$router.push("/auth-error");
      }
    }
    this.userType = this.$route.path.match(/\/app\/(.+?)\//)[1];
  },
  methods: {
    async getData() {
      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT + "common/get-rate-courses",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );

      response = await response.json();
      if (response.success) {
        this.courses = response.result;
        // Clean the array in every update
        this.pendingCourses = [];
        this.acceptedCourses = [];
        this.rejectedCourses = [];

        this.courses.forEach((course) => {
          if (course.status_id == 1) {
            this.pendingCourses.push(course);
          } else if (course.status_id == 2) {
            this.acceptedCourses.push(course);
          } else if (course.status_id == 3) {
            this.rejectedCourses.push(course);
          }
        });
      } else {
        throw new Error(response.message);
      }
    },
    async courseAltered() {
      this.ratingCourse = true;
      this.rating = this.course.rating;
      this.comment = this.course.comment;
      this.reason = this.course.reason;
    },
    async rateCourse() {
      let response;
      try {
        response = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/rate-course",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
            body: JSON.stringify({
              course: this.course.course_id,
              rating: this.rating,
              comment: this.comment,
              reason: this.reason,
            }),
          }
        );
        response = await response.json();
        if (response.success) {
          // Info message
          this.snackbar_color = "success";
          this.snackbar_text = "Course rated successfully!";
          this.snackbar_icon = "mdi-check";
          this.snackbar_show = true;
          this.ratingCourse = false;
          // Update page data
          this.getData();
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = error.message;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },
  },
};
</script>
<style scoped>
.detailbtn {
  float: right;
}

.cart {
  margin-bottom: 100px;
  padding: 20px;
}

.detacurso {
  margin-bottom: 20px;
}

.titlecourse {
  margin-top: 40px;
  margin-bottom: 20px;
}

.title {
  margin-bottom: 25px;
}
.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}
.no-link {
  text-decoration: none;
  color: black;
}
.gray {
  color: #283444;
}

.button-gradient-half:hover {
  background-position: right center;
}

.button-gradient-half {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 100%);
}
</style>
