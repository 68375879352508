<template>
  <v-container class="wrapper">
    <h2 class="text-center pb-2">Allview Platform About</h2>
    <v-expansion-panels>
      <v-expansion-panel v-for="info in information" :key="info.title">
        <v-expansion-panel-header>{{ info.tittle }}</v-expansion-panel-header>
        <v-expansion-panel-content class="pt-2">
          {{ info.content }}
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <h2 class="text-center pt-5">Contact Us</h2>
    <div class="text-center pt-2">
      <p><b>Email:</b> <a href="mailto:support@allview.eu">support@allview.eu</a></p>
      <p><b>Linkedin:</b> <a href="https://www.linkedin.com/company/allview-platform/">https://www.linkedin.com/company/allview-platform/</a></p>
      <p><b>Facebook:</b> <a href="https://www.facebook.com/Allview-Erasmus-Vocational-Excellence-Platform-100934198582293">https://www.facebook.com/Allview-Erasmus-Vocational-Excellence-Platform-100934198582293</a></p>
    </div>


  </v-container>
</template>

<script>
export default {
  data: () => ({
    name: "Help",
    information: [
      { tittle: "Programme ", content: "Erasmus+" },
      { tittle: "Key action ", content: "KA3 – Support for Policy Reform" },
      { tittle: "Action", content: "Centres of Vocational Excellence" },
      { tittle: "Project acronym ", content: "ALLVIEW" },
      { tittle: "Project title ", content: "Alliance of Centres of Vocational Excellence in the Furniture and Wood sector" },
      { tittle: "Project starting date", content: "01/11/2020" },
      { tittle: "Project end date", content: "31/10/2024" },
      { tittle: "Dissemination level", content: "Confidential only for members of the consortium (including EACEA, Commission services and project reviewers)" },
      { tittle: "Produced ", content: "UPCT –Cristina Albaladejo, Mª Victoria Bueno" },
    ],
  }),
};
</script>

<style scoped>
.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 50px
}
</style>
