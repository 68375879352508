<template>
  <div>
    <Toolbar/>
    <SignIn/>
  </div>
</template>

<script>
import Toolbar from "@/components/carbon-ui/toolbar/Toolbar2.vue";
import SignIn from "@/components/carbon-ui/signin/SignIn2.vue";

export default {
  components: {
    SignIn,
    Toolbar
  }
}
</script>
