<template>
  <v-container class="wrapper">



    <div class="text-center">
      <div class="text-center">
        <h1 class="text-h4 gray">
          My Favorite Courses
        </h1>
      </div>
      <div v-if="courses && courses.length === 0">
        <p class="text-center mt-2">
          You don't have any saved courses at the moment
        </p>
      </div>
    </div>

    <v-row class="mt-5">
      <v-col
        xl="3"
        lg="3"
        md="3"
        sm="6"
        xs="6"
        v-for="course in courses"
        :key="course.course_id"
      >
        <v-card hover min-height="350px" height="100%" class="card-outter">
          <!-- <router-link
            text
            :to="'/app/' + userType + '/course/' + course.course_id"
            class="centertext link no-link"
          > -->
            <!-- {{ course.image }} -->
            <v-img
              height="175px"
              :src="
                VUE_APP_API_ENDPOINT +
                'images/courses/' +
                (course.image || 'default.jpg')
              "
              contain
            ></v-img>

            <v-card-title>
              <div
                class="text-subtitle-1 card-title">
                {{ course.title.toLowerCase() }}
              </div>
            </v-card-title>
          <!-- </router-link> -->
          <v-card-text>
            <div class="ma-2 pa-0 text-body-1">
              {{ course.partner }}
            </div>
          </v-card-text>

          <v-card-actions class="card-actions">

             <v-btn
                block
                class="button-gradient-half"
                @click.stop="(dialog = true), (selectedCourse = course)">
                Details
              </v-btn>
            <!--  -->
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- COMIENZA EL DIALOG -->

      <v-row>
        <v-dialog v-model="dialog" max-width="800">

          <course-view :selected-course = "selectedCourse"
            v-on:close="closeDialog"
            v-on:removeFromList="deleteFromList"
            v-on:addToList="addToList"
           />
        </v-dialog>
      </v-row>


    <v-snackbar v-model="snackbar_show" :color="snackbar_color" top>
      <v-icon>{{ snackbar_icon }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import CourseView from "@/pages/app/common/CourseView";
export default {
  name: "MyCourses",
  components: {CourseView},
  data: () => ({
    courses: null,
    selectedCourse: "",
    lang: null,
    // Snackbar
    snackbar_show: false,
    snackbar_color: null,
    snackbar_text: null,
    snackbar_icon: null,
    // Used to build the course urls
    userType: null,
    dialog: false,
    // env
    VUE_APP_API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,
  }),

  async mounted() {
    try {
      await this.getData();
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        this.$router.push("/auth-error");
      }
    }
    this.userType = this.$route.path.match(/\/app\/(.+?)\//)[1];
  },

  methods: {
      addToList(course){
        this.courses.push(course);
      },
      deleteFromList(id){
        let target = parseInt(id);
        for(let i = 0; i < this.courses.length ; i++){
          if(this.courses[i].course_id === target){
            this.courses.splice(i, 1);
            break;
          }
        }
      },
      closeDialog(){
        this.dialog = false;
      },
      async getData() {
        let response = await fetch(
          // We reuse the search-courses endpoint since it returns the fav courses
          process.env.VUE_APP_API_ENDPOINT + "common/search-courses",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        response = await response.json();
        if (response.success) {
          this.courses = response.result.courses;
        } else {
          throw new Error(response.message);
        }
    },
    async getCourse() {
      let response = await fetch(
        process.env.VUE_APP_API_ENDPOINT +
          "common/courses/" +  this.selectedCourse.course_id,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Auth-Token": this.$cookie.get("jwt_token"),
          },
        }
      );
      response = await response.json();
      if (response.success) {
        this.course = response.result
        this.lang = this.course.language.JOIN()
      } else {
        throw new Error(response.message);
      }
    },
    // Insert a course in favourites
    async addFav() {
      let responseInsert;
      try {
        responseInsert = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/add-fav-courses",
          {
            method: "POST",
            body: JSON.stringify({
              course_id: this.course.course_id,
            }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        responseInsert = await responseInsert.json();
        if (responseInsert.success) {
          this.snackbar_color = "success";
          this.snackbar_text = "Success: course added to favorites";
          this.snackbar_icon = "mdi-success-circle";
          this.snackbar_show = true;
        } else {
          throw new Error(responseInsert.message);
        }
      } catch (error) {
        if (error.message === "alreadySaved") {
          // Warning message
          this.snackbar_color = "orange";
          this.snackbar_text = "The course was already saved as favorite";
          this.snackbar_icon = "mdi-alert-outline";
        } else {
          // Error message
          this.snackbar_color = "error";
          this.snackbar_text = error.message;
          this.snackbar_icon = "mdi-alert-circle";
        }
        this.snackbar_show = true;
      }
    },
    // Delete a course in favourites
    async delFav() {
      console.log(JSON.stringify(this.selectedCourse))
      let responseDelete;
      try {
        responseDelete = await fetch(
          process.env.VUE_APP_API_ENDPOINT + "common/delete-fav-courses",
          {
            method: "POST",
            body: JSON.stringify({
              course_id: this.selectedCourse.course_id,
            }),
            headers: {
              "Content-Type": "application/json",
              "Auth-Token": this.$cookie.get("jwt_token"),
            },
          }
        );
        responseDelete = await responseDelete.json();
        if (responseDelete.success) {
          this.snackbar_color = "success";
          this.snackbar_text = "Success: course removed from favorites";
          this.snackbar_icon = "mdi-success-circle";
          this.snackbar_show = true;
        } else {
          throw new Error(responseDelete.message);
        }
      } catch (error) {
        // Error message
        this.snackbar_color = "error";
        this.snackbar_text = error.message;
        this.snackbar_icon = "mdi-alert-circle";
        this.snackbar_show = true;
      }
    },
  }
}

</script>

<style scoped>
.card-outter {
  position: relative;
  padding-bottom: 50px;
}
.card-actions {
  position: absolute;
  bottom: 0;
  width: 100%;
  flex: 1;
}

.detailbtn {
  float: right;
}

.cart {
  margin-bottom: 100px;
  padding: 40px;
}

.detacurso {
  margin-bottom: 20px;
}

.titlecourse {
  margin-top: 40px;
  margin-bottom: 20px;
}

.title {
  margin-bottom: 25px;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}

.no-link {
  text-decoration: none;
  color: black;
}
.card-title {
  text-transform: capitalize;
  word-break: normal;
}
.gray {
  color: #283444;
}

.button-gradient:hover {
  background-position: right center;
}

.button-gradient {
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(
    to right,
    #047ec3 0%,
    #52ae32 51%,
    #047ec3 100%
  );
}

.button-gradient-half {
  text-transform: uppercase;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
  box-shadow: 0 0 20px #eee;
  border-radius: 7px;
  /* Yellow and orange background-image: linear-gradient(to right, #F2E500 0%, #EC6608 51%, #F2E500 100%); */
  background-image: linear-gradient(to right, #047ec3 0%, #52ae32 100%);
}
</style>
